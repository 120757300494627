import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  Text,
  Panel,
  Button,
  Toggle,
  Field,
  Loader,
  Paragraph,
  Columns,
  Column,
} from "ks_storybook";
import withStore from "../../../../hocs/withStore";
import moment from "moment";
import { AdPlanRow } from "../../../../Components/Plans";
import { Tooltip } from "react-tooltip";
import { EmptyContent } from "../../../../Components/Common";

@observer
class AdsPlanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accounts: this.getAccounts(),
      ads: this.getAdAll(),
      userId: this.props.store.loggedInUser.id,
      showModal: false,
      showAdBatchesEyeModel: false,
      showAdDeletedPlansModal: false,
      showAdPlanEditModal: false,
      currentAdPlans: null,
      adsPlans: this.getAdsPlans(this.props.store.loggedInUser.id),
      datesSelected: [],
      plan: null,
    };

    this.weekdays = this.props.store.language.label61.split(",");
  }

  componentDidMount() {
    this.props.store.ui.pushHistory("/player/ads_plans");
    this.props.store.menuTitle = this.props.store.language.label49;
  }

  componentWillUnmount() {
    this.props.store.handleHeader(null, ["renderHeaderEnd"]);
    this.props.store.menuTitle = null;
  }

  getAdAll = () => {
    return this.props.store.ads.fetchAll();
  };

  getAccounts = () => {
    return this.props.store.users.fetchAccounts();
  };

  getOptions = (accounts) => {
    return accounts
      .toArray()
      .map((account) => ({ value: account.id, label: account.username }));
  };

  dateToKasimuDate = (wd) => {
    return wd == 0 ? 7 : wd;
  };

  isWeekDay = (plan, weekDay) => {
    if (plan.week_day && plan.week_day == weekDay) {
      return true;
    }
    if (plan.exact_day) {
      const wd = moment(plan.exact_day, "DD/MM/YYYY");
      const d = wd.day();
      return this.dateToKasimuDate(d) == weekDay;
    }
    return false;
  };

  getAdsPlans = (value) => {
    if (!this.props.store.failsafeMode) {
      return this.props.store.advertisingGuidelines.getGuidelinesUser(value);
    } else {
      return null;
    }
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label92}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderHeaderOk = () => {
    const { adsPlans } = this.state;
    const options = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
    const arrayPlans = adsPlans?.toArray();

    if (arrayPlans.length > 0)
      this.props.store.handleHeader(
        <Button
          size="lg"
          iconSize="28px"
          iconKasimu="turn-off"
          color={arrayPlans[0].ads_planner_on ? "confirm" : "remove"}
          kind="link"
        />,
        ["renderHeaderEnd"]
      );

    return (
      <React.Fragment>
        <Panel
          color="gray"
          padding={this.props.store.viewMobile ? "23px 20px" : "12px 10px"}
        >
          {arrayPlans.length === 0 && this.renderEmptyContent()}

          {arrayPlans.length > 0 &&
            options.map((i) => this.renderFrame(arrayPlans, i))}
        </Panel>
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { adsPlans, ads } = this.state;
    if (!adsPlans && ads) return this.renderEmptyContent();
    return !adsPlans.isOk() ? this.renderLoader() : this.renderHeaderOk();
  };

  getTitleForWeekDay = (weekDay) => {
    const titles = {
      1: this.weekdays[1],
      2: this.weekdays[2],
      3: this.weekdays[3],
      4: this.weekdays[4],
      5: this.weekdays[5],
      6: this.weekdays[6],
      7: this.weekdays[0],
      8: this.props.store.language.label183,
      9: this.props.store.language.label184,
      10: this.props.store.language.label185,
    };
    return titles[weekDay];
  };

  renderFrame = (plans, weekDay) => {
    return (
      <React.Fragment>
        <Panel
          color="grayDark"
          className="mt-1"
          padding={this.props.store.viewMobile ? "10px 25px" : "5px 15px"}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Text
                weight="strong"
                size={this.props.store.viewMobile ? "xl" : "lg"}
              >
                <strong className="has-text-light">
                  {this.getTitleForWeekDay(weekDay)}
                </strong>
              </Text>
            </div>
            <Button
              name="arrow-down"
              kind="link"
              color="basic"
              size={this.props.store.viewMobile ? "xl" : "lg"}
              icon={
                this.state.datesSelected.some((e) => e == weekDay)
                  ? "chevron-down"
                  : "chevron-up"
              }
              space
              onClick={() => {
                let valid = this.state.datesSelected.indexOf(weekDay);

                if (valid >= 0) {
                  this.setState({
                    datesSelected: this.state.datesSelected.filter(
                      (i) => i !== weekDay
                    ),
                  });
                } else {
                  this.setState({
                    datesSelected: [...this.state.datesSelected, weekDay],
                  });
                }
              }}
            />
          </div>
        </Panel>
        {this.state.datesSelected.some((e) => e == weekDay)
          ? null
          : plans
              .filter((p) => this.isWeekDay(p, weekDay))
              .map((p) => (
                <AdPlanRow
                  plan={p}
                  onDeleteClick={this.handleAdDeletedPlansModalOpen}
                  onEyeClick={this.handleEyeBatchesModelOpen}
                  onEditClick={this.handleEditModalOpen}
                />
              ))}
      </React.Fragment>
    );
  };

  render() {
    const { adsPlans } = this.state;
    const arrayPlans = adsPlans?.toArray();

    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <div
                className="flex items-center"
                style={{
                  justifyContent: "space-between",
                }}
              >
                <div className="flex items-center">
                  <Text
                    lineBreak
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    lead
                    weight="black"
                    className="mr-2"
                  >
                    {this.props.store.language.label49}
                  </Text>

                  {arrayPlans?.length > 0 && (
                    <Text
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      weight="medium"
                      color="special"
                      className={
                        arrayPlans[0].ads_planner_on ? "success" : "danger"
                      }
                    >
                      {arrayPlans[0].ads_planner_on
                        ? this.props.store.language.label53
                        : this.props.store.language.label54}
                    </Text>
                  )}
                </div>
                <Button
                  className="px-2"
                  onClick={() =>
                    this.props.store.getPermission(8, 8, () => {
                      this.props.store.goToPanel();
                    })}
                  size="lg"
                  color="basic"
                  kind="outline"
                  id="panel"
                >
                  {this.props.store.language.label51}
                </Button>
              </div>
            )}

            <div
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            ></div>

            {this.renderHeader()}
          </Column>
        </Columns>
      </React.Fragment>
    );
  }
}

export default withStore(AdsPlanner);
