import styled from "styled-components";
import Sheet from "react-modal-sheet";
import { useState, useRef } from "react";

const CustomSheet = styled(Sheet)`
  .react-modal-sheet-backdrop {
    background-color: rgb(0 0 0 / 50%) !important;
  }
  .react-modal-sheet-container {
    background-color: #1f1f1f !important;
  }
  .react-modal-sheet-header {
    /* custom styles */
  }
  .react-modal-sheet-drag-indicator {
    background-color: #999999 !important;
  }
  .react-modal-sheet-content {
    /* custom styles */
  }
`;

function ModalSheet(props) {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef();
  const snapTo = (i) => ref.current?.snapTo(i);

  const { children, onClose, snapPoints } = props;

  return (
    <CustomSheet
      ref={ref}
      isOpen={true}
      onClose={onClose}
      snapPoints={snapPoints || [600, 400, 100, 0]}
      initialSnap={1}
      onSnap={(snapIndex) => {}}
    >
      <CustomSheet.Container>
        <CustomSheet.Header />
        <CustomSheet.Content>{children}</CustomSheet.Content>
      </CustomSheet.Container>
      <Sheet.Backdrop onClick={onClose} />
    </CustomSheet>
  );
}

export default ModalSheet;
