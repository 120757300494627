const pt_PT = {
  languages: {
    1: "Espanhol",
    2: "Inglês",
    3: "Francês",
    4: "Português",
    5: "Estoniano",
    6: "Alemão",
    7: "Italiano"
  }, 
  label1: "Idiomas",
  label2: "Alterar a língua",
  label3: "Pode escolher e alterar a língua que desejar.",
  label4: "Sem descrição",
  label5: "Carregando Aplicação...",
  label6: "Sua assinatura expirou. Ir para ",
  label7: "Esta conta está conectada de outra localização.",
  label8: "Erro, Usuário ou senha incorretos.",
  label9: "As contas administrativas não têm acesso ao reprodutor web!",
  label10: "Usuário suspenso! Entre em contato com ",
  label11: "Por favor, forneça nome de usuário e senha.",
  label12: "Usuário suspenso pelo administrador",
  label13: "Erro ao conectar ao servidor, verifique sua conexão.",
  label14: "Usuário",
  label15: "(Não usar e-mail)",
  label16: "Senha",
  label17: "Iniciar sessão",
  label18: "Categoria",
  label19: "Minhas playlists",
  label20: "Voltar",
  label21: `Conecte-se ao painel de controle para criar playlists.`,
  label22: `Sem dados no momento`,
  label23: `Erro, o PIN deve ter 4 dígitos.`,
  label24: `Erro, PIN incorreto.`,
  label25: `Reprodutor bloqueado`,
  label26: `Por favor, insira seu PIN para desbloquear.`,
  label27: `Obrigatório`,
  label28: `Sair`,
  label29: `Escolher por Tipo de Local`,
  label30: `Escolher por Estilo de Música`,
  label31: `Escolher por Outros Critérios`,
  label32: `Reprodutor em Hibernação`,
  label33: `Por favor, insira seu PIN para sair da hibernação.`,
  label34: `Desbloquear`,
  label35: `Cancelar`,
  label36: `Início`,
  label37: `Reproduzindo`,
  label38: `Músicas`,
  label39: `Início`,
  label40: `Buscar Playlists`,
  label41: `Biblioteca Musical`,
  label42: `Artista`,
  label43: `Buscar nome da música`,
  label44: `Buscar artista`,
  label45: `Resultados`,
  label46: `Adicionar como próxima a ser reproduzida`,
  label47: `Planejador de Playlists`,
  label48: `Personalização`,
  label49: `Planejador de Anúncios`,
  label50: `Anúncios`,
  label51: `Painel de Controle`,
  label52: `Sobre`,
  label53: `Ativado`,
  label54: `Desativado`,
  label55: `Versão de Atualização do Banco de Dados`,
  label56: `Aplicativo de Música`,
  label57: `Este aplicativo foi desenvolvido por`,
  label58: `Este aplicativo foi desenvolvido para`,
  label59: "Janeiro,Fevereiro,Março,Abril,Mayo,Junho,Julho,Agosto,Setembro,Outubro,Novembro,Dezembro",
  label60: "Jan,Fev,Mar,Abr,May,Jun,Jul,Ago,Set,Out,Nov,Dez",
  label61: "Domingo,Segunda,Terça,Quarta,Quinta,Sexta,Sábado",
  label62: "Dom,Seg,Ter,Qua,Qui,Sex,Sáb",
  label63: "D,Seg,Ter,Qua,Qui,Sex,Sáb",
  label64: "Conectado",
  label65: "Desconectado",
  label66: `Deseja encerrar a sessão?`,
  label67: "Sim",
  label68: "Não",
  label69: `Mover como próxima a ser reproduzida`,
  label70: `Marcar como próxima a ser reproduzida`,
  label71: `Remover música da playlist em reprodução`,
  label72: `Buscando...`,
  label73: `Buscar...`,
  label74: `Abrir`,
  label75: `Fechar`,
  label76: `Mais opções`,
  label77: `Favorito`,
  label78: `Bloquear`,
  label79: `Anterior`,
  label80: `Play`,
  label81: `Pausa`,
  label82: `Próxima`,
  label83: `Playlist em reprodução`,
  label84: `Ir para o painel de controle web`,
  label85: `Avançar`,
  label86: `Expandir biblioteca`,
  label87: `Reduzir biblioteca`,
  label88: `Expandir biblioteca`,
  label89: `Mudo`,
  label90: `Deseja marcar esta música como indesejada?`,
  label91: `Não será reproduzida novamente.`,
  label92: `Carregando Dados...`,
  label93: "Configurações",
  label94: "Conta",
  label95: "Serviço contratado",
  label96: "Preferências",
  label97: "Volume",
  label98: "Você pode ajustar ou silenciar o volume no painel de controle web.",
  label99: "Planejadores e anúncios",
  label100: "Agende suas playlists favoritas no horário que desejar, tudo pelo painel de controle web.",
  label101: "Crie e atribua anúncios no horário desejado, tudo pelo painel de controle web.",
  label102: "Faça upload de seus anúncios, tudo pelo painel de controle web.",
  label103: "Informações",
  label104: "Versão",
  label105: "Desenvolvedor",
  label106: "Website",
  label107: "Políticas de privacidade",
  label108: "Importante para você e para nós.",
  label109: "Suporte",
  label110: "Conte com a nossa ajuda.",
  label111: "Outros",
  label112: "Sair",
  label113: "Você está logado como",
  label114: "REPRODUZINDO DE",
  label115: "Curti",
  label116: "Ocultar esta música",
  label117: "Mover como próxima a ser reproduzida",
  label118: "Marcar como próxima a ser reproduzida",
  label119: "Remover da lista atual",
  label120: "Adicionar como próxima a ser reproduzida",
  label121: `Anúncio`,
  label122: "Salvar alterações",
  label123: "Autosalvar (Ativado)",
  label124: "Autosalvar (Desativado)",
  label125: "Autosalvar ativado",
  label126: "Autosalvar desativado",
  label127: "Música adicionada à playlist com sucesso",
  label128: "Não foi possível adicionar a música à playlist",
  label129: "Playlist criada com sucesso",
  label130: "O nome da playlist já existe, escolha outro nome",
  label131: "Você deve indicar um nome para a nova playlist",
  label132: "Músicas adicionadas à playlist com sucesso",
  label133: "Não foi possível adicionar as músicas à playlist",
  label134: "Você deve selecionar uma playlist",
  label135: "O usuário não possui uma configuração base de limites, entre em contato com o suporte",
  label136: "Música marcada como favorita, será programada sempre",
  label137: "Música desmarcada como favorita, não será programada mais",
  label138: "Música adicionada à playlist em reprodução",
  label139: "Selecione uma playlist",
  label140: "Playlists manuais",
  label141: "Nome da nova playlist",
  label142: "Adicionar",
  label143: "Salvar",
  label144: "Adicionar a uma playlist existente",
  label145: "Adicionar a uma nova playlist",
  label146: "É necessário conexão à internet para realizar esta ação, verifique sua conexão",
  label147: "Adicionar as músicas a uma playlist existente",
  label148: "Todas as músicas em reprodução serão adicionadas a uma playlist existente",
  label149: "Adicionar as músicas a uma nova playlist",
  label150: "Todas as músicas em reprodução serão adicionadas a uma nova playlist",
  label151: "Você não tem nenhuma playlist manual criada pelo usuário",
  lavel152: "Salvo com sucesso",
  label153: "Adicionando...",
  label154: "Salvando...",
  label155: "Não foi possível salvar",
  label156: "Você não tem permissão para acessar minhas playlists",
  label157: "Você não tem permissão para acessar minhas playlists favoritas",
  label158: "Você não tem permissão para acessar categorias e playlists",
  label159: "Você não tem permissão para buscar em todo o conteúdo musical, apenas nas favoritas",
  label160: "Você não tem permissão para acessar a biblioteca musical",
  label161: "Você não tem permissão para acessar as configurações",
  label162: "Você não tem permissão para acessar sair",
  label163: "Você não tem permissão para acessar o painel de controle",
  label164: "Você não tem permissão para acessar o planejador de playlists",
  label165: "Você não tem permissão para acessar o planejador de anúncios",
  label166: "Você não tem permissão para acessar os anúncios",
  label167: "Você não tem permissão para sair do aplicativo",
  label168: "Você não tem permissão para reproduzir/pausar a reprodução",
  label169: "Você não tem permissão para trocar a música em reprodução",
  label170: "Você não tem permissão para trocar a barra de progresso da música em reprodução",
  label171: "Você não tem permissão para acessar a visualização do mini reprodutor",
  label172: "Você não tem permissão para mover músicas na playlist em reprodução",
  label173: "Você não tem permissão para salvar alterações de uma playlist manual em reprodução",
  label174: "Você não tem permissão para adicionar músicas a uma playlist manual nova ou existente",
  label175: "Você não tem permissão para marcar ou desmarcar músicas como favoritas",
  label176: "Você não tem permissão para ocultar músicas para que não possam ser reproduzidas",
  label177: "Você não tem permissão para remover músicas da playlist em reprodução",
  label178: "Playlists favoritas",
  label179: "Todas as suas playlists favoritas e do seu administrador",
  label180: "Acesso restrito por PIN",
  label181: "Favoritas",
  label182: "Esta playlist pertence ao administrador e não pode ser modificada",
  label183: "Todos os dias",
  label184: "Segunda a sexta-feira",
  label185: "Sábado e domingo",
  label186: "Dia exato",
  label187: "Hora exata",
  label188: "Intervalo",
  label189: "Cada",
  label190: "Frequência",
  label191: "músicas",
  label192: "Limite de anúncios por bloco",
  label193: "Início da propaganda",
  label194: "Fim da propaganda",
  label195: "Do administrador",
  label196: "Do usuário",
  label197: "(Administrador) - ",
  label198: "(Usuário) - ",
  label199: "Hibernação",
  label200: "Assinatura inválida, entre em contato com o provedor de serviços"
};

export default pt_PT;