import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  LayoutSideMenuLink,
  LayoutSideMenu,
  Text,
  SidebarMenu,
  SidebarMenuLink,
  MobileLayoutMenuLink,
} from "ks_storybook";
import withStore from "../../hocs/withStore";

class PlayerBottomMenu extends Component {
  state = {
    value: "",
  };

  handleChangeShow = (val) => {
    if (this.state.value !== val) this.setState({ value: val });
    else this.setState({ value: "" });
  };

  seeCertificate = () => {
    if (this.props.store.typeView == 0) {
      if (
        this.props.store.loggedInUser.suscription.categoria_id == 6 ||
        this.props.store.loggedInUser.suscription.categoria_id == 9
      )
        return true;
    }
    return false;
  };

  handleClick = () => {
    if (this.props.store.toggleMenu) {
      this.props.store.toggleMenu = false;
    } else {
      this.props.store.toggleMenu = true;
    }
  };

  renderHeader = () => {
    const { handleChangeShow } = this;
    const { value } = this.state;
    const path = this.props.match.path;
    const location = this.props.location.pathname;

    return [
      <React.Fragment key={"main"}>
        <MobileLayoutMenuLink
          title={this.props.store.language.label17}
          to={`${path}home`}
          titleClick={() => handleChangeShow("home")}
          show={value == "home"}
          iconKasimu="home"
          active={`${path}home` === location}
          onClick={() => {
            this.props.store.ui.isReproducing = false;
            this.props.store.ui.isCategory = false;
          }}
          iconStyle={{ height: "1.25em" }}
        ></MobileLayoutMenuLink>

        <MobileLayoutMenuLink
          title={this.props.store.language.label578}
          to={`${path}playlists/all`}
          titleClick={() => handleChangeShow("playlist")}
          show={value == "playlist"}
          iconKasimu="search"
          active={`${path}playlists/all` === location}
          onClick={() => {
            this.props.store.ui.isReproducing = false;
          }}
          iconStyle={{ height: "1.25em" }}
        ></MobileLayoutMenuLink>

        <div
          onClick={() => {
            this.props.store.getPermission(5, 5, () => {
              this.props.history.push(`${path}library`);
            });
          }}
        >
          <MobileLayoutMenuLink
            title={
              this.props.store.language.label717 +
              " / " +
              this.props.store.language.label701
            }
            to={false}
            titleClick={() => handleChangeShow("library")}
            show={value == "library"}
            iconKasimu="song"
            active={`${path}library` === location}
            iconStyle={{ height: "1.25em" }}
          ></MobileLayoutMenuLink>
        </div>
      </React.Fragment>,
    ];
  };

  render() {
    const { className } = this.props;
    var menu = [];
    menu.push(this.renderHeader());
    return <div className={className}>{menu}</div>;
  }
}

export default withStore(withRouter(PlayerBottomMenu));
