import React, { Component } from "react";
import PropTypes from "prop-types";

import { Text, Columns } from "ks_storybook";

import CategoryListItem from "./CategoryListItem";

class CategoriesList extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(category) {
    this.props.onClick && this.props.onClick(category);
  }

  render() {
    const { title, categories, sizeText, main, id, mobile } = this.props;

    const sizeCard = main ? 340 : 170;

    const mobileStyle = {
      gridAutoFlow: "column",
      gridTemplateColumns: `repeat(${categories.length}, minmax(${sizeCard}px, 1fr))`,
      overflowX: "auto",
    };

    const desktopStyle = {
      gap: "24px",
    };

    return (
      <>
        {main ? (
          <section>
            <Columns
              fill="340"
              className="has-text-centered slider-wrapper"
              style={mobile ? mobileStyle : desktopStyle}
            >
              {categories.map((category, idx) => (
                <CategoryListItem
                  category={category}
                  onClick={this.handleClick}
                  main={main}
                  idx={idx + 1}
                  id={id}
                  key={idx}
                />
              ))}
            </Columns>
          </section>
        ) : (
          <section>
            <div className={`is-row ${mobile ? "mb-1" : "mb-2"}`}>
              <Text lead size={sizeText} weight="black" lineBreak>
                {title}
              </Text>
            </div>
            <br />
            <Columns
              fill="170"
              className="has-text-centered slider-wrapper"
              style={mobile ? mobileStyle : desktopStyle}
            >
              {categories.map((category, idx) => (
                <CategoryListItem
                  category={category}
                  onClick={this.handleClick}
                  idx={idx}
                  id={id}
                  key={idx}
                />
              ))}
            </Columns>
          </section>
        )}
        <div className={mobile ? "py-2" : "py-3"}></div>
      </>
    );
  }
}

CategoriesList.propTypes = {
  title: PropTypes.string,
  categories: PropTypes.array,
  sizeText: PropTypes.string,
};

CategoriesList.defaultProps = {
  title: "Titulo de lista",
  Categories: [],
  sizeText: "md",
};

export default CategoriesList;
