import { Store } from "ks_storybook";
import { AccountLimit } from "../models";

class AccountLimitStore extends Store {
  model = AccountLimit;

  fetch() {
    return this.search({}, "accountLimit", true, "/account_limint");
  }
}

export default AccountLimitStore;
