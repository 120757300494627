const it_IT = {
  languages: {
    1: "Spagnolo",
    2: "Inglese",
    3: "Francese",
    4: "Portoghese",
    5: "Estone",
    6: "Tedesco",
    7: "Italiano"
  },  
  label1: "Lingue",
  label2: "Cambia lingua",
  label3: "Puoi scegliere e cambiare la lingua che preferisci.",
  label4: "Nessuna descrizione",
  label5: "Caricamento dell'applicazione...",
  label6: `La tua sottoscrizione è scaduta. Vai a `,
  label7: "Questo account è connesso da un'altra posizione.",
  label8: "Errore, Nome utente o password errati.",
  label9: "Gli account amministratori non hanno accesso al lettore web!",
  label10: "Utente sospeso! Contattare ",
  label11: "Fornire nome utente e password, per favore.",
  label12: "Utente sospeso dall'amministratore",
  label13: "Errore di connessione al server, controlla la tua connessione.",
  label14: `Utente`,
  label15: `(Non utilizzare l'email)`,
  label16: `Password`,
  label17: `Accedi`,
  label18: `Categoria`,
  label19: `Le mie playlist`,
  label20: `Indietro`,
  label21: `Connettiti al pannello di controllo per creare playlist.`,
  label22: `Nessun dato al momento`,
  label23: `Errore, il PIN deve essere di 4 cifre.`,
  label24: `Errore, PIN incorrecto.`,
  label25: `Lettore bloccato`,
  label26: `Inserisci il tuo PIN per sbloccarlo.`,
  label27: `Obbligatorio`,
  label28: `Disconnetti`,
  label29: `Scegli per tipo di località`,
  label30: `Scegli per stile di musica`,
  label31: `Scegli per altri criteri`,
  label32: `Lettore in stato di ibernazione`,
  label33: `Inserisci il tuo PIN per uscire dall'ibernazione.`,
  label34: `Sblocca`,
  label35: `Annulla`,
  label36: `Home`,
  label37: `In riproduzione`,
  label38: `Canzoni`,
  label39: `Home`,
  label40: `Cerca Playlist`,
  label41: `Libreria musicale`,
  label42: `Artista`,
  label43: `Cerca il nome della canzone`,
  label44: `Cerca l'artista`,
  label45: `Risultati`,
  label46: `Aggiungi come prossimo in riproduzione`,
  label47: `Pianificatore di playlist`,
  label48: `Personalizzazione`,
  label49: `Pianificatore di annunci`,
  label50: `Annunci`,
  label51: `Pannello di controllo`,
  label52: `Informazioni su`,
  label53: `Attivato`,
  label54: `Disattivato`,
  label55: `Versione di aggiornamento del database`,
  label56: `App di musica`,
  label57: `Questa app è stata sviluppata da`,
  label58: `Questa app è stata sviluppata per`,
  label59: "Gennaio, Febbraio, Marzo, Aprile, Maggio, Giugno, Luglio, Agosto, Settembre, Ottobre, Novembre, Dicembre",
  label60: "Gen, Feb, Mar, Apr, Mag, Giu, Lug, Ago, Set, Ott, Nov, Dic",
  label61: "Domenica, Lunedì, Martedì, Mercoledì, Giovedì, Venerdì, Sabato",
  label62: "Dom, Lun, Mar, Mer, Gio, Ven, Sab",
  label63: "Do, Lu, Ma, Me, Gi, Ve, Sa",
  label64: "Connesso",
  label65: "Disconnesso",
  label66: `Vuoi disconnetterti?`,
  label67: "Sì",
  label68: "No",
  label69: `Sposta come prossimo in riproduzione`,
  label70: `Contrassegna come prossimo in riproduzione`,
  label71: `Elimina canzone dalla playlist in riproduzione`,
  label72: `Ricerca in corso...`,
  label73: `Cerca...`,
  label74: `Apri`,
  label75: `Chiudi`,
  label76: `Altre opzioni`,
  label77: `Preferito`,
  label78: `Blocca`,
  label79: `Precedente`,
  label80: `Play`,
  label81: `Pausa`,
  label82: `Successivo`,
  label83: `Playlist in riproduzione`,
  label84: `Vai al pannello di controllo web`,
  label85: `Avanti`,
  label86: `Espandi libreria`,
  label87: `Riduci libreria`,
  label88: `Espandi libreria`,
  label89: `Silenzioso`,
  label90: `Vuoi contrassegnare questa canzone come indesiderata?`,
  label91: `non verrà più riprodotta.`,
  label92: `Caricamento dati...`,
  label93: "Impostazioni",
  label94: "Account",
  label95: "Servizio contrattato",
  label96: "Preferenze",
  label97: "Volume",
  label98: "Puoi modificare o silenziare il volume dal pannello di controllo web.",
  label99: "Pianificatori e annunci",
  label100: "Programma le playlist che ti piacciono nell'orario desiderato, puoi farlo dal pannello di controllo web.",
  label101: "Crea e assegna pubblicità nell'orario desiderato, puoi farlo dal pannello di controllo web.",
  label102: "Carica i tuoi annunci pubblicitari, puoi farlo dal pannello di controllo web.",
  label103: "Informazioni",
  label104: "Versione",
  label105: "Sviluppatore",
  label106: "Sito web",
  label107: "Politiche sulla privacy",
  label108: "Importante per te e per noi.",
  label109: "Supporto",
  label110: "Contiamo sul nostro aiuto.",
  label111: "Altro",
  label112: "Disconnetti",
  label113: "Hai effettuato l'accesso come",
  label114: "IN RIPRODUZIONE DA",
  label115: "Mi piace",
  label116: "Nascondi questa canzone",
  label117: "Sposta come prossimo in riproduzione",
  label118: "Contrassegna come prossimo in riproduzione",
  label119: "Rimuovi dalla lista attuale",
  label120: "Aggiungi come prossimo in riproduzione",
  label121: `Annuncio`,
  label122: "Salva modifiche",
  label123: "Auto-salvataggio (Attivato)",
  label124: "Auto-salvataggio (Disattivato)",
  label125: "Auto-salvataggio attivato",
  label126: "Auto-salvataggio disattivato",
  label127: "La canzone è stata aggiunta con successo alla playlist",
  label128: "Impossibile aggiungere la canzone alla playlist",
  label129: "La playlist è stata creata con successo",
  label130: "Il nome della playlist esiste già, specifica un altro nome",
  label131: "Devi specificare un nome per la nuova playlist",
  label132: "Le canzoni sono state aggiunte con successo alla playlist",
  label133: "Impossibile aggiungere le canzoni alla playlist",
  label134: "Devi selezionare una playlist",
  label135: "L'utente non ha una configurazione di limiti di base, contatta il supporto",
  label136: "La canzone è stata contrassegnata come preferita e sarà sempre programmata",
  label137: "La canzone non è più contrassegnata come preferita e non sarà più programmata",
  label138: "La canzone è stata aggiunta alla playlist in riproduzione",
  label139: "Seleziona una playlist",
  label140: "Playlist manuali",
  label141: "Nome della nuova playlist",
  label142: "Aggiungi",
  label143: "Salva",
  label144: "Aggiungi a una playlist esistente",
  label145: "Aggiungi a una nuova playlist",
  label146: "È necessaria una connessione Internet per completare questa azione, verifica la tua connessione",
  label147: "Aggiungi le canzoni a una playlist esistente",
  label148: "Tutte le canzoni in riproduzione saranno aggiunte a una playlist esistente",
  label149: "Aggiungi le canzoni a una nuova playlist",
  label150: "Tutte le canzoni in riproduzione saranno aggiunte a una nuova playlist",
  label151: "Non hai playlist manuali create dall'utente",
  lavel152: "Salvato con successo",
  label153: "Aggiunta...",
  label154: "Salvataggio...",
  label155: "Impossibile salvare",
  label156: "Non hai il permesso di accedere alle mie playlist",
  label157: "Non hai il permesso di accedere alle mie playlist preferite",
  label158: "Non hai il permesso di accedere alle categorie e alle playlist",
  label159: "Non hai il permesso di cercare in tutto il contenuto musicale solo nelle preferite",
  label160: "Non hai il permesso di accedere alla libreria musicale",
  label161: "Non hai il permesso di accedere alle impostazioni",
  label162: "Non hai il permesso di disconnetterti",
  label163: "Non hai il permesso di accedere al pannello di controllo",
  label164: "Non hai il permesso di accedere al pianificatore di playlist",
  label165: "Non hai il permesso di accedere al pianificatore di annunci",
  label166: "Non hai il permesso di accedere agli annunci",
  label167: "Non hai il permesso di uscire dall'applicazione",
  label168: "Non hai il permesso di Play/Pause della riproduzione",
  label169: "Non hai il permesso di cambiare la canzone in riproduzione",
  label170: "Non hai il permesso di cambiare la barra di avanzamento della canzone in riproduzione",
  label171: "Non hai il permesso di accedere alla vista del mini lettore",
  label172: "Non hai il permesso di spostare le canzoni nella playlist in riproduzione",
  label173: "Non hai il permesso di salvare le modifiche di una playlist manuale in riproduzione",
  label174: "Non hai il permesso di aggiungere canzoni a una nuova o esistente playlist manuale",
  label175: "Non hai il permesso di contrassegnare o disinserire canzoni come preferite",
  label176: "Non hai il permesso di nascondere le canzoni per impedirne la riproduzione",
  label177: "Non hai il permesso di eliminare le canzoni nella playlist in riproduzione",
  label178: "Playlist preferite",
  label179: "Tutte le tue playlist preferite e quelle del tuo amministratore",
  label180: "Accesso limitato tramite PIN",
  label181: "Preferite",
  label182: "Questa playlist è dell'amministratore e non può essere modificata",
  label183: "Tutti i giorni",
  label184: "Dal lunedì al venerdì",
  label185: "Sabato e domenica",
  label186: "Giorno specifico",
  label187: "Ora specifica",
  label188: "Intervallo",
  label189: "Ogni",
  label190: "Frequenza",
  label191: "canzoni",
  label192: "Limite di annunci per blocco",
  label193: "Inizio del blocco",
  label194: "Fine del blocco",
  label195: "Dall'amministratore",
  label196: "Dall'utente",
  label197: `(Amministratore) - `,
  label198: `(Utente) - `,
  label199: `Ibernazione`,
  label200: "Abbonamento non valido, contattare il fornitore del servizio"  
};

export default it_IT;