import { action, observable, computed } from "mobx";
import { RESTClient, LocalStorageClient } from "ks_storybook";
import { ConexionLog, PlaylistLog, SongLog, AdLog, Category } from "../models";
import {
  UIStore,
  UsersStore,
  CategoriesStore,
  CategoriesPlaylistsStore,
  UserGroupStore,
  ConexionsLogsStore,
  PlaylistsStore,
  PlaylistsPlansStore,
  PlaylistsPlansUserStore,
  AdsStore,
  AdsPlansStore,
  AccountLimitStore,
  PreferencesStore,
  PlaylistLogStore,
  SongLogStore,
  AdLogStore,
  UserPlaylistStore,
  UserSyncUpStore,
  UserPersonalizationStore,
  AdvertisingGuidelinesStore,
  UserHibernationStore,
  SongsStore,
  UsersSongsEditStore,
  SongStylesStore,
  UserKSPermissionsStore,
  UserFavoritePlaylistsStore,
} from ".";
import es_ES from "../lang/es_ES.js";
import en_EN from "../lang/en_EN.js";
import fr_FR from "../lang/fr_FR.js";
import pt_PT from "../lang/pt_PT.js";
import et_EE from "../lang/et_EE.js";
import de_DE from "../lang/de_DE.js";
import it_IT from "../lang/it_IT.js";

import KASIMU from "../companies/kasimu.js"
import AUDIOPLAYER from "../companies/audioplayer.js"
import FUNBOXMUSIC from "../companies/funboxmusic.js"
import SMARTMCMUSIC from "../companies/smartmcmusic.js"
import MYSOUND from "../companies/mysonud.js"
import NEOMEDIA from "../companies/neomedia.js";
import BITPROXIMITY from "../companies/bitproximity.js"
import MOZARTPLAY from "../companies/mozartplay.js"
import MLBUISNESS from "../companies/monitorlatino.js";

import pkg from "../../package.json";


export default class AppStore {
  @observable isLoading = true;
  @observable loggedInUser = null;
  @observable typeUser = 0;
  @observable catalog = 0;
  @observable serviceErrors = [];
  @observable lang = 2;
  @observable language = en_EN;
  @observable viewMobile = false;
  @observable viewSmall = false;
  @observable langSeeable = true;
  @observable loggedInUserPersonalization = null;
  @observable username = null;
  //@observable pin = null;
  @observable cacheLastConnectionAppId = null;
  @observable osNumber = 3;
  @observable toggleMenu = false;
  @observable typeView = 6;
  @observable companies = [
    KASIMU,
    AUDIOPLAYER,
    FUNBOXMUSIC,
    SMARTMCMUSIC,
    MYSOUND,
    KASIMU,
    AUDIOPLAYER,
    NEOMEDIA,
    BITPROXIMITY,
    MOZARTPLAY,
    MLBUISNESS
  ];
  @observable currentCompany = AUDIOPLAYER;  
  @observable catalog = 0;
  @observable version = `v ${pkg.version}`;
  @observable menuTitle = "";
  @observable renderHeaderStart = null;
  @observable renderHeaderContent = null;
  @observable renderHeaderEnd = null;
  @observable failsafeMode = false;
  @observable permissionPin = false;
  @observable permissions = [];
  @observable isPlaying = false;
  @observable currentVersionSyncUp = 0;
  @observable lastVersionSyncUp = 0;

  refreshTime = 10000;

  api_token_key = `ks_${process.env.REACT_APP_KASIMU_API_URL}_token`;
  logged_user_key = `ks_${process.env.REACT_APP_KASIMU_API_URL}_user`;

  settings = {
    refreshRate: 5, // in minutes
  };

  constructor() {
    const storedToken = localStorage.getItem(this.api_token_key);
    const storedUser = localStorage.getItem(this.logged_user_key);
    const storedLang = localStorage.getItem("currentLang");
    const storedUsername = localStorage.getItem("username");
    const storedCacheLastConnectionAppId = localStorage.getItem("cacheLastConnectionAppId");
    const storedTypeUser = localStorage.getItem("currentTypeUser");
    const storedTypeView = localStorage.getItem("currentTypeView");
    const storedCatalog = localStorage.getItem("currentCatalog");

    // create adapters
    this.kasimuAPIClient = new RESTClient(
      process.env.REACT_APP_KASIMU_API_URL,
      storedToken
    );
    this.localStorageClient = new LocalStorageClient("ks");

    // initialize stores
    this.stores = new Map();

    // Domain stores
    this.stores.set("users", new UsersStore(this.kasimuAPIClient, this));
    this.stores.set(
      "categories",
      new CategoriesStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "categoriesPlaylists",
      new CategoriesPlaylistsStore(this.kasimuAPIClient, this)
    );
    this.stores.set("groups", new UserGroupStore(this.kasimuAPIClient, this));
    this.stores.set(
      "conexionsLogs",
      new ConexionsLogsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "playlists",
      new PlaylistsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "userHibernation",
      new UserHibernationStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "playlistsPlans",
      new PlaylistsPlansStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "playlistsPlansUser",
      new PlaylistsPlansUserStore(this.kasimuAPIClient, this)
    );
    this.stores.set("adsPlans", new AdsPlansStore(this.kasimuAPIClient, this));
    this.stores.set("ads", new AdsStore(this.kasimuAPIClient, this));
    this.stores.set(
      "accountLimit",
      new AccountLimitStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "preferences",
      new PreferencesStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "playlistLog",
      new PlaylistLogStore(this.kasimuAPIClient, this)
    );
    this.stores.set("songLog", new SongLogStore(this.kasimuAPIClient, this));
    this.stores.set("adLog", new AdLogStore(this.kasimuAPIClient, this));
    this.stores.set(
      "userPlaylist",
      new UserPlaylistStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "userSyncUp",
      new UserSyncUpStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "userPersonalization",
      new UserPersonalizationStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "advertisingGuidelines",
      new AdvertisingGuidelinesStore(this.kasimuAPIClient, this)
    );
    this.stores.set("songs", new SongsStore(this.kasimuAPIClient, this));
    this.stores.set(
      "songStyles",
      new SongStylesStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "usersSongsEdit",
      new UsersSongsEditStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "userKSPermissions",
      new UserKSPermissionsStore(this.kasimuAPIClient, this)
    );
    this.stores.set(
      "userFavoritePlaylists",
      new UserFavoritePlaylistsStore(this.kasimuAPIClient, this)
    );

    // UI stores
    this.stores.set("ui", new UIStore(this.localStorageClient, this));

    // create easy stores getters
    this.stores.forEach((store, key) => {
      Object.defineProperty(this, key, {
        get: (v) => store,
      });

      store.updateThreshold = this.settings.refreshRate;
    });

    // Translate first before you start
    this.lang = parseInt(storedLang) || this.lang;
    this.getLanguage(this.lang);

    // is already a session open?
    if (storedUser > 0) {
      this.users.get(storedUser).andThen((res, err) => {
        if (err) {
          // something went terrible wrong....
          const user = JSON.parse(localStorage.getItem("user"));
          if (user) {
            this.failsafeMode = true;
            this.setCurrentUser(user);
            this.typeUser = parseInt(storedTypeUser);
            this.typeView = parseInt(storedTypeView);
            this.currentCompany = this.companies[this.typeView];
            this.catalog = parseInt(storedCatalog);
            this.username = storedUsername;
            this.cacheLastConnectionAppId = storedCacheLastConnectionAppId;
            this.lastVersionSyncUp = 0;
            this.currentVersionSyncUp = 0;
            this.isLoading = false;
            this.langSeeable = false;

            // this.lang = this.getNumLang(user.preference.language_app);
            // this.getLanguage(this.lang);
            this.geolocation();
            this.loadUserPersonalization();
            this.ui.signIn();
          } else {
            this.typeView = parseInt(storedTypeView);
            this.currentCompany = this.companies[this.typeView];
            this.signOut();
            this.isLoading = false;
          }
        } else {
          this.loadPermissions().andThen((permissions) => {
            if (permissions.length > 0) {
              let per = permissions.toArray();
              this.setCurrentPermissions(per);
            }
            localStorage.setItem(
              "user",
              JSON.stringify({ ...res.toJS(), configs: res.configs.toJS() })
            );
            this.setCurrentUser(res);
            this.typeUser = parseInt(storedTypeUser);
            this.typeView = parseInt(storedTypeView);
            this.currentCompany = this.companies[this.typeView];
            this.catalog = parseInt(storedCatalog);
            this.username = storedUsername;
            this.cacheLastConnectionAppId = storedCacheLastConnectionAppId;
            this.lastVersionSyncUp = 0;
            this.currentVersionSyncUp = 0;
            this.isLoading = false;
            this.langSeeable = false;
            // this.lang = this.getNumLang(res.preference.language_app);
            // this.getLanguage(this.lang);
            this.geolocation();
            this.loadUserPersonalization();
            this.ui.signIn();
          });
        }
      });
    } else {
      this.typeView = parseInt(storedTypeView);
      this.currentCompany = this.companies[this.typeView];
      this.signOut();
      this.isLoading = false;
    }
  }

  @action
  signOut() {
    this.langSeeable = true;
    if (this.loggedInUser != null) {
      this.disconnect_or_logout(1);
    }

    this.kasimuAPIClient.token = null;
    this.loggedInUser = null;
    this.loggedInUserPersonalization = null;
    this.permissions = [];
    this.currentVersionSyncUp = 0;
    this.lastVersionSyncUp = 0;
    this.ui.signOut();
    this.stores.forEach((store, key) => {
      store.clear();
    });

    localStorage.setItem(this.api_token_key, null);
    localStorage.setItem(this.logged_user_key, null);
    // localStorage.setItem("currentLang", 1);
  };

  @action
  signIn(username, password) {
    localStorage.setItem("currentLang", this.lang);
    this.setTypeView(this.typeView);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    const urlencoded = new URLSearchParams();
    urlencoded.append("username", username);
    urlencoded.append("password", password);
    urlencoded.append("os_number", this.osNumber);
    urlencoded.append("os_str", "WebPlayer|"+this.getNameNavegador());
    urlencoded.append("reseller_companies_id", this.currentCompany.reseller_companies_id);
    urlencoded.append("version_app_number", pkg.version.replace(/\./g, ""));
    urlencoded.append("version_app_str", pkg.version); 

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow"
    };

    return fetch(this.kasimuAPIClient.apiURI+"/login_apps", requestOptions)
    .then((response) => response.json())
    .then((result) => {
        if (result.answer_code == 3) {
          this.kasimuAPIClient.token = result.token
          this.loadPermissions().andThen((permissions) => {
            if (permissions.length > 0) {
              let per = permissions.toArray();
              this.setCurrentPermissions(per);
            }
            this.setCurrentUser(this.users.store(result.user));

            // save the info in storage
            localStorage.setItem(this.api_token_key,this.kasimuAPIClient.token);
            localStorage.setItem(this.logged_user_key, this.loggedInUser.id);
            localStorage.setItem("currentTypeUser", result.type_user);
            localStorage.setItem("currentCatalog", result.catalog);

            // this.lang = this.getNumLang(res.user.preference.language_app);
            // this.getLanguage(this.lang);
            this.typeUser = result.type_user;
            this.catalog = result.catalog;
            this.cacheLastConnectionAppId = result.cache_last_connection_app.id;
            this.lastVersionSyncUp = 0;
            this.currentVersionSyncUp = 0;
            this.username = username;
            localStorage.setItem("username", this.username);
            localStorage.setItem("cacheLastConnectionAppId", this.cacheLastConnectionAppId);
            this.loadUserPersonalization();
            this.geolocation();
            this.langSeeable = false;
            this.ui.signIn();
          });
        }
        return result;  
    }).catch((error) => {
      let res = {"answer_code": 12};
      return res;
    });
  };

  /*@action
  signIn(user, password) {
    localStorage.setItem("currentLang", this.lang);
    this.setTypeView(this.typeView);
    if (this.username != user) {
      this.pin = null;
    }
    return this.kasimuAPIClient
      .authenticate(user, password, this.pin, "/login_webplayer")
      .then((res) => {
        if (res.codError === 3) {
          this.loadPermissions().andThen((permissions) => {
            if (permissions.length > 0) {
              let per = permissions.toArray();
              this.setCurrentPermissions(per);
            }
            this.setCurrentUser(this.users.store(res.user));

            // save the info in storage
            localStorage.setItem(
              this.api_token_key,
              this.kasimuAPIClient.token
            );
            localStorage.setItem(this.logged_user_key, this.loggedInUser.id);
            localStorage.setItem("currentTypeUser", res.type_user);
            localStorage.setItem("currentCatalog", res.catalog);

            // this.lang = this.getNumLang(res.user.preference.language_app);
            // this.getLanguage(this.lang);
            this.typeUser = res.type_user;
            this.catalog = res.catalog;
            if (this.pin == null) {
              this.pin = res.pin;
            }
            this.username = user;
            localStorage.setItem("username", this.username);
            localStorage.setItem("pin", this.pin);
            //const conexionLog = this.newConexionLog(3);
            //conexionLog.save();
            this.loadUserPersonalization();
            this.langSeeable = false;
            this.ui.signIn();
          });
        } else {
          this.ui.signIn();
        }
      });
  };*/

  @action
  geolocation = () => { // servicio de geolocalizacion
    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };
    
    fetch("https://geoservice.megaplayer.me/locate", requestOptions)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json(); // Cambiado a .json() para procesar la respuesta como JSON
    })
    .then((result) => {
      console.log(result)
      this.apiGeolocation(result);
    }) // `result` es un objeto JSON
    .catch((error) => console.error("Error:", error));
  };

  @action
  apiGeolocation = (params) => {
    this.kasimuAPIClient.putParams("/cache_last_geolocation/update_or_create_geolocation", params);
  };

  @action
  loadUserPersonalization = () => {
    if (!this.failsafeMode) {
      const x = this.userPersonalization.getUsersPersonalization();
      x.andThen((response) => {
        if (response.length > 0) {
          let res = response.toArray();
          this.setCurrentUserPersonalization(
            this.userPersonalization.store(res[0])
          );
        }
      });
    }
  };

  @action
  setCurrentUserPersonalization(personalization) {
    this.loggedInUserPersonalization = personalization;
  }

  @action
  setCurrentUser(user) {
    this.loggedInUser = user;
  }

  @action
  setTypeView(type) {
    localStorage.setItem("currentTypeView", type);
    this.typeView = type;
    this.currentCompany = this.companies[this.typeView];
  };

  @action
  disconnect_or_logout(connectionType) {
    if (this.cacheLastConnectionAppId != null) {
      const params = {
        last_connection_app_id: this.cacheLastConnectionAppId,
        connection_type: connectionType,
        os_str: "WebPlayer|"+this.getNameNavegador()
      };
      this.kasimuAPIClient.putParams("/cache_last_connection_app/disconnect_or_logout", params);
    }
  };

  getNameNavegador() {
    var aKeys = ["MSIE", "Firefox", "Safari", "Chrome", "Opera"],
        sUsrAg = navigator.userAgent,
        nIdx = aKeys.length - 1;

    for (nIdx; nIdx >= 0; nIdx--) {
        if (sUsrAg.indexOf(aKeys[nIdx]) !== -1) {
            return aKeys[nIdx];
        }
    }

    // Si no se encuentra ningún navegador en el User Agent String, devuelve un valor predeterminado
    return "Unknown Browser";
  };

  @action
  updateConnection(connectionType,playbackStatus) {
    const params = {
      last_connection_app_id: this.cacheLastConnectionAppId,
      reseller_companies_id: this.currentCompany.reseller_companies_id,
      os_number: this.osNumber,
      os_str: "WebPlayer|"+this.getNameNavegador(),
      connection_type: connectionType,
      version_app_number: pkg.version.replace(/\./g, ""),
      version_app_str: pkg.version,
      mac_address: '',
      playback_status: playbackStatus?1:2
    };
    return this.kasimuAPIClient.putParams("/cache_last_connection_app/update_cache_last_connection_webplayer", params)
    .then((result) => {
      this.cacheLastConnectionAppId = result.results.last_connection_app_id;
      localStorage.setItem("cacheLastConnectionAppId", this.cacheLastConnectionAppId);
      this.failsafeMode = false;
      return result;
    }).catch((error) => {
      this.failsafeMode = true;
      return error;
    });
  };

  @action
  checkCatalogChange(results) {
    if (this.catalog === results.catalog) return false;
    this.catalog = results.catalog;
    localStorage.setItem("currentCatalog", this.catalog);
    return true;
  };

  checkVersionSyncUp = () => {
    if (this.lastVersionSyncUp == this.currentVersionSyncUp)
      return false;
    else {
      this.lastVersionSyncUp = this.currentVersionSyncUp;
      return true;
    }
  };

  @action
  checkUserSyncUp = (versionSyncUp) => {
    if (this.currentVersionSyncUp == 0 && this.lastVersionSyncUp == 0) {
      this.currentVersionSyncUp = versionSyncUp;
      this.lastVersionSyncUp = this.currentVersionSyncUp;
    } else {
      if (this.currentVersionSyncUp < versionSyncUp) {
        this.currentVersionSyncUp = versionSyncUp;
        return this.checkVersionSyncUp();
      }
    }
    return false;
  };

  @action
  setCurrentPermissions(permissions) {
    this.permissions = permissions;
  };

  @action
  loadTypeView() {
    if (isNaN(this.typeView)) {
      this.typeView = 1;
    }
    localStorage.setItem("currentTypeView", this.typeView);
    this.currentCompany = this.companies[this.typeView];
  }

  @computed
  get isLoggedIn() {
    const loggedInUser = this.loggedInUser; // I need to do this for MOBX to catch it....
    return this.kasimuAPIClient.token != null && loggedInUser != null;
  }

  getIsLoggedIn() {
    const loggedInUser = this.loggedInUser; // I need to do this for MOBX to catch it....
    return this.kasimuAPIClient.token != null && loggedInUser != null;
  }

  @computed
  get isLoggingIn() {
    const loggedInUser = this.loggedInUser; // I need to do this for MOBX to catch it....
    return this.kasimuAPIClient.token != null && loggedInUser == null;
  }

  @computed
  get loggedInUserKey() {
    if (this.isLoggedIn) {
      return this.loggedInUser.id;
    }

    return null;
  }

  getCurrentTheme() {
    return "kasimu";
  }

  goToPanel = () => {
    window.open(this.currentCompany.url_panel, "_blank");
  };

  // @action
  getLanguage(val) {
    if (val == 2) {
      localStorage.setItem("currentLang", val);
      return (this.language = en_EN);
    }
    if (val == 1) {
      localStorage.setItem("currentLang", val);
      return (this.language = es_ES);
    }
    if (val == 3) {
      localStorage.setItem("currentLang", val);
      return (this.language = fr_FR);
    }
    if (val == 4) {
      localStorage.setItem("currentLang", val);
      return (this.language = pt_PT);
    }
    if (val == 5) {
      localStorage.setItem("currentLang", val);
      return (this.language = et_EE);
    }
    if (val == 6) {
      localStorage.setItem("currentLang", val);
      return (this.language = de_DE);
    }
    if (val == 7) {
      localStorage.setItem("currentLang", val);
      return (this.language = it_IT);
    }
  }

  // @action
  getNumLang(val) {
    if (val == "en_US") return 2;
    if (val == "en_EN") return 2;
    if (val == "es_ES") return 1;
    if (val == "fr_FR") return 3;
    if (val == "pt_PT") return 4;
    if (val == "et_EE") return 5;
    if (val == "de_DE") return 6;
    if (val == "it_IT") return 7;
  }

  @action
  handleHeader(content, types) {
    types.map((e) => {
      if (e === "renderHeaderStart") this.renderHeaderStart = content;
      if (e === "renderHeaderContent") this.renderHeaderContent = content;
      if (e === "renderHeaderEnd") this.renderHeaderEnd = content;
    });
  }

  getFreeRights() {
    return this.catalog;
  }

  replaceBucketUrl(originalUrl) {
    if (originalUrl) {
      // URL base a reemplazar
      let oldBaseUrl = process.env.REACT_APP_KASIMU_API_URL == "https://api.megaplayer.me"
      ? "https://bucket-kasimu.s3.amazonaws.com/"
      : "https://bucket-kasimu-test.s3.amazonaws.com/";

      // Nueva URL base
      let newBaseUrl = this.getBucketUrl();

      // Reemplazar la URL base en la URL original
      let newUrl = originalUrl.replace(oldBaseUrl, newBaseUrl);
      
      // Devolver la nueva URL
      return newUrl;
    }
    return null;
  }

  getBucketUrl() {
    return process.env.REACT_APP_KASIMU_API_URL == "https://api.megaplayer.me"
      ? "https://sources.megaplayer.me/"
      : "https://sources-test.megaplayer.me/";
  }

  getKasimuCategoriePlaylist() { //Agregar cada vez que se agrega un catalogo
    const categorie = [1, 497, 806, 903];
    let cat = categorie[this.catalog];
    return cat;
  }

  getServiceName() { //Agregar cada vez que se agrega un catalogo
    const title = ["Global", "Freedom 1", "Freedom 2", "Freedom 3"];
    let name = this.currentCompany.name + " ";
    name = name + title[this.catalog];
    return name;
  }

  getSingleUser() {
    if (this.typeUser == 1) return false; //si es 1 es administrador
    return true;
  }

  currentDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    var hour = today.getHours();
    var minutes = today.getMinutes();
    var seconds = today.getSeconds();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    today =
      yyyy + "-" + mm + "-" + dd + " " + hour + ":" + minutes + ":" + seconds;
    return today;
  }

  showContent(name) {
    switch (name) {
      case "Kasimu": {
        const valid = {
          0: true,
          1: false,
          2: false,
          3: false,
          4: false
        };

        return valid[this.typeView] || false;
      }
    }
  }

  @action
  loadPermissions = (valid = false) => {
    if (valid) {
      this.loadPermissions().andThen((permissions) => {
        if (permissions.length > 0) {
          let per = permissions.toArray();
          this.setCurrentPermissions(per);
        }
      });
    } else {
      return this.userKSPermissions.fetchAllParams();
    }
  };

  getPermission(id, msg, fc) {
    const permission = {
      0: () => this.getMessagePermission(msg),
      1: () => fc(),
      2: () => this.getPinPermission(fc),
    };

    return permission[this.validPermission(id)]();
  }

  verifyPermission(id) {
    let typeAccess = true;
    const permission = this.permissions.find((per) => per?.id == id);

    if (permission) {
      if (permission?.user_permission.permission_activated) {
        if (permission.user_permission.requires_pin) {
          typeAccess = true;
        } else {
          typeAccess = false;
        }
      }
    } else {
      typeAccess = false;
    }

    return typeAccess;
  }

  validPermission(id) {
    let typeAccess = 0;
    const permission = this.permissions.find((per) => per.id === id);

    if (permission) {
      if (permission.user_permission.permission_activated) {
        if (permission.user_permission.requires_pin) {
          typeAccess = 2;
        } else {
          typeAccess = 1;
        }
      }
    } else {
      typeAccess = 1;
    }

    return typeAccess;
  }

  getMessagePermission(id) {
    const message = {
      1: this.language.label156,
      2: this.language.label157,
      3: this.language.label158,
      4: this.language.label159,
      5: this.language.label160,
      6: this.language.label161,
      7: this.language.label162,
      8: this.language.label163,
      9: this.language.label164,
      10: this.language.label165,
      11: this.language.label166,
      12: this.language.label167,
      13: this.language.label168,
      14: this.language.label169,
      15: this.language.label170,
      16: this.language.label171,
      17: this.language.label172,
      18: this.language.label173,
      19: this.language.label174,
      20: this.language.label175,
      21: this.language.label176,
      22: this.language.label177,
    };
    const msg = message[id];

    if (msg) {
      this.toastManager.add(message[id], {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }

  getPinPermission(fc) {
    this.permissionPin = true;
    this.permissionFc = fc;
  }

  validatePinPermission() {
    this.permissionPin = false;
    this.permissionFc();
  };

  newPlaylistLog(playlist_id, playlist_type, playlist_owner) {
    const today = this.currentDate();
    return new PlaylistLog(
      {
        playlist_id: playlist_id,
        play_date: today,
        playlist_type: playlist_type,
        playlist_owner: playlist_owner,
        deleted: 0,
      },
      this.playlistLog
    );
  }

  newSongLog(songId) {
    const today = this.currentDate();
    return new SongLog(
      {
        song: songId,
        play_date: today,
        addedby: 1,
        deleted: 0,
      },
      this.songLog
    );
  }

  newAdLog(pattern, advertisements_id, duration) {
    const today = this.currentDate();
    let frequency = 0;
    if (pattern.priority == 3 || pattern.priority == 5) {
      frequency = pattern.frequency_songs;
    } else if (pattern.priority == 4 || pattern.priority == 6) {
      frequency = pattern.frequency_minutes;
    }
    return new AdLog(
      {
        advertisements_id: advertisements_id,
        week_day: pattern.week_day,
        exact_day: pattern.exact_day,
        exact_hour: pattern.exact_hour,
        from_hour: pattern.from_hour,
        to_hour: pattern.to_hour,
        frequency: frequency,
        priority: pattern.priority,
        play_date: today,
        duration: duration,
        deleted: 0,
      },
      this.adLog
    );
  }

  newMyPlaylistsCategories(freeRights) {
    return new Category(
      {
        id: 5000,
        name: this.language.label19,
        description: this.language.label19,
        icon_path: this.getBucketUrl() + "images/c-pu.png",
        free_rights: freeRights,
        list_view: 3,
        order_view: 3,
        deleted: 0,
        created_at: "2019-04-10T04:24:01Z",
        updated_at: "2019-04-10T04:24:01Z",
      },
      this.categories
    );
  }

  newFavoritesPlaylistsCategories(freeRights) {
    return new Category(
      {
        id: 23,
        name: this.language.label178,
        description: this.language.label179,
        icon_path: this.getBucketUrl() + "images/c-pf.png",
        free_rights: freeRights,
        list_view: 3,
        order_view: 3,
        deleted: 0,
        created_at: "2019-04-10T04:24:01Z",
        updated_at: "2019-04-10T04:24:01Z",
      },
      this.categories
    );
  }
}
