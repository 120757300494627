import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import withStore from "../../hocs/withStore";
import {
  Img,
  RectSkeleton,
  Text,
  CircleSkeleton,
  ClickableImg,
  Button,
  TextInput,
  CardAlbum,
  Columns,
  Column,
} from "ks_storybook";
import { PlayerView } from "../../Components/AudioPlayer/";
import { Tooltip } from "react-tooltip";

@observer
class CategoryPlaylists extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryPlaylists: null,
      selectedCategoryPlaylist: null,
      name: "",
      selectedPlaylist: false,
    };

    this.handleBackwards = this.handleBackwards.bind(this);
  }

  componentDidMount() {
    const category = this.props.category;

    if (category.id != 5000) {
      if (category.id == 23) {
        this.setState({
          categoryPlaylists: this.getFavoritePlaylists(category),
        });
      } else {
        this.setState({
          categoryPlaylists: this.getCategoryPlayLists(category),
        });
      }
    } else {
      this.setState({ categoryPlaylists: this.getMyPlayLists() });
    }
  }

  componentDidUpdate(prevProps) {
    const category = this.props.category;

    if (category !== prevProps.category) {
      if (category.id != 5000) {
        if (category.id == 23) {
          this.setState({
            categoryPlaylists: this.getFavoritePlaylists(category),
          });
        } else {
          this.setState({
            categoryPlaylists: this.getCategoryPlayLists(category),
          });
        }
      } else {
        this.setState({ categoryPlaylists: this.getMyPlayLists() });
      }
    }
  }

  handleChange = (sender, value, name, validation) => {
    this.setState({ name: value });
  };

  handlePlaylistClick = (categoryPlaylist) => {
    this.props.store.ui.loadBalancer();
    this.setState({
      selectedCategoryPlaylist: categoryPlaylist,
      selectedPlaylist: true,
    });
    this.props.store.ui.selectedCategoryPlaylist = categoryPlaylist;
    this.props.store.ui.isPlayer = true;
  };

  getCategoryPlayLists(category) {
    if (!this.props.store.failsafeMode) {
      return this.props.store.categoriesPlaylists.fetchSearchId(
        category.id,
        this.props.store.lang
      );
    }
  }

  getMyPlayLists() {
    return this.props.store.userPlaylist.fetchAll();
  }

  getFavoritePlaylists() {
    return this.props.store.userFavoritePlaylists.fetchAllPlaylistsFavorites(
      this.props.store.lang
    );
  }

  renderSkeleton(playlists) {
    return (
      <div>
        <Columns style={{ gridGap: "2rem" }} isVCentered>
          <Column isSize={{ mobile: 12, desktop: 4, tablet: 4 }}>
            <RectSkeleton width="100%" height="250px" />
          </Column>
          <Column isSize={{ mobile: 12, desktop: 8, tablet: 8 }}>
            <div>
              <RectSkeleton width="170px" height="15px" />
              <div className="py-1">
                <RectSkeleton width="370px" height="50px" />
              </div>
              <RectSkeleton width="370px" height="15px" />
              <RectSkeleton width="170px" height="15px" />
            </div>
          </Column>
        </Columns>
        <div className="my-4" style={{ maxWidth: "580px" }}>
          <RectSkeleton width="100%" height="30px" />
        </div>
        <Columns fill="170">
          {playlists.map((p, idx) => (
            <Column key={idx}>
              <RectSkeleton width="100%" height="150px" />
              <RectSkeleton width="100%" height="15px" />
              <RectSkeleton width="100%" height="15px" />
            </Column>
          ))}
        </Columns>
      </div>
    );
  }

  getIdentifier = (userId) => {
    if (this.props.store.loggedInUser.id == userId)
      return this.props.store.language.label196;
    return this.props.store.language.label195;
  };

  renderPlaylistRow(playlist, idx) {
    const { category } = this.props;

    if (!playlist.id)
      return (
        <Column>
          <div style={{ aspectRatio: "1 / 1" }}>
            <RectSkeleton height="100%" width="100%" />
          </div>
        </Column>
      );

    if (category.id != 5000) {
      return (
        <Column>
          <div
            onClick={() =>
              //this.props.store.getPermission(3, 3, () => {
                this.handlePlaylistClick(playlist)
              //})
            }
            style={{ position: "relative", height: "100%" }}
          >
            <CardAlbum
              id={idx}
              title={playlist.alias}
              description={playlist.description}
              src={this.props.store.replaceBucketUrl(playlist.icon_path)}
              mobile={!this.props.store.viewMobile}
              style={{
                borderRadius: "6px",
              }}
            />

            {this.props.store.viewMobile && (
              <Tooltip
                id={"playlist-" + idx}
                place="bottom"
                style={{ zIndex: 3 }}
              >
                <span>{playlist.alias}</span>
              </Tooltip>
            )}
          </div>
        </Column>
      );
    } else {
      return (
        <Column>
          <div
            onClick={() =>
              //this.props.store.getPermission(3, 3, () => {
                this.handlePlaylistClick(playlist)
              //})
            }
            style={{ position: "relative" }}
          >
            <CardAlbum
              id={idx}
              // title={this.getIdentifier(playlist.user_id)}
              title={playlist.name}
              description={`${
                playlist.description || this.props.store.language.label4
              } \n ${this.getIdentifier(playlist.user_id)}`}
              src={
                playlist.static === 1
                  ? this.props.store.ui._iconMyplaylists2
                  : this.props.store.ui._iconMyplaylists
              }
              style={{
                borderRadius: "6px",
              }}
            />
            {this.props.store.viewMobile && (
              <Tooltip
                id={"playlist-" + idx}
                place="bottom"
                style={{ zIndex: 3 }}
              >
                <span>{playlist.name}</span>
              </Tooltip>
            )}
          </div>
        </Column>
      );
    }
  }

  renderMessage() {
    return (
      <Columns
        isGapless
        isMarginless
        className="py-3"
        style={{
          padding: `0 ${this.props.store.viewMobile ? "50px" : "20px"}`,
        }}
      >
        <Column className="has-text-centered">
          <Text
            color="black"
            weigth="strong"
            lead
            size={this.props.store.viewMobile ? "md" : "sm"}
            multiline
          >
            {this.props.store.language.label21}
          </Text>
        </Column>
      </Columns>
    );
  }

  search = (data) => {
    const { name } = this.state;
    const { category } = this.props;

    if (!data[0].id) {
      return data;
    }

    if (category.id != 5000) {
      if (category.id == 23) {
        data = data.map((e) => e.categories_playlists);
      }
      return data.filter(
        (item) =>
          item.alias?.toLowerCase()?.includes(name) ||
          item.alias?.includes(name)
      );
    } else {
      return data.filter(
        (item) =>
          item.name?.toLowerCase()?.includes(name) || item.name?.includes(name)
      );
    }
  };

  handleBackwards = () => {
    const path = "/app";

    const history = this.props.store.ui.onBackwards();

    if (history) {
      if (history == "/player/categories") {
        this.props.store.ui.isCategory = true;
        this.props.store.ui.isReproducing = false;
        this.props.history.push(path + "/player/home");
      } else if (history == "/player/playlist") {
        this.props.store.ui.isReproducing = true;
        this.props.store.ui.isCategory = false;
        this.props.history.push(path + "/player/home");
      } else {
        this.props.store.ui.isCategory = false;
        this.props.store.ui.isReproducing = false;
        this.props.history.push(path + history);
      }
    }
  };

  render() {
    const { category } = this.props;

    const { categoryPlaylists, selectedPlaylist, selectedCategoryPlaylist } =
      this.state;

    const { isCategory, isReproducing, isPlayer } = this.props.store.ui;

    const isAllPlaylists = category.id === this.props.store.ui.allPlaylists?.id;

    if (!categoryPlaylists) return null;

    const playlists = !categoryPlaylists.isOk()
      ? category._store.getDummy(10)
      : categoryPlaylists.toArray();

    if (categoryPlaylists.isEmpty()) {
      return this.renderSkeleton(playlists);
    }

    if (selectedCategoryPlaylist && isPlayer) {
      return (
        <React.Fragment>
          {!this.props.store.ui.hideMiniPlayer &&
            this.props.store.viewMobile && (
              <div className="is-hidden-desktop">
                <div className="mt-6"></div>
              </div>
            )}
          <PlayerView
            categoryPlaylist={selectedCategoryPlaylist}
            category={category}
            onBackwards={this.handleBackwards}
            mobile={this.props.mobile}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {/* {!this.props.store.ui.hideMiniPlayer && this.props.mobile && (
          <div className="is-hidden-desktop">
            <div className="mt-6"></div>
          </div>
        )} */}
        <div>
          {this.props.store.viewMobile ? (
            <div>
              <div
                className="flex items-center"
                style={{ gap: "70px", flexWrap: "wrap" }}
              >
                <Img height="auto" width="320" src={this.props.store.replaceBucketUrl(category.icon_path)} />

                <div>
                  <Text size="sm" lead weight="medium">
                    {this.props.store.language.label18}
                  </Text>
                  <Text
                    className="py-1"
                    size="xl"
                    lead
                    weight="black"
                    lineBreak
                  >
                    {category.name}
                  </Text>
                  <Text size="md" weight="medium" multiline>
                    {category.description}
                  </Text>
                  <Text size="md" weight="medium" color="secondary" multiline>
                    {`${playlists?.length} Playlists`}
                  </Text>
                </div>
              </div>

              <div className="my-4" style={{ maxWidth: "580px" }}>
                {isAllPlaylists && (
                  <TextInput
                    invert
                    backgroundColor="grayLight"
                    className="is-fullwidth"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    name="search"
                    autocomplete="nope"
                    placeholder={this.props.store.language.label73}
                    iconKasimu="search"
                    colorIcon="dark"
                    onChange={this.handleChange}
                  />
                )}
              </div>
            </div>
          ) : (
            <>
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  width: "100%",
                  padding: "0 12px",
                  height: 8 * 8,
                  background: "transparent",
                  zIndex: 10,
                }}
                id="show-header"
              >
                <div className="flex items-center" style={{ height: "100%" }}>
                  <Button
                    size="xl"
                    iconKasimu="arrow-left"
                    onClick={this.handleBackwards}
                    color="basic"
                    className="mr-2"
                    id="go-back"
                    kind="link"
                    space
                  />
                  <div id="show-category" style={{ display: "none" }}>
                    <Text size="xl" weight="bold" lineBreak>
                      {category.name}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="pb-2 pt-5">
                <div
                  className="flex items-center"
                  style={{ justifyContent: "center" }}
                >
                  <Img height="auto" width="280" src={this.props.store.replaceBucketUrl(category.icon_path)} />
                </div>
                <div className="py-2">
                  <Text size="md" lead weight="black" lineBreak>
                    {category.name}
                  </Text>
                  <Text color="special" size="md" weight="medium" multiline>
                    {category.description}
                  </Text>
                </div>
              </div>
            </>
          )}
          <Columns
            fill={this.props.store.viewMobile ? "170" : "130"}
            style={{ gap: this.props.store.viewMobile && "24px" }}
          >
            {playlists.length > 0
              ? this.search(playlists).map((p, idx) =>
                  this.renderPlaylistRow(p, idx)
                )
              : this.renderMessage()}
          </Columns>
        </div>
      </React.Fragment>
    );
  }
}

export default withStore(withRouter(CategoryPlaylists));
