import React, { lazy, Component, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { ToastProvider } from "react-toast-notifications";
import { ThemeProvider } from "react-jss";
import { Themes, Loader, Text, Panel, Field } from "ks_storybook";
import AppStore from "./stores/AppStore";
import AppContext from "./AppContext";
//import  PasswordRecoveryRouter from './views/PasswordRecoveryRouter/PasswordRecoveryRouter'

//const PublicRouter = lazy(() => import('./views/PublicRouter/PublicRouter'));

import AppRouter from "./views/AppRouter/AppRouter";
import MobileAppRouter from "./views/AppRouter/MobileAppRouter";
import SessionRouter from "./views/SessionRouter/SessionRouter";
import MediaQuery from "react-responsive";
import GlobalStyle from "./styles/globalStyle.js";

const MyCustomToast = ({ appearance, children }) => (
  <div
    style={{
      margin: "0px auto",
      marginBottom: 5,
      background: appearance === "error" ? "#df0101" : "#01a9db",
      padding: "4px 10px",
      borderRadius: 15,
      fontSize: 14,
      color: "white",
      textAlign: "center",
      width: "fit-content",
    }}
  >
    {children}
  </div>
);

@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.appStore = new AppStore();
    this.state = {};
  };

  handleName = () => {
    document.title = `${this.appStore.currentCompany.name}`;
  };

  handleFavicon = () => {
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = this.appStore.currentCompany.favicon;
  };

  renderFrame() {
    const myArray = window.location.pathname.split("/");
    if (myArray[3]) {
      if (myArray[2] == "signIn") {
        if (myArray[3] >= this.appStore.companies.length) {
          this.appStore.setTypeView(1);
        } else this.appStore.setTypeView(myArray[3]);
      }
    } else {
      this.appStore.loadTypeView();
    }
    this.handleFavicon();
    this.handleName();

    return (
      <React.Fragment>
        <MediaQuery minDeviceWidth={1025}>
          {(desk) => {
            return (
              <BrowserRouter>
                <Switch>
                  <Route path={"/session"} component={SessionRouter} />
                  {/* <Route  path = {'/reset_password' } component={ PasswordRecoveryRouter } /> */}
                  {/*<Route path={'/public'} component={PublicRouter} />*/}
                  {(this.appStore.viewMobile = desk)}
                  <Route
                    path={"/app"}
                    component={desk ? AppRouter : MobileAppRouter}
                  />
                  {/* Replace this by a 404... maybe.... */}
                  <Redirect
                    to={"/app"}
                    component={desk ? AppRouter : MobileAppRouter}
                  />
                </Switch>
              </BrowserRouter>
            );
          }}
        </MediaQuery>
        <MediaQuery minDeviceWidth={576}>
          {(small) => (this.appStore.viewSmall = !small)}
        </MediaQuery>
        {this.appStore.serviceErrors.length > 0
          ? this.renderServiceErrors()
          : null}
      </React.Fragment>
    );
  }

  renderServiceErrors() {
    return (
      <Panel
        color="error"
        style={{ position: "fixed", bottom: "8px", left: "8px", right: "8px" }}
      >
        {this.appStore.serviceErrors.map((error) => (
          <Text>
            {error.message}
            <Text
              size="xs"
              color="white"
            >{`${error.service} - ${error.detail}`}</Text>
          </Text>
        ))}
      </Panel>
    );
  }

  render() {
    const loader = (
      <Loader
        className="fullscreen"
        label={this.appStore.language.label5}
        animation="spin"
      />
    );

    return (
      <AppContext.Provider value={this.appStore}>
        <GlobalStyle />
        <ThemeProvider theme={Themes[this.appStore.getCurrentTheme()]}>
          <ToastProvider
            placement="bottom-center"
            autoDismissTimeout={5000}
            components={{
              Toast: MyCustomToast,
            }}
          >
            <React.Fragment>
              <Suspense fallback={loader}>
                {this.appStore.isLoading ? loader : this.renderFrame()}
              </Suspense>
            </React.Fragment>
          </ToastProvider>
        </ThemeProvider>
      </AppContext.Provider>
    );
  }
}

export default App;
