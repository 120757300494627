import React, { Component } from "react";
import PropTypes from "prop-types";
import { observable } from "mobx";
import { RectSkeleton, Text, CardSong, Button } from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { ModalSheet } from "../Common";
import SongModal from "./SongModal";
import { withToastManager } from "react-toast-notifications";

@observer
class LibraryRow extends Component {
  @observable usersSongsEditNew;
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      messageError: false,
      open: false,
      showModalSong: false,
      createPlaylist: false,
      loading: false,
    };
  }

  handleShowModalSong = (create = false) => {
    this.setState({ showModalSong: true, createPlaylist: create });
  };

  handleCloseModalSong = () => {
    this.setState({ showModalSong: false });
  };

  handleSavePlaylist = (playlist_id) => {
    const { song, toastManager } = this.props;
    const playlist_songs = [song.id];

    if (playlist_id) {
      this.setState({ loading: true });
      const params = {
        playlist_id: playlist_id,
        playlist_songs: playlist_songs,
      };

      this.props.store.kasimuAPIClient
        .post("/users_playlist/add_songs_to_playlist", params)
        .then(
          (res) => {
            this.setState({ loading: false });
            const { answer_code } = res.results;
            switch (answer_code) {
              case 1:
                this.handleCloseModalSong();
                toastManager.add(this.props.store.language.label127, {
                  appearance: "success",
                  autoDismiss: true,
                });
                break;
              case 2:
                toastManager.add(this.props.store.language.label128, {
                  appearance: "error",
                  autoDismiss: true,
                });
                break;
            }
          },
          (error) => {
            this.setState({ loading: false });
            toastManager.add(this.props.store.language.label128, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        )
        .catch((error) => {
          this.setState({ loading: false });
          toastManager.add(this.props.store.language.label128, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    } else {
      toastManager.add(this.props.store.language.label134, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  handleCreatePlaylist = (name) => {
    const { song, toastManager } = this.props;
    const playlist_songs = [song.id];

    if (name) {
      this.setState({ loading: true });
      const params = {
        playlist_id: 0,
        song_lenguages_id: 1,
        name: name,
        description: "",
        free_rights: 0,
        static: 1,
        explicit: 0,
        random: 0,
        num_blocks: 0,
        origin_type: 0,
        deleted: 0,
        playlist_songs: playlist_songs,
      };

      this.props.store.kasimuAPIClient
        .post("/users_playlist/create_playlist_all", params)
        .then(
          (res) => {
            this.setState({ loading: false });
            const { answer_code } = res.results;
            switch (answer_code) {
              case 1:
                this.handleCloseModalSong();
                toastManager.add(this.props.store.language.label129, {
                  appearance: "success",
                  autoDismiss: true,
                });
                break;
              case 2:
                toastManager.add(this.props.store.language.label130, {
                  appearance: "error",
                  autoDismiss: true,
                });
                break;
            }
          },
          (error) => {
            this.setState({ loading: false });
            toastManager.add(this.props.store.language.label128, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        )
        .catch((error) => {
          this.setState({ loading: false });
          toastManager.add(this.props.store.language.label128, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    } else {
      toastManager.add(this.props.store.language.label131, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  renderSkelenton() {
    return <RectSkeleton width="150px" height="20px" />;
  }

  render() {
    const { song, idx, addToList } = this.props;
    const { showModalSong, createPlaylist } = this.state;

    if (!song || !song?.isOk()) {
      return this.renderSkelenton();
    }

    const items = [
      {
        name: this.props.store.language.label46,
        onClick: () => addToList(song),
      },
      {
        name: this.props.store.language.label144,
        onClick: () => {
          this.props.store.getPermission(19, 19, () => {
            this.handleShowModalSong();
          });
        },
      },
      {
        name: this.props.store.language.label145,
        onClick: () => {
          this.props.store.getPermission(19, 19, () => {
            this.handleShowModalSong(true);
          });
        },
      },
    ];

    return (
      <div>
        <CardSong
          name={song.name}
          description={song.singer.name}
          time={this.props.store.ui.convertTime(
            Math.floor(song.duration / 1000)
          )}
          items={items}
          key={idx}
          isMobile={!this.props.store.viewMobile}
          clickOptions={() => this.setState({ open: true })}
        />
        {this.state.open && (
          <ModalSheet
            onClose={() => {
              this.setState({ open: false });
            }}
            // snapPoints={[600, 250, 100, 0]}
          >
            <div className="px-2 pb-2">
              <Text color="white" weight="bold" size="xl">
                {song.name}
              </Text>
              <Text color="special" sixe="lg">
                {song.singer.name}
              </Text>
            </div>

            <div
              style={{ width: "100%", height: 1, background: "#999999" }}
            ></div>

            <div
              className="flex px-2 py-2"
              style={{ flexDirection: "column", gap: 8 }}
            >
              <div
                className="flex items-center"
                style={{ padding: "6px 0px" }}
                onClick={() => {
                  addToList(song);
                  this.setState({ open: false });
                }}
              >
                <Text size="xl" color="white">
                  {this.props.store.language.label120}
                </Text>
              </div>

              <div
                className="flex items-center"
                style={{ padding: "6px 0px" }}
                onClick={() => {
                  this.props.store.getPermission(19, 19, () => {
                    this.handleShowModalSong();
                  });
                  this.setState({ open: false });
                }}
              >
                <Text size="xl" color="white">
                  {this.props.store.language.label144}
                </Text>
              </div>
              <div
                className="flex items-center"
                style={{ padding: "6px 0px" }}
                onClick={() => {
                  this.props.store.getPermission(19, 19, () => {
                    this.handleShowModalSong(true);
                  });
                  this.setState({ open: false });
                }}
              >
                <Text size="xl" color="white">
                  {this.props.store.language.label145}
                </Text>
              </div>
            </div>
          </ModalSheet>
        )}
        {showModalSong && (
          <SongModal
            onClose={this.handleCloseModalSong}
            onAccept={this.handleCloseModalSong}
            create={createPlaylist}
            song={song}
            handleSavePlaylist={this.handleSavePlaylist}
            handleCreatePlaylist={this.handleCreatePlaylist}
            loading={this.state.loading}
          />
        )}
      </div>
    );
  }
}

LibraryRow.propTypes = {
  song: PropTypes.object,
};

LibraryRow.defaultProps = {
  song: null,
};

export default withToastManager(withStore(LibraryRow));
