import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import withStore from "../../hocs/withStore";
import PlayerPage from "./Main/PlayerPage";
import PlaylistsListPage from "./Playlists/PlaylistsListPage";
import PlaylistSearch from "./Playlists/PlaylistSearch";
import PlaylistPlanner from "./Playlists/Planer/PlaylistsPlanner";
import AdsPlanner from "./Ads/Planner/AdsPlanner";
import AdsListPage from "./Ads/List/AdsListPage";
import AboutPage from "./About/AboutPage";
import SettingPage from "./Setting/SettingPage";
import AllPlaylistsPage from "./Playlists/AllPlaylistsPage";
import LibraryPage from "./Library/LibraryPage";

class PlayerRouter extends Component {
  render() {
    const path = this.props.match.path;

    return (
      <Switch>
        <Route
          exact
          path={`${path}/player/home`}
          component={() => <PlayerPage container={this.props.container} />}
        />
        <Route
          exact
          path={`${path}/player/playlists/list`}
          component={PlaylistsListPage}
        />
        <Route
          exact
          path={`${path}/player/playlists_plans`}
          component={PlaylistPlanner}
        />
        <Route exact path={`${path}/player/ads_plans`} component={AdsPlanner} />
        <Route exact path={`${path}/player/ads/list`} component={AdsListPage} />

        <Route exact path={`${path}/player/about`} component={AboutPage} />
        <Route exact path={`${path}/player/setting`} component={SettingPage} />

        <Route
          exact
          path={`${path}/player/playlists/all`}
          component={() => (
            <AllPlaylistsPage container={this.props.container} />
          )}
        />

        <Route
          exact
          path={`${path}/player/playlists/all`}
          component={() => (
            <AllPlaylistsPage container={this.props.container} />
          )}
        />
        <Route exact path={`${path}/player/library`} component={LibraryPage} />

        <Redirect to={`${path}/player/home`} component={PlayerPage} />
      </Switch>
    );
  }
}

export default withRouter(withStore(PlayerRouter));
