import React, { useState, useRef, useEffect } from "react";

const Progress = (props) => {
  const { value, max, onChange, update, style, className } = props;
  const [val, setVal] = useState(value);
  const sliderRef = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleSlide = (event) => {
      const sliderWidth = sliderRef.current.offsetWidth;
      const offsetX =
        event.type === "touchmove" ? event.touches[0].clientX : event.clientX;
      const position = Math.max(
        0,
        Math.min(
          sliderWidth,
          offsetX - sliderRef.current.getBoundingClientRect().left
        )
      );
      const newValue = Math.round((position / sliderWidth) * 100);

      onChange(newValue);
      setShow(true);

      setVal(newValue);
    };

    const handleMouseUp = () => {
      update();
      document.removeEventListener("mousemove", handleSlide);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    const handleTouchEnd = () => {
      update();
      document.removeEventListener("touchmove", handleSlide);
      document.removeEventListener("touchend", handleTouchEnd);
    };

    sliderRef.current.addEventListener("mousedown", () => {
      document.addEventListener("mousemove", handleSlide);
      document.addEventListener("mouseup", handleMouseUp);
    });

    sliderRef.current.addEventListener("touchstart", () => {
      document.addEventListener("touchmove", handleSlide);
      document.addEventListener("touchend", handleTouchEnd);
    });

    sliderRef.current.addEventListener("mousedown", handleSlide);

    return () => {
      document.removeEventListener("mousemove", handleSlide);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleSlide);
      document.removeEventListener("touchend", handleTouchEnd);
      sliderRef.current.removeEventListener("mousedown", handleSlide);
    };
  }, []);

  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <div
      className={`playback-progressbar ${className || "mx-2"}`}
      style={style}
    >
      <div className="progress-bar">
        <div
          className="progress-bar-background"
          ref={sliderRef}
          onMouseEnter={(e) => {
            setShow(true);
          }}
          onMouseLeave={(e) => {
            setShow(false);
          }}
          onTouchEnd={() => {
            setShow(false);
          }}
        >
          <div
            className="progress-slider"
            style={{
              width: `${val}%`,
              backgroundColor: show ? "red" : "white",
            }}
          ></div>
          <div
            className="progress-bar-button"
            style={{ left: `${val}%`, display: show ? "block" : "none" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
