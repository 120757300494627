import { createGlobalStyle } from "styled-components";
import Roboto from "../assets/fonts/Roboto-Regular.ttf";
import RobotoBold from "../assets/fonts/Roboto-Bold.ttf";
import SatoshiRegular from "../assets/fonts/Satoshi/Satoshi-Regular.otf";
import SatoshiMedium from "../assets/fonts/Satoshi/Satoshi-Medium.otf";
import SatoshiBold from "../assets/fonts/Satoshi/Satoshi-Bold.otf";
import SatoshiBlack from "../assets/fonts/Satoshi/Satoshi-Black.otf";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: Roboto;
    src: url(${Roboto}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: Roboto;
    src: url(${RobotoBold}) format("truetype");
    font-weight: bold;
    font-style: bold;
  }

  @font-face {
    font-family: Satoshi;
    font-weight: 400;
    font-style: normal;
    src: url(${SatoshiRegular});
    font-display: block;
  }
  
  @font-face {
    font-family: Satoshi;
    font-weight: 500;
    font-style: normal;
    src: url(${SatoshiMedium});
    font-display: block;
  }
  
  @font-face {
    font-family: Satoshi;
    font-weight: 700;
    font-style: normal;
    src: url(${SatoshiBold});
    font-display: block;
  }
  @font-face {
    font-family: Satoshi;
    font-weight: 900;
    font-style: normal;
    src: url(${SatoshiBlack});
    font-display: block;
  }
  
  html, body{
    overflow: hidden;  
    height: 100%;
  }

  #root, .ks--layout  {
    height: 100%;
  }

  *::selection{
    background-color: #ff3f08;
    color: #fff;
  }

  ::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: hsla(0,0%,100%,.4);
    border-radius: 20px;
    width: 10px;
  }
  
  ::-webkit-scrollbar-corner {
    color: #aaa;
    background: #000;
  }
  
  ::-webkit-scrollbar-track {
    background: tranparent;
  }

  .ks--layout .ks--layout-navbar{
    background: transparent;
  }

  .ks--mobile-layout .ks--mobile-layout-header{
    background: transparent;
  }

  .playback-progressbar{
    height: 12px;
    position: relative;
    width: 100%;
  }

  .progress-bar {
    height: 100%;
    overflow: hidden;
    touch-action: none;
    width: 100%;
  }

  .progress-bar-background{
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: gray;
    display: flex;
    top: 50%;
    transform: translateY(-50%);
  }

  .progress-bar-button{
    margin-left: -6px;
    position: absolute;
    background-color: white;
    border: 0;
    border-radius: 50%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
    // display: none;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
  }
  
  .range-slider {
    width: 300px;
    height: 10px;
    background-color: #ccc;
    position: relative;
  }
  
  .progress-slider {
    width: 0;
    height: 100%;
    position: absolute;
    background-color: red;
    top: 0;
    left: 0;
  }
  
  .slider-value {
    position: absolute;
    top: -20px;
    left: 0;
  }
  
 .ReactVirtualized__Grid,
 .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }

  .slider-wrapper::-webkit-scrollbar {
    display:none
  }


  .ks--layout-content-column{
    overflow: auto !important;
  }

  .ks--mobile-layout{
    position: fixed;
    height: auto;
    min-height: 100vh;
    inset: 0;
    display: block !important;
  }

  .ks--mobile-layout > .columns {
    height: 100%;
    overflow: auto;
    padding-bottom: calc(72px + 64px);
  }

  .ks--mobile-layout .ks--mobile-layout-menu{
    position: fixed;
    left: 0;
    right: 0;
    z-index: 20;
  }

  .ks--screen{
    animation-name: open-slide;
    animation-duration: 1s;
  }

  .ks--screen.close{
    animation-name: close-slide;
    animation-duration: 1s;
  }

  .react-toast-notifications__container{
    bottom: 100px;
    width: 100%;
  }

  @keyframes open-slide {
    0% { transform: translateY(100%); }
    100% {transform: translateY(0%); }
  }

  @keyframes close-slide {
    0% { transform: translateY(0%); }
    100% {transform: translateY(100%); }
  }

  @media (max-width: 1025px) {
    .react-toast-notifications__container{
      bottom: 120px;
      width: 100%;
    }
  }
`;

export default GlobalStyle;
