import { Model } from "ks_storybook";

class CategoryPlaylist extends Model {
  urlRoot = "/categories_playlist";

  getAlias(value) {
    return this._store.search(
      {},
      "categories_playlist",
      false,
      `/categories_playlist/${value}/`
    );
  }
}

export default CategoryPlaylist;
