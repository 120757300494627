import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";

@observer
class PlaylistsManageSong extends Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   songs: this.getSongsAll(
    //     25,
    //     null,
    //     null,
    //     this.props.store.getFreeRights(),
    //     null
    //   ),
    // };
  }

  getSongsAll = (per_page, filters, orders, free_rights, search) => {
    return this.props.store.songs.fetchAllParams(
      per_page,
      JSON.stringify(filters),
      JSON.stringify(orders),
      free_rights,
      search
    );
  };

  renderEmptyContent = () => {
    return <React.Fragment></React.Fragment>;
  };

  renderLoader = () => {
    return "cargando...";
  };

  renderHeader = () => {
    const { songs } = this.state;
    if (!songs) return this.renderEmptyContent();
    return !songs.isOk() ? this.renderLoader() : this.renderSongs();
  };

  renderSongs = () => {
    const { songs, songsStyles, usersStyles } = this.state;
    const songsMemmory = songs.toArray();

    return (
      <React.Fragment>
        <p>PlaylistsManageSong</p>

        {songsMemmory.map((song, idx) => (
          <div key={idx}>{song.name}</div>
        ))}
      </React.Fragment>
    );
  };

  render() {
    return <></>;
  }
}

export default withStore(PlaylistsManageSong);
