import React, { Component } from "react";
import PropTypes from "prop-types";
import { observable } from "mobx";
import { RectSkeleton, Text, CardActions, Button } from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { UsersSongsEdit, Songs } from "../../models";
import { ModalSheet } from "../Common";

@observer
class SongRow extends Component {
  @observable usersSongsEditNew;
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      messageError: false,
      open: false,
    };

    this.usersSongsEditNew = this.newUsersSongsEdit();
  }

  newUsersSongsEdit = () => {
    const SongsStore = this.props.store.songs;

    const attrs = {
      preference: this.props.song.preference,
    };

    return new Songs(attrs, SongsStore);
  };

  editUsersSongsEdit = () => {
    const SongsStore = this.props.store.songs;
    const attrs = {
      song_id: this.props.song.id,
    };

    // Cambiar preferencia y id de preference -> 0

    return new UsersSongsEdit(attrs, SongsStore);
  };

  handleFav = () => {
    const isFav = this.getUserFav(this.props.song);
    const songId = this.props.song.id;
    const { updateList, songKey } = this.props;

    if (!this.props.store.failsafeMode) {
      if (isFav) {
        this.props.store.kasimuAPIClient
          .putOne("/songs/unmark_song_as_like", "?song_id=" + songId)
          .then(
            (res) => {
              const { preference } = res.results;
              updateList(songKey, "preference", preference);
            },
            (error) => {
              console.log("error", error);
            }
          );
      } else {
        this.props.store.kasimuAPIClient
          .putOne("/songs/mark_song_as_like", "?song_id=" + songId)
          .then(
            (res) => {
              const { preference } = res.results;
              updateList(songKey, "preference", preference);
            },
            (error) => {
              console.log("error", error);
            }
          );
      }
    }
  };

  handleBan = () => {
    const { song, blockSong, songKey } = this.props;

    blockSong(this.getUserBan(song), song, songKey);
  };

  handleOptions = (_, value, name, event) => {
    event.stopPropagation();
    this.setState({ open: true });
  };

  renderSkelenton() {
    return <RectSkeleton width="150px" height="20px" />;
  }

  getUserFav = (song) => {
    if (song.preference == 0) return true;

    return false;
  };

  getUserBan = (song) => {
    if (song.preference == 10) return true;

    return false;
  };

  render() {
    const { song, idx, moveSong, markNext, deleteSong, blockSong, songKey } =
      this.props;
    const selectedSong = this.props.store.ui.currentSong;
    const selectedIndex = this.props.store.ui.currentSongIndex(idx);
    const selectetnextIndex = this.props.store.ui.nextSongIndex(idx);
    const selectedNextSong = this.props.store.ui.nextSong;
    const isPlaying = this.props.store.ui.isPlaying;
    // const valid = song.isOk && song.isOk();
    if (!song) {
      return this.renderSkelenton();
    }

    const items = [
      {
        name: this.props.store.language.label69,
        onClick: (_, value, name, event) => {
          event.stopPropagation();
          this.props.store.getPermission(17, 17, () => moveSong());
        },
      },
      {
        name: this.props.store.language.label70,
        onClick: (_, value, name, event) => {
          event.stopPropagation();
          markNext();
        },
      },
      {
        name: this.props.store.language.label71,
        onClick: (_, value, name, event) => {
          event.stopPropagation();
          this.props.store.getPermission(22, 22, () => deleteSong());
        },
      },
    ];

    return (
      <>
        <CardActions
          id={idx + 1}
          name={song.name}
          description={song.singer.name}
          // selected={selectedSong.id === song.id}
          selected={selectedIndex === idx}
          time={this.props.store.ui.convertTime(
            Math.floor(song.duration / 1000)
          )}
          userFav={this.getUserFav(song)}
          userBan={this.getUserBan(song)}
          favClick={(_, value, name, event) => {
            event.stopPropagation();
            this.props.store.getPermission(20, 20, () => this.handleFav());
          }}
          optionsClick={this.handleOptions}
          banClick={(_, value, name, event) => {
            event.stopPropagation();
            this.props.store.getPermission(20, 20, () => this.handleBan());
          }}
          isPlaying={isPlaying}
          selectedNext={selectedNextSong && selectetnextIndex === idx}
          items={items}
          disabled={selectedIndex === idx}
          isMobile={!this.props.store.viewMobile}
        />
        {this.state.open && (
          <ModalSheet
            onClose={(e) => {
              e.stopPropagation();
              this.setState({ open: false });
            }}
          >
            <div className="px-2 pb-2">
              <Text color="white" weight="bold" size="xl" multiline>
                {song.name}
              </Text>
              <Text color="special" sixe="lg">
                {song.singer.name}
              </Text>
            </div>

            <div
              style={{ width: "100%", height: 1, background: "#999999" }}
            ></div>

            <div
              className="flex px-2 py-2"
              style={{ flexDirection: "column", gap: 8 }}
            >
              <div
                className="flex items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.store.getPermission(22, 22, () => {
                    this.handleFav();
                  });
                  this.setState({ open: false });
                }}
              >
                <Button
                  iconKasimu={this.getUserFav(song) ? "heart-solid" : "heart"}
                  kind="link"
                  color={this.getUserFav(song) ? "primary" : "basic"}
                  size="lg"
                  space
                />
                <Text size="xl" color="white" className="ml-2">
                  {this.props.store.language.label115}
                </Text>
              </div>
              <div
                className="flex items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.store.getPermission(21, 21, () => {
                    blockSong(this.getUserBan(song), song, songKey);
                  });
                  this.setState({ open: false });
                }}
              >
                <Button
                  iconKasimu="ban"
                  kind="link"
                  color={this.getUserBan(song) ? "primary" : "basic"}
                  size="lg"
                  space
                />
                <Text size="xl" color="white" className="ml-2">
                  {this.props.store.language.label116}
                </Text>
              </div>
              <div className="flex items-center" onClick={moveSong}>
                <Button
                  iconKasimu="arrows"
                  kind="link"
                  color="basic"
                  size="lg"
                  space
                />
                <Text size="xl" color="white" className="ml-2">
                  {this.props.store.language.label117}
                </Text>
              </div>
              <div className="flex items-center" onClick={markNext}>
                <Button
                  // icon="step-forward"
                  iconKasimu="next"
                  kind="link"
                  color="basic"
                  size="lg"
                  space
                />
                <Text size="xl" color="white" className="ml-2">
                  {this.props.store.language.label118}
                </Text>
              </div>
              <div
                className="flex items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  this.props.store.getPermission(22, 2, () => {
                    deleteSong();
                  });
                  this.setState({ open: false });
                }}
              >
                <Button
                  iconKasimu="times"
                  kind="link"
                  color="basic"
                  size="lg"
                  space
                />
                <Text size="xl" color="white" className="ml-2">
                  {this.props.store.language.label119}
                </Text>
              </div>
            </div>
          </ModalSheet>
        )}
      </>
    );
  }
}

SongRow.propTypes = {
  song: PropTypes.object,
};

SongRow.defaultProps = {
  song: null,
};

export default withStore(SongRow);
