import { Store } from "ks_storybook";
import { UserFavoritePlaylists } from "../models";

class UserFavoritePlaylistsStore extends Store {
  model = UserFavoritePlaylists;

  fetchAllPlaylistsFavorites(lang) {
    return this.search(
      { per_page: 1000, lang },
      "get_all_playlists_favorites",
      true,
      "/users_favorite_playlists/get_all_playlists_favorites"
    );
  }
}

export default UserFavoritePlaylistsStore;
