import { observable, action, computed, toJS } from "mobx";
import { Store } from "ks_storybook";
import { UISettings, Playlist } from "../models";
import { vdjToMp3Src, bufferToSrc } from "../libs/songslib";
import { v4 as uuidv4 } from "uuid";

import moment from "moment";
export default class UIStore extends Store {
  model = UISettings;
  urlRoot = "UI";
  _songDownloadLimit = 10;
  _behindsongs = 2;
  _downloadingPrevSongs = false;
  _downloadingNextSongs = false;
  _sessionOn = false;
  _hostad = process.env.REACT_APP_KASIMU_API_URL + "/ad_download?url=";
  _host = "https://vdj.megaplayer.me/Multimedia/VDJ/";
  _hostCopy = "";
  _hosts = [
    "https://vdj.megaplayer.me/Multimedia/VDJ/",
    "https://vdj.megaplayer.me/Multimedia/VDJ/",
  ];
  _iconMyplaylists = this.getBucketUrl() + "images/cp-m.png";
  _iconMyplaylists2 = this.getBucketUrl() + "images/cp-a.png";
  sidebarExpand = false;
  open = false;
  @observable audioDriver = new Audio();
  @observable audioAdsDriver = new Audio();
  @observable lists = {
    songs: [],
    hibernationPlans: null,
    playlistPlans: null,
    adsGuidelines: null,
    adBatches: [],
    currentPattern: null,
    currentPatternExact: null,
    nextTimeAd: null,
    currentAdBatches: [],
    adNextCurrentTimes: [],
  };
  @observable library = {
    songs: null,
    search: "",
    singer: false,
    favorites: false,
  };
  @observable showMainSide = false;
  @observable showFloatingPlayer = false;
  @observable currentSong;
  @observable nextSong;
  @observable currentCategoryPlaylist = null;
  @observable currentPlaylist;
  @observable currentPlaylistType;
  @observable currentOwner = 1;
  @observable currentDayP;
  @observable currentDayH;
  @observable currentDayA;
  @observable currentCategoriesPlaylistId = 0;
  @observable isPlaying = false;
  @observable isPlayingAds = false;
  @observable preferences = null;
  @observable currentDuration = null;
  @observable durationSecs = 0;
  @observable durationAdSecs = 0;
  @observable currentProgressTime = null;
  @observable currentProgress = 0;
  @observable signalLaunchPlaylistPlan = false;
  @observable adIndex = 0;
  @observable hideMiniPlayer = true;
  @observable downloadedSongsOk = false;
  @observable patternExact = false;
  @observable unlock = false;
  @observable manualUnlock = false;
  @observable currentFinishTime = 0;
  @observable volume = 1;
  @observable isMuted = false;
  @observable currentAd;

  @observable categoryId = 0;
  @observable reproducingView = false;

  // armando manualmente click

  @observable banderClick = false;
  @observable ownerClick = 1;

  @observable cacheMusicName = "music-v0";

  // hibernation
  @observable isHibernating = false;
  @observable lastPlansHibernationId = -1;
  @observable intervalsTxt = "";
  @observable syncPlaylistPlanner = false;

  @observable selectedCategory = null;
  @observable prevSelectedCategory = null;
  @observable selectedCategoryPlaylist = null;
  @observable showCategory = false;
  @observable allPlaylists = null;
  @observable autoSave = null;
  @observable playAutoError = false;
  @observable flagReport = false;
  @observable countReport = 0;

  // Navigations
  @observable histories = [];
  @observable historyPosition = -1;
  @observable isCategory = false;
  @observable isReproducing = false;
  @observable isPlayer = false;

  constructor(adapter, appStore) {
    super(adapter, appStore);

    var myHeaders = new Headers();
    myHeaders.append("pragma", "no-cache");
    myHeaders.append("cache-control", "no-store");

    //this.audioDriver.autoplay= true;
    this.audioDriver.onended = () => this.autoNextSong();
    this.audioDriver.onplay = () => {
      this.isPlaying = true;
    };
    this.audioDriver.onpause = () => {
      this.isPlaying = false;
    };

    //this.audioAdsDriver.autoplay = true;
    this.audioAdsDriver.onended = () => this.autoNextAd();
    this.audioAdsDriver.onplay = () => {
      this.isPlayingAds = true;
      this.appStore.isPlaying = true;
    };
    this.audioAdsDriver.onpause = () => {
      this.isPlayingAds = false;
      this.appStore.isPlaying = false;
    };

    this.autoSave = this.getAutoSave();

    this.downloadedSongs = [];

    this.downloadedAds = [];

    this.interval = 1000;
    setInterval(this.updateProgress, this.interval);
    //setInterval(this.loadBalancer, this.interval * 10);
    //setInterval(()=>console.log('this.downloadedSongs', this.downloadedSongs), this.interval);

    this.downloadNextSongs = this.downloadNextSongs.bind(this);

    document.body.onkeydown = (event) => {
      let keyCode;
      if (event.key !== undefined) {
        keyCode = event.key;
      } else if (event.keyIdentifier !== undefined) {
        keyCode = event.keyIdentifier;
      } else if (event.keyCode !== undefined) {
        keyCode = event.keyCode;
      }
      switch (keyCode) {
        case "ArrowDown":
          this.appStore.getPermission(14, 14, () => {
            this.next();
          });
          break;
        case 40: // arrow down next
          this.appStore.getPermission(14, 14, () => {
            this.next();
          });
          break;
      }
    };
  }

  @computed
  get playlistsPlannerIsOn() {
    const user = this.appStore.loggedInUser;
    return user && user.configs && user.configs.playlist_planner_on === 1;
  }

  @computed
  get adsPlannerIsOn() {
    const user = this.appStore.loggedInUser;
    return user && user.configs && user.configs.ads_planner_on === 1;
  }

  @computed
  get lockIsOn() {
    const preference = this.appStore.loggedInUser.configs;
    return preference.block_interface === 1 || preference.lock_start === 1;
  }

  getBucketUrl() {
    return process.env.REACT_APP_KASIMU_API_URL == "https://api.megaplayer.me"
      ? "https://sources.megaplayer.me/"
      : "https://sources-test.megaplayer.me/";
  }

  setLock = () => {
    if (!this.manualUnlock) {
      if (this.lockIsOn) this.unlock = true;
      else this.unlock = false;
    } else {
      this.unlock = false;
    }
  };

  updateProgress = () => {
    this.updateSongSilder();
    this.showDuration();
  };

  changePlaylistPlan = () => {
    if (!this.playlistsPlannerIsOn) {
      this.changeManualSelection();
      return;
    }
    this.lists.playlistPlans.andThen((res) => {
      let plan = this.currentTimePlans(res.toArray());

      if (plan.length === 1) {
        this.currentPlaylistType = plan[0].playlist_type;
        if (parseInt(plan[0].owner) == 1) {
          this.currentOwner = parseInt(plan[0].owner);
          this.currentCategoriesPlaylistId = plan[0].categories_playlists_id;
          const categoriesPlaylists = this.getCategoriesPlaylists(
            this.currentCategoriesPlaylistId
          );
          categoriesPlaylists.andThen((resx) => {
            this.categoryId = parseInt(resx.category);
            this.changePlaylist(
              resx.playlist,
              resx,
              this.currentCategoriesPlaylistId,
              this.currentPlaylistType,
              plan[0].owner
            );
          });
        } else {
          this.currentCategoriesPlaylistId = plan[0].categories_playlists_id;
          this.currentPlaylistType = plan[0].playlist_type;
          this.currentOwner = parseInt(plan[0].owner);
          this.categoryId = 5000;
          this.changePlaylist(
            this.currentCategoriesPlaylistId,
            0,
            this.currentCategoriesPlaylistId,
            this.currentPlaylistType,
            plan[0].owner
          );
        }
        return;
      }
      this.changeManualSelection();
    });
  };

  updatePreference = (categoryPlaylistId) => {
    const preference = this.appStore.loggedInUser.configs;
    preference.last_categories_playlist_id = categoryPlaylistId;
    preference.last_playlist_type = 0;
    preference.last_playlist_owner = 1;
    preference.save();
    this.appStore.newPlaylistLog(categoryPlaylistId, 0, 1).save();
  };

  getPlaylistsTypeUser(p_static, p_random) {
    if (p_static == 1) {
      if (p_random == 1) return 7;
      else return 6;
    } else {
      if (p_random == 1) return 5;
      else return 4;
    }
  }

  updatePreferenceUser = (playlist_id, p_user_id, p_random, p_static) => {
    const preference = this.appStore.loggedInUser.configs;
    let owner = p_user_id == this.appStore.loggedInUser.id ? 2 : 3;
    let playlitType = this.getPlaylistsTypeUser(p_static, p_random);
    preference.last_categories_playlist_id = playlist_id;
    preference.last_playlist_type = playlitType;
    preference.last_playlist_owner = owner;
    this.appStore.newPlaylistLog(playlist_id, playlitType, owner).save();
  };

  shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;

    // Mientras queden elementos por barajar...
    while (currentIndex != 0) {
      // Elija un elemento restante...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // Y cámbielo por el elemento actual.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  /*
    playlistType
    0 automatica sin random (kasimu)
    1 automatica con random (kasimu)
    2 manual sin randon (kasimu)
    3 manual con randon (kasimu)
    4 automatica sin randon (usuario)
    5 automatica con random (usuario)
    6 manual sin randon (usuario)
    7 manual con randon (usuario)
   */

  changePlaylist = (
    id,
    category,
    categoriesPlaylistId,
    playlistType,
    owner
  ) => {
    //this.loadBalancer();
    const preference = this.appStore.loggedInUser.configs;
    this.currentPlaylist = this.getPlaylist(id, playlistType, owner);

    if (owner == 1) {
      this.getCategoriesPlaylistsLang(categoriesPlaylistId);
      //this.currentCategoryPlaylist = category;
      preference.last_playlist_type = 0;
      preference.last_categories_playlist_id = categoriesPlaylistId;
      preference.last_playlist_owner = 1;
      preference.save();
      this.appStore.newPlaylistLog(categoriesPlaylistId, 0, 1).save();
    } else {
      this.currentCategoryPlaylist = this.currentPlaylist;
    }
    const that = this;

    that.currentPlaylist.andThen((resx, err) => {
      if (that.currentPlaylist.static === 1) {
        that.getSongs(that.currentPlaylist, playlistType, owner, preference);
      } else {
        that.currentPlaylist.build().andThen((res) => {
          for (let song of res.toArray()) {
            song.key = uuidv4();
          }

          that.lists.songs = res.toArray();
          if (
            playlistType == 1 ||
            playlistType == 3 ||
            playlistType == 5 ||
            playlistType == 7
          )
            that.lists.songs = this.shuffle(res.toArray());
          else that.lists.songs = res.toArray();
          that.startPlaying();
          if (owner > 1) {
            preference.last_playlist_type = playlistType;
            preference.last_categories_playlist_id = resx.playlist_id;
            preference.last_playlist_owner = owner;
            that.appStore
              .newPlaylistLog(resx.playlist_id, playlistType, owner)
              .save();
          }
          //that.hideMiniPlayer = true;
        });
      }
    });
  };

  getSongs = (playlist, playlistType, owner, preference) => {
    const that = this;
    this.appStore.kasimuAPIClient
      .search("/users_playlist/build_list", {
        playlist_id: playlist.playlist_id,
        user_id: playlist.user_id,
      })
      .then((res) => {
        const data = res.results;
        for (let song of data) {
          song.key = uuidv4();
        }

        that.lists.songs = data;
        if (
          playlistType == 1 ||
          playlistType == 3 ||
          playlistType == 5 ||
          playlistType == 7
        )
          that.lists.songs = this.shuffle(data);
        else that.lists.songs = data;
        that.startPlaying();
        if (owner > 1) {
          preference.last_playlist_type = playlistType;
          preference.last_categories_playlist_id = playlist.playlist_id;
          preference.last_playlist_owner = owner;
          that.appStore
            .newPlaylistLog(playlist.playlist_id, playlistType, owner)
            .save();
        }
      });
  };

  getPlaylist = (id, playlistType, owner) => {
    /*if (playlistType < 4){
      return this.appStore.playlists.get(id);
    } else {
      return this.appStore.userPlaylist.get(id);
    } */
    if (owner == 1) {
      return this.appStore.playlists.get(id);
    } else {
      return this.appStore.userPlaylist.get(id);
    }
  };

  getCategoriesPlaylists = (id) => {
    return this.appStore.categoriesPlaylists.get(id);
  };

  getCategoriesPlaylistsLang = (id) => {
    let _categoriesPlaylists =
      this.appStore.categoriesPlaylists.fetchSearchIdLast(
        id,
        this.appStore.lang
      );
    _categoriesPlaylists.andThen((response) => {
      if (response.length > 0) {
        let res = response.toArray();
        this.currentCategoryPlaylist = res[0];
      }
    });
  };

  getCurrentDay = () => {
    const weekday = new Date().getDay();
    let day = weekday == 0 ? "7" : weekday;
    return day;
  };

  currentTimePlans = (plans) => {
    return plans.filter((p) =>
      moment().isBetween(
        moment(p.start_hour + ":59", "HH:mm:ss"),
        moment(p.finish_hour + ":59", "HH:mm:ss")
      )
    );
  };

  get settings() {
    if (this.appStore.loggedInUser) {
      return this.get(this.appStore.loggedInUser.id);
    }
    return new UISettings({}, this);
  }

  default_playlists = () => {
    let categories_playlist_id = this.appStore.getKasimuCategoriePlaylist();
    this.banderClick = true;
    this.ownerClick = 1;
    const x = this.getCategoriesPlaylists(categories_playlist_id);
    x.andThen((res) => {
      this.categoryId = parseInt(res.category);
      this.changePlaylist(res.playlist, res, categories_playlist_id, 0, 1);
    });
  };

  startPlaying = () => {
    var that = this;
    that.freeMemory();
    if (that.lists.songs.length == 0) {
      that.default_playlists();
      return;
    }
    that.currentSong = that.lists.songs[0];
    that.fetchSong(that.currentSong).then((buffer) => {
      if (buffer) {
        that.downloadedSongs = [
          ...that.downloadedSongs,
          {
            song_id: that.currentSong.id,
            buffer: vdjToMp3Src(buffer),
          },
        ];
        that.loadCurrentSong();
        if (!that.isPlayingAds) {
          that.play();
        }

        //that.setInitialPosition(that.currentSong.initial_time);
        //that.setFinishPosition(that.currentSong.finish_time);
        // if (!this.appStore.failsafeMode) {
        //   that.appStore.newSongLog(that.currentSong.id).save();
        // }
        that.downloadNextSongs(that.currentSongIndex() + 1);
      } else {
        this.audioDriver.removeAttribute("src");
        that.downloadNextSongs(that.currentSongIndex() + 1, true);
      }
    });
  };

  timeout = (ms, promise) => {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        clearTimeout(timeoutId);
        resolve({ statusCode: 504, status: "504" });
      }, ms);
      promise.then(
        (res) => {
          clearTimeout(timeoutId);
          resolve(res);
        },
        (err) => {
          clearTimeout(timeoutId);
          reject(err);
        }
      );
    });
  };

  loadBalancer = () => {
    this._hostCopy = this._host;
    var final = 0;
    let arr = [];
    let requests = this._hosts.map((host, index) => {
      var started = new Date().getTime();
      return this.timeout(
        1000,
        fetch(host.split("/").splice(0, 3).join("/"), this.myHeaders)
      )
        .then((res) => {
          if (res.status === 200) {
            var ended = new Date().getTime();

            var milliseconds = ended - started;
            arr.push(milliseconds);
            console.log("res", res.url, " time: ", `${milliseconds}ms`);
            return res;
          } else {
            console.log("HTTP error, status = " + res.status);
          }
          return res;
        })
        .catch((err) => {
          return console.log("HTTP error, status = " + err);
        });
    });

    Promise.all(requests).then((res) => {
      if (arr.length != 0) {
        if (arr[0] < arr[1]) this._host = this._hosts[0];
        else this._host = this._hosts[1];

        if (this._hostCopy != this._host) {
          this._hosts = this._hosts.reverse();
        }
      }
    });
  };

  fetchSong = (song, host) => {
    var that = this;

    let hostSong = this._host;
    if (host) hostSong = host;

    return this.timeout(30000, fetch(hostSong + song.song_path, this.myHeaders))
      .then((response) => {
        if (response.status === 200) {
          // caches.open(this.cacheMusicName).then((cache) => {
          //   cache.put(song.song_path, response.clone());
          // });
          return response.clone().arrayBuffer();
        } else {
          let hostIndex = this._hosts.findIndex((h) => h == hostSong);
          let searchHost = this._hosts[hostIndex + 1];

          if (!searchHost) {
            searchHost = this._hosts[0];
          }

          if (searchHost == this._host) {
            console.log("HTTP error, status = " + response.status);
            return null;
          }

          return that.fetchSong(song, searchHost);
        }
      })
      .catch((error) => {
        let hostIndex = this._hosts.findIndex((h) => h == hostSong);
        let searchHost = this._hosts[hostIndex + 1];

        if (!searchHost) {
          searchHost = this._hosts[0];
        }

        if (searchHost == this._host) {
          console.error("There was an error!", error);
          return null;
        }

        return that.fetchSong(song, searchHost);
      });
  };

  fetchAd = (adPath) =>
    this.timeout(30000, fetch(this._hostad + this.appStore.replaceBucketUrl(adPath))).then(async (response) => {
      if (response.status === 200) {
        let str = await response.clone().text();

        if (str.includes("AccessDenied")) {
          return null;
        }

        return response.clone().arrayBuffer();
      } else {
        console.log("HTTP error, status = " + response.status);
        return null;
      }
    });

  play = () => {
    if (this.isHibernating) return;
    if (!this.isPlayingAds) {
      let promise = this.audioDriver.play();
      let playButton = document.getElementById("play-button");

      const that = this;
      promise
        .then((res) => {
          if (playButton) {
            that.playAutoError = false;
            playButton.classList.remove("ks--playauto-error");
          }
        })
        .catch((err) => {
          if (playButton) {
            that.playAutoError = true;
            playButton.classList.add("ks--playauto-error");
          }
        });
    }
  };

  loadCurrentSong = () => {
    if (!this.lists.songs[this.currentSongIndex()]) return;

    let downloadedSong = this.downloadedSongs.find(
      (s) => s.song_id === this.currentSong.id
    );

    if (!downloadedSong) return;

    // 200mb - 300mb
    this.audioDriver.setAttribute("src", downloadedSong.buffer);
    const preference = this.appStore.loggedInUser.configs;
    let volume = preference?.volume >= 0 ? preference.volume / 100 : 1;
    if (this.getMuted(preference)) {
      volume = 0;
      this.isMuted = true;
    } else {
      this.isMuted = false;
    }
    this.volume = volume;
    this.audioDriver.volume = volume;
    this.audioDriver.load();
  };

  getMuted = (preference) => {
    if (preference.show_favorites === 1) return true;

    return false;
  };

  loadAudioPlayer = () => {
    this.preferences.andThen((response) => {
      if (response.length > 0) {
        let res = response.toArray();
        const preference = res[0];

        let volume = preference?.volume >= 0 ? preference.volume / 100 : 1;
        if (this.getMuted(preference)) {
          volume = 0;
          this.isMuted = true;
        } else {
          this.isMuted = false;
        }

        this.volume = volume;
        this.audioDriver.volume = volume;
      }
    });
  };

  loadLanguage = () => {
    this.preferences.andThen((response) => {
      if (response.length > 0) {
        let res = response.toArray();
        const preference = res[0];
        const language = this.appStore.getNumLang(preference.language_app);

        if (this.appStore.lang !== language) {
          this.appStore.lang = this.appStore.getNumLang(
            preference.language_app
          );
          this.appStore.getLanguage(this.appStore.lang);
        }
      }
    });
  };

  pause = () => {
    this.audioDriver.pause();
  };

  updateSongSilder = () => {
    var that = this;
    that.currentProgress = Math.round(that.audioDriver.currentTime);

    if (that.currentProgress > 0 && !that.appStore.failsafeMode) {
      that.countReport = that.countReport + 1;
      if (!that.flagReport && that.countReport >= 10) {
        that.flagReport = true;
        that.appStore.newSongLog(that.currentSong.id).save();
      }
    }

    if (that.currentProgress > 0 && that.currentFinishTime != 0) {
      var finishTime = Math.round(that.currentFinishTime);

      if (that.currentProgress >= finishTime) {
        that.autoNextSong();
      }
    }
    that.currentProgressTime = that.convertTime(that.currentProgress);
  };

  updateSong = (value) => {
    var that = this;
    if (value >= 0) {
      that.audioDriver.currentTime = Math.round(value);
    }
  };

  showDuration = () => {
    this.durationSecs = Math.floor(this.audioDriver.duration);
    this.currentDuration = this.convertTime(this.durationSecs);
  };

  showDurationAds = () => {
    var that = this;
    that.audioAdsDriver.addEventListener("loadedmetadata", (e) => {
      that.durationAdSecs = Math.floor(e.target.duration);
    });
  };

  setInitialPosition = (ms) => {
    // ojo
    var sec = Math.floor((ms / 1000) % 60);
    this.audioDriver.currentTime = sec;
  };

  setFinishPosition = (ms) => {
    var sec = Math.floor((ms / 1000) % 60);
    this.currentFinishTime = sec;
  };

  convertTime = (secs) => {
    var min = Math.floor(secs / 60);
    var sec = secs % 60;
    min = min < 10 ? "0" + min : min;
    sec = sec < 10 ? "0" + sec : sec;
    return min + ":" + sec;
  };

  autoNextSong = () => {
    var that = this;
    if (that.audioDriver.ended && that.audioDriver.duration !== 0) {
      that.next();
    } else {
      if (!that.isPlayingAds) {
        that.play();
      }
    }
  };

  next() {
    var that = this;
    var currentSongIndex = that.currentSongIndex();

    if (!that.isPlayingAds && this.downloadedSongs.length > 0) {
      if (this.getAdsPlannerOn()) {
        that.getNextAdPathOfBatches(currentSongIndex);
      }

      if (!navigator.onLine) {
        // no internet
        that.currentSong = that.nextSongOffline();
        that.loadCurrentSong();
        //that.setInitialPosition(that.currentSong.initial_time);
        //that.setFinishPosition(that.currentSong.finish_time);
        if (!that.isPlayingAds) {
          that.play();
        }
      } else {
        // internet

        const nextSong =
          that.nextSong || that.lists.songs[currentSongIndex + 1];
        that.flagReport = false;
        that.countReport = 0;

        if (that.downloadedSongs.length >= that._songDownloadLimit) {
          URL.revokeObjectURL(that.downloadedSongs[0].buffer);
          that.downloadedSongs.shift();
        }

        if (
          nextSong &&
          currentSongIndex >= 0 &&
          currentSongIndex <= that.lists.songs.length
        ) {
          if (that.downloadedSongs.find((s) => s.song_id === nextSong.id)) {
            that.currentSong = nextSong;
            that.loadCurrentSong();

            that.nextSong = null;

            //that.setInitialPosition(that.currentSong.initial_time);
            //that.setFinishPosition(that.currentSong.finish_time);
            if (!that.isPlayingAds) {
              that.play();
            }

            that.downloadNextSongs(currentSongIndex + 1); // download next songs

            // if (!this.appStore.failsafeMode) {
            //   that.appStore.newSongLog(that.currentSong.id).save();
            // }
          } else {
            // still not downloaded

            if (!that._downloadingNextSongs) {
              that
                .fetchSong(nextSong)
                .then((buffer) => {
                  if (buffer) {
                    if (that.nextSong) {
                      const nextSongIndex = that.nextSongIndex();
                      that.downloadedSongs.push({
                        song_id: that.lists.songs[nextSongIndex].id,
                        buffer: vdjToMp3Src(buffer),
                      });
                      that.currentSong = that.lists.songs[nextSongIndex];
                    } else {
                      const currentSongIndex = that.currentSongIndex();
                      that.downloadedSongs.push({
                        song_id: that.lists.songs[currentSongIndex + 1].id,
                        buffer: vdjToMp3Src(buffer),
                      });
                      that.currentSong = that.lists.songs[currentSongIndex + 1];
                    }
                    that.nextSong = null;

                    that.loadCurrentSong();
                    //that.setInitialPosition(that.currentSong.initial_time);
                    //that.setFinishPosition(that.currentSong.finish_time);
                    if (!that.isPlayingAds) {
                      that.play();
                    }
                    // if (!this.appStore.failsafeMode) {
                    //   that.appStore.newSongLog(that.currentSong.id).save();
                    // }
                  } else {
                    console.log("error descarga");
                  }
                })
                .then(that.downloadNextSongs(currentSongIndex + 1));
            }
          }
        } else {
          // rebuild
          //that.changePlaylist(that.currentCategoryPlaylist.playlist);
          that.freeMemory();
          that.startPlaying();
        }
        //that.cleanSongsMemory();
      }
    }
  }

  nextSongOffline = () => {
    var that = this;
    var currentSongIndex = that.currentSongIndex();
    const nextSong = that.lists.songs[currentSongIndex + 1];

    // caches.open(that.cacheMusicName).then((cache) => {
    //   cache.keys().then((keys) => {
    //     console.log("keys", keys);
    //   });
    // });

    if (!that.lists.songs) {
      return null;
    }
    if (that.downloadedSongs.length === 0) {
      return null;
    }
    if (!nextSong) {
      return null;
    } else {
      if (that.downloadedSongs.find((s) => s.song_id === nextSong.id)) {
        return nextSong;
      } else {
        let downladedSong = that.downloadedSongs.find(
          (s) => s.song_id == nextSong.id
        );

        if (!downladedSong) {
          let currentDownloadedSong = that.downloadedSongs.findIndex(
            (s) => s.song_id == that.lists.songs[currentSongIndex].id
          );

          let current = that.downloadedSongs[currentDownloadedSong + 1];

          if (
            !current ||
            current.id == that.lists.songs[currentSongIndex + 1].id
          ) {
            current = that.downloadedSongs[0];

            return that.lists.songs.find((s) => s.id == current.song_id);
          }

          return that.lists.songs.find((s) => s.id == current.song_id);
        } else {
          return nextSong;
        }
        // if (that.downloadedSongs.find((s) => s.song_id === nextSong.id)) {
        //   return nextSong;
        // }
      }
    }
  };

  prev() {
    var that = this;
    // Get the index of the current song
    var currentSongIndex = that.currentSongIndex();

    // If there aren't ads and there are downloaded songs
    if (!that.isPlayingAds && this.downloadedSongs.length > 0) {
      // There isn't Internet

      if (!navigator.onLine) {
        that.currentSong = that.prevSongOffline();
        that.loadCurrentSong();

        if (!that.isPlayingAds) {
          that.play();
        }
      } else {
        // Get the prev song
        const prevSong = that.lists.songs[currentSongIndex - 1];
        that.flagReport = false;
        that.countReport = 0;

        if (that.downloadedSongs.length >= that._songDownloadLimit) {
          URL.revokeObjectURL(
            that.downloadedSongs[that.downloadedSongs.length - 1].buffer
          );

          that.downloadedSongs.pop();
        }

        // If prevSong exists, it is greater equal than 0 and currentSongIndex is less equal than lenght of the list songs
        if (
          prevSong &&
          currentSongIndex >= 0 &&
          currentSongIndex <= that.lists.songs.length
        ) {
          // If exits the prevSong id in the dowloadedSongs
          if (that.downloadedSongs.find((s) => s.song_id === prevSong.id)) {
            // The current song is equal to the prevSong
            that.currentSong = prevSong;
            // You load the current song
            that.loadCurrentSong();

            that.downloadPrevSongs(currentSongIndex - 1);

            // If playing ads isn't exits you can play
            if (!that.isPlayingAds) {
              that.play();
            }
          } else {
            // You load the current song
            // that.loadCurrentSong();

            // If playing ads isn't exits you can play
            if (!that.isPlayingAds) {
              that.play();
            }

            if (!that._downloadingPrevSongs) {
              that
                .fetchSong(prevSong)
                .then((buffer) => {
                  if (buffer) {
                    const currentSongIndex = that.currentSongIndex();
                    that.downloadedSongs.push({
                      song_id: that.lists.songs[currentSongIndex - 1].id,
                      buffer: vdjToMp3Src(buffer),
                    });
                    that.currentSong = that.lists.songs[currentSongIndex - 1];

                    that.loadCurrentSong();
                    //that.setInitialPosition(that.currentSong.initial_time);
                    //that.setFinishPosition(that.currentSong.finish_time);
                    if (!that.isPlayingAds) {
                      that.play();
                    }
                    // that.appStore.newSongLog(that.currentSong.id).save();
                  } else {
                    console.log("error descarga");
                  }
                })
                .then(that.downloadNextSongs(currentSongIndex - 1));
            }
          }
        } else {
          // that.currentSong = that.prevSongOffline();

          // that.loadCurrentSong();

          const prevSong = that.lists.songs[that.lists.songs.length - 1];

          if (!that.isPlayingAds) {
            that.play();
          }

          if (!that._downloadingPrevSongs) {
            that
              .fetchSong(prevSong)
              .then((buffer) => {
                if (buffer) {
                  // const currentSongIndex = that.currentSongIndex();

                  that.downloadedSongs.push({
                    song_id: that.lists.songs[that.lists.songs.length - 1].id,
                    buffer: vdjToMp3Src(buffer),
                  });
                  that.currentSong =
                    that.lists.songs[that.lists.songs.length - 1];
                  that.loadCurrentSong();
                  //that.setInitialPosition(that.currentSong.initial_time);
                  //that.setFinishPosition(that.currentSong.finish_time);
                  if (!that.isPlayingAds) {
                    that.play();
                  }
                  // that.appStore.newSongLog(that.currentSong.id).save();
                } else {
                  console.log("error descarga");
                }
              })
              .then(that.downloadNextSongs(that.lists.songs.length - 1));
          }
        }
      }
    }
  }

  prevSongOffline = () => {
    var that = this;
    var currentSongIndex = that.currentSongIndex();

    const prevSong = that.lists.songs[currentSongIndex - 1];

    if (!that.lists.songs) {
      return null;
    }
    if (that.downloadedSongs.length === 0) {
      return null;
    }
    if (!prevSong) {
      let currentDownloadedSong = that.downloadedSongs.findIndex(
        (s) => s.song_id == that.lists.songs[currentSongIndex].id
      );

      let current = that.downloadedSongs[currentDownloadedSong - 1];

      if (!current || current.id == that.lists.songs[currentSongIndex - 1].id) {
        current = that.downloadedSongs[that.downloadedSongs.length - 1];

        return that.lists.songs.find((s) => s.id == current.song_id);
      }

      return that.lists.songs.find((s) => s.id == current.song_id);
    } else {
      if (that.downloadedSongs.find((s) => s.song_id === prevSong.id)) {
        return prevSong;
      } else {
        let downladedSong = that.downloadedSongs.find(
          (s) => s.song_id == prevSong.id
        );

        if (!downladedSong) {
          let currentDownloadedSong = that.downloadedSongs.findIndex(
            (s) => s.song_id == that.lists.songs[currentSongIndex].id
          );

          let current = that.downloadedSongs[currentDownloadedSong - 1];
          if (
            !current ||
            current.id == that.lists.songs[currentSongIndex - 1].id
          ) {
            current = that.downloadedSongs[that.downloadedSongs.length - 1];

            return that.lists.songs.find((s) => s.id == current.song_id);
          }

          return that.lists.songs.find((s) => s.id == current.song_id);
        } else {
          return prevSong;
        }
      }
    }
  };

  getDownloadedSongs = () => {
    var that = this;
    return that.downloadedSongs;
  };

  downloadNextSongs(songIndex, bool = false, force = false) {
    var that = this;

    if (!songIndex) {
      return null;
    }

    that._downloadingNextSongs = true;

    if (that.downloadedSongs.length <= 0 && !bool) {
      return null;
    }

    // if (!navigator.onLine) {
    //   that._downloadingNextSongs = false;
    //   return null;
    // }

    if (!that.lists.songs[songIndex]) {
      that._downloadingNextSongs = false;
      return null;
    }

    if (that.downloadedSongs.length >= that._songDownloadLimit && !force) {
      that._downloadingNextSongs = false;
      return null;
    }

    if (
      !that.downloadedSongs.find(
        (song) => song.song_id === that.lists.songs[songIndex].id
      )
    ) {
      if (!that._sessionOn) {
        that.lists = {
          songs: [],
          playlistPlans: null,
          adsGuidelines: null,
          adBatches: [],
          currentPattern: null,
          currentPatternExact: null,
          nextTimeAd: null,
          currentAdBatches: [],
          adNextCurrentTimes: [],
        };
        return null;
      }

      return (
        that.lists.songs[songIndex] &&
        that
          .fetchSong(that.lists.songs[songIndex])
          .then((buffer) => {
            if (that.downloadedSongs.length <= 0 && !bool) {
              return null;
            }

            if (buffer) {
              let search = that.downloadedSongs.some(
                (e) => e.song_id == that.lists.songs[songIndex].id
              );

              if (!search) {
                that.downloadedSongs = [
                  ...that.downloadedSongs,
                  {
                    song_id:
                      that.lists.songs[songIndex] &&
                      that.lists.songs[songIndex].id,
                    buffer: vdjToMp3Src(buffer),
                  },
                ];

                if (!this.audioDriver.src) {
                  that.currentSong = that.lists.songs[songIndex];
                  that.loadCurrentSong();
                  if (!that.isPlayingAds) {
                    that.play();
                  }

                  // if (!this.appStore.failsafeMode) {
                  //   that.appStore.newSongLog(that.currentSong.id).save();
                  // }
                }
              }
            } else {
              console.log("error descarga");
              return that.downloadNextSongs(songIndex + 1, true);
            }
          })
          .then(() => {
            return that.downloadNextSongs(songIndex + 1);
          })
      );
    } else {
      that.downloadNextSongs(songIndex + 1);
    }
  }

  downloadPrevSongs(songIndex, bool = false) {
    var that = this;

    if (typeof songIndex != "number") {
      return null;
    }

    that._downloadingNextSongs = true;

    if (that.downloadedSongs.length <= 0 && !bool) {
      return null;
    }

    if (!that.lists.songs[songIndex]) {
      that._downloadingNextSongs = false;
      return null;
    }

    if (that.downloadedSongs.length >= that._songDownloadLimit) {
      that._downloadingNextSongs = false;
      return null;
    }

    if (
      !that.downloadedSongs.find(
        (song) => song.song_id === that.lists.songs[songIndex].id
      )
    ) {
      if (!that._sessionOn) {
        that.lists = {
          songs: [],
          playlistPlans: null,
          adsGuidelines: null,
          adBatches: [],
          currentPattern: null,
          currentPatternExact: null,
          nextTimeAd: null,
          currentAdBatches: [],
          adNextCurrentTimes: [],
        };
        return null;
      }

      return (
        that.lists.songs[songIndex] &&
        that
          .fetchSong(that.lists.songs[songIndex])
          .then((buffer) => {
            if (that.downloadedSongs.length <= 0 && !bool) {
              return null;
            }

            if (buffer) {
              let search = that.downloadedSongs.some(
                (e) => e.song_id == that.lists.songs[songIndex].id
              );

              if (!search) {
                that.downloadedSongs = [
                  {
                    song_id:
                      that.lists.songs[songIndex] &&
                      that.lists.songs[songIndex].id,
                    buffer: vdjToMp3Src(buffer),
                  },
                  ...that.downloadedSongs,
                ];

                if (!this.audioDriver.src) {
                  that.currentSong = that.lists.songs[songIndex];
                  that.loadCurrentSong();
                  if (!that.isPlayingAds) {
                    that.play();
                  }
                }
              }
            } else {
              console.log("error descarga");
              return that.downloadPrevSongs(songIndex - 1, true);
            }
          })
          .then(() => {
            return that.downloadPrevSongs(songIndex - 1);
          })
      );
    } else {
      that.downloadPrevSongs(songIndex - 1);
    }
  }

  nextDownloadedSong() {
    var that = this;
    if (!that.lists.songs) {
      return null;
    }
    if (that.downloadedSongs.length === 0) {
      return null;
    }
    if (!that.currentSong) {
      return that.downloadedSongs[0];
    }

    const currentIndex = that.downloadedSongs.findIndex(
      (s) => s.song_id === that.currentSong.id
    );
    if (currentIndex === -1) {
      return that.downloadedSongs[0];
    }

    if (that.downloadedSongs.length - 1 === currentIndex) {
      return that.downloadedSongs[0];
    }

    return that.downloadedSongs[currentIndex + 1];
  }

  /*deleteSongBuffer= (songIndex) => {


    let song = this.lists.songs[songIndex];

    if (!song) return;

    let index = this.downloadedSongs.findIndex(s => s.song_id === song.id);

    if (index != -1) {
      this.downloadedSongs = this.downloadedSongs.splice(index, 1).map( o =>(Object.assign({},o)));
    }
  }*/

  /*cleanSongsMemory=() => {
    let i = this.currentSongIndex() - this._behindsongs;

    for (i; 0 <= i; i--) {
      this.deleteSongBuffer(i);
    }

    let j = this.currentSongIndex() + this._songDownloadLimit;

    for (j; j < this.lists.songs.length; j++) {
      this.deleteSongBuffer(i);
    }
  }*/

  freeMemory = () => {
    if (this.downloadedSongs.length > 0) {
      this.downloadedSongs.map((e) => URL.revokeObjectURL(e.buffer));
    }
    this.downloadedSongs = [];
    //caches.delete('musics-v0')
  };

  currentSongIndex = () => {
    if (!this.lists.songs || !this.currentSong) {
      return -1;
    }

    return this.lists.songs.findIndex((s) => s.key === this.currentSong.key);
  };

  nextSongIndex = () => {
    if (!this.lists.songs || !this.nextSong) {
      return -1;
    }
    return this.lists.songs.findIndex((s) => s.key === this.nextSong.key);
  };

  changeManualSelection = () => {
    const preference = this.appStore.loggedInUser.configs;
    this.currentPlaylistType = parseInt(preference.last_playlist_type);
    if (parseInt(preference.last_playlist_owner) == 1) {
      this.currentOwner = 1;
      this.currentCategoriesPlaylistId = preference.last_categories_playlist_id;
      const x = this.getCategoriesPlaylists(this.currentCategoriesPlaylistId);
      x.andThen((res) => {
        this.categoryId = parseInt(res.category);
        this.changePlaylist(
          res.playlist,
          res,
          this.currentCategoriesPlaylistId,
          this.currentPlaylistType,
          1
        );
      });
    } else {
      this.setCurrentCategoriesPlaylistId();
    }
  };

  setCurrentCategoriesPlaylistId = () => {
    this.preferences.andThen((response) => {
      if (response.length > 0) {
        let res = response.toArray();
        this.currentCategoriesPlaylistId = res[0].last_categories_playlist_id;
        this.currentOwner = parseInt(res[0].last_playlist_owner);
        this.categoryId = 5000;
        this.changePlaylist(
          this.currentCategoriesPlaylistId,
          0,
          this.currentCategoriesPlaylistId,
          res[0].last_playlist_type,
          res[0].last_playlist_owner
        );
      }
    });
  };

  loadHibernationPlansDay = () => {
    if (!this.appStore.failsafeMode) {
      if (!this.currentDayH) {
        this.currentDayH = this.getCurrentDay();
        this.lists.hibernationPlans =
          this.appStore.userHibernation.getHibernationPlansCurrentDay(
            this.currentDayH
          );
      } else {
        if (this.currentDayH != this.getCurrentDay()) {
          this.lists.hibernationPlans =
            this.appStore.userHibernation.getHibernationPlansCurrentDay(
              this.currentDayH
            );
        }
      }
    }
  };

  getIsHibernation = () => {
    return this.isHibernating;
  };

  getConnectionType = () => {
    if (this.isHibernating) return 4;
    return 3;
  };

  hibernationShuttle = () => {
    if (this.playlistsPlannerIsOn) {
      if (this.lists.hibernationPlans) {
        this.lists.hibernationPlans.andThen((res) => {
          let hibernation = this.currentTimePlans(res.toArray());
          if (hibernation.length === 1) {
            if (this.lastPlansHibernationId != hibernation[0].id) {
              this.lastPlansHibernationId = hibernation[0].id;
              this.intervalsTxt =
                hibernation[0].start_hour + " - " + hibernation[0].finish_hour;
              this.isHibernating = true;
              this.pauseAllPlayback();
            }
          } else {
            if (this.isHibernating) {
              this.lastPlansHibernationId = -1;
              this.isHibernating = false;
              this.intervalsTxt = "";
              this.resumeAllPlayback();
            }
          }
        });
      } else {
        if (this.isHibernating) {
          this.lastPlansHibernationId = -1;
          this.isHibernating = false;
          this.intervalsTxt = "";
          this.resumeAllPlayback();
        }
      }
    } else {
      if (this.isHibernating) {
        this.lastPlansHibernationId = -1;
        this.isHibernating = false;
        this.intervalsTxt = "";
        this.resumeAllPlayback();
      }
    }
  };

  pauseAllPlayback = () => {
    if (this.isPlayingAds) {
      this.audioAdsDriver.pause();
    } else {
      this.audioDriver.pause();
    }
  };

  resumeAllPlayback = () => {
    this.audioDriver.play();
  };

  cancelHibernation = () => {
    if (this.isHibernating) {
      this.resumeAllPlayback();
      this.lists.hibernationPlans = null;
      this.isHibernating = false;
    }
  };

  loadPlansDay = () => {
    if (!this.appStore.failsafeMode) {
      if (!this.currentDayP) {
        this.currentDayP = this.getCurrentDay();
        this.lists.playlistPlans =
          this.appStore.playlistsPlans.getPlaylistPlansCurrentDay(
            this.currentDayP
          );
      } else {
        if (this.currentDayP != this.getCurrentDay()) {
          this.currentDayP = this.getCurrentDay();
          this.lists.playlistPlans =
            this.appStore.playlistsPlans.getPlaylistPlansCurrentDay(
              this.currentDayP
            );
        }
      }
    }
  };

  loadPlansSyncUp = () => {
    if (!this.appStore.failsafeMode) {
      this.lists.playlistPlans =
        this.appStore.playlistsPlans.getPlaylistPlansCurrentDay(
          this.currentDayP
        );
      if (!this.syncPlaylistPlanner) this.syncPlaylistPlanner = true;
    }
  };

  loadHibernationSyncUp = () => {
    if (!this.appStore.failsafeMode) {
      this.lists.hibernationPlans =
        this.appStore.userHibernation.getHibernationPlansCurrentDay(
          this.currentDayH
        );
      if (!this.syncPlaylistPlanner) this.syncPlaylistPlanner = true;
    }
  };

  loadAdsPlansDay = () => {
    if (!this.currentDayA) {
      this.currentDayA = this.getCurrentDay();
      this.loadAdsGuidelines();
    } else {
      if (this.currentDayA != this.getCurrentDay()) {
        this.currentDayA = this.getCurrentDay();
        this.loadAdsGuidelines();
      }
    }
  };

  loadAdsPlansSyncUp = () => {
    this.loadAdsGuidelines();
  };

  launchActionPlaylistPlans = () => {
    if (!this.appStore.failsafeMode) {
      this.lists.playlistPlans.andThen((res) => {
        let plan = this.currentTimePlans(res.toArray());

        if (plan.length === 1) {
          if (
            this.currentCategoriesPlaylistId != plan[0].categories_playlists_id
          ) {
            this.currentCategoriesPlaylistId = plan[0].categories_playlists_id;
            this.currentPlaylistType = plan[0].playlist_type;
            if (parseInt(plan[0].owner) == 1) {
              const categoriesPlaylists = this.getCategoriesPlaylists(
                this.currentCategoriesPlaylistId
              );
              categoriesPlaylists.andThen((resx) => {
                this.categoryId = parseInt(resx.category);
                this.currentOwner = parseInt(plan[0].owner);
                this.banderClick = false;
                this.freeMemory();
                this.changePlaylist(
                  resx.playlist,
                  resx,
                  this.currentCategoriesPlaylistId,
                  this.currentPlaylistType,
                  plan[0].owner
                );
              });
            } else {
              this.currentOwner = parseInt(plan[0].owner);
              this.banderClick = false;
              this.categoryId = 5000;
              this.freeMemory();
              this.changePlaylist(
                this.currentCategoriesPlaylistId,
                0,
                this.currentCategoriesPlaylistId,
                this.currentPlaylistType,
                plan[0].owner
              );
            }
          } else {
            if (this.currentOwner != parseInt(plan[0].owner)) {
              this.currentCategoriesPlaylistId =
                plan[0].categories_playlists_id;
              this.currentPlaylistType = plan[0].playlist_type;
              if (parseInt(plan[0].owner) == 1) {
                const categoriesPlaylists = this.getCategoriesPlaylists(
                  this.currentCategoriesPlaylistId
                );
                categoriesPlaylists.andThen((resx) => {
                  this.categoryId = parseInt(resx.category);
                  this.currentOwner = parseInt(plan[0].owner);
                  this.banderClick = false;
                  this.freeMemory();
                  this.changePlaylist(
                    resx.playlist,
                    resx,
                    this.currentCategoriesPlaylistId,
                    this.currentPlaylistType,
                    plan[0].owner
                  );
                });
              } else {
                this.currentOwner = parseInt(plan[0].owner);
                this.banderClick = false;
                this.categoryId = 5000;
                this.freeMemory();
                this.changePlaylist(
                  this.currentCategoriesPlaylistId,
                  0,
                  this.currentCategoriesPlaylistId,
                  this.currentPlaylistType,
                  plan[0].owner
                );
              }
            }
          }
        }
      });
    }
  };

  launchPlaylist = () => {
    var that = this;
    if (!this.appStore.failsafeMode) {
      this.lists.playlistPlans.endUpdate();
      this.lists.playlistPlans.andThen((response, error) => {
        if (error) {
          const categoryPlaylist = JSON.parse(
            localStorage.getItem("currentCategoryPlaylist")
          );
          const songs = JSON.parse(localStorage.getItem("songs"));
          that.currentCategoryPlaylist = categoryPlaylist;
          that.lists.songs = songs;
          that.startPlaying();
        } else {
          if (response.length > 0) {
            this.changePlaylistPlan();
          } else {
            this.changeManualSelection();
          }
        }
      });
    } else {
      const categoryPlaylist = JSON.parse(
        localStorage.getItem("currentCategoryPlaylist")
      );
      const songs = JSON.parse(localStorage.getItem("songs"));
      that.currentCategoryPlaylist = categoryPlaylist;
      that.lists.songs = songs;
      that.startPlaying();
    }
  };

  loadPreferences = () => {
    if (!this.appStore.failsafeMode) {
      this.preferences = this.appStore.preferences.getPreferences();
      this.loadAudioPlayer();
      // this.loadLanguage();
    } else {
      const user = JSON.parse(localStorage.getItem("user"));
      this.preferences = user.preference;
    }
  };

  loadUserSyncUp = () => {
    this.manualUnlock = false;
    this.load;
    this.loadPreferences();
    this.loadHibernationSyncUp();
    this.loadPlansSyncUp();
    this.loadAdsPlansSyncUp();
    this.appStore.loadUserPersonalization();
    this.appStore.loadPermissions(true);
  };

  getPlaylistPlannerOn = () => {
    return this.playlistsPlannerIsOn;
  };

  getAdsPlannerOn = () => {
    return this.adsPlannerIsOn;
  };

  currentAdIndex = (adId) => {
    return this.lists.adBatches.findIndex((a) => a.ad_id === adId);
  };

  downloadTodayAds = () => {
    var that = this;

    if (that.downloadedAds.length > 0) {
      that.downloadedAds.map((e) => URL.revokeObjectURL(e.buffer));
    }
    that.downloadedAds = [];

    if (that.lists.adBatches.length === 0) return;
    for (var i = 0; i < that.lists.adBatches.length; i++) {
      let adId = toJS(that.lists.adBatches[i].advertisements.id);
      let adPath = toJS(that.lists.adBatches[i].advertisements.ad_path);

      if (that.downloadedAds.length === 0) {
        that.fetchAd(adPath).then((buffer) => {
          if (buffer) {
            that.downloadedAds = [
              ...that.downloadedAds,
              {
                ad_id: adId,
                buffer: bufferToSrc(buffer),
              },
            ];
          }
        });
      } else {
        if (that.downloadedAds.find((a) => a.ad_id != adId)) {
          that.fetchAd(adPath).then((buffer) => {
            if (buffer) {
              that.downloadedAds = [
                ...that.downloadedAds,
                {
                  ad_id: adId,
                  buffer: bufferToSrc(buffer),
                },
              ];
            }
          });
        }
      }
    }
    that.downloadedSongsOk = true;
    that.getNextExactAdPathOfBatches();
  };

  loadAdsGuidelines = () => {
    if (!this.appStore.failsafeMode) {
      var that = this;
      var date = moment().format("DD/MM/YYYY");
      var time = moment().format("HH:mm:ss");

      that.lists.adsGuidelines =
        that.appStore.advertisingGuidelines.getGuidelinesAndAdBatches(
          date,
          time
        );
      that.lists.adsGuidelines.andThen((response) => {
        if (response.length > 0) {
          let res = response.toArray();
          for (var i = 0; i < response.length; i++) {
            if (i == 0) {
              that.lists.adBatches = toJS(res[i].ad_batches);
            } else {
              let adBatches = toJS(res[i].ad_batches);
              for (var j = 0; j < adBatches.length; j++) {
                that.lists.adBatches.push(adBatches[j]);
              }
            }
          }
          that.downloadTodayAds();
        }
      });
    }
  };

  getBoolExpirationPattern = (startDate, finishDate) => {
    var boolExpirationPattern = 0;
    if (startDate == "NULL" && finishDate == "NULL") {
      boolExpirationPattern = 1;
    } else {
      startDate = startDate + ":00";
      finishDate = finishDate + ":00";
      if (
        moment().isBetween(
          moment(startDate, "DD/MM/YYYY HH:mm:ss"),
          moment(finishDate, "DD/MM/YYYY HH:mm:ss")
        )
      ) {
        boolExpirationPattern = 1;
      } else {
        boolExpirationPattern = 0;
      }
    }
    return boolExpirationPattern;
  };

  getNextAdPathOfBatches = (currentSongIndex) => {
    if (this.downloadedSongsOk == false) return;
    currentSongIndex = currentSongIndex + 1;
    var normalAdAvalible = false;
    this.lists.adsGuidelines.andThen((response) => {
      if (response.length > 0) {
        let guidelines = response.toArray();
        for (var i = 0; i < response.length; i++) {
          if (
            this.getBoolExpirationPattern(
              guidelines[i].start_date,
              guidelines[i].finish_date
            ) === 1
          ) {
            if (guidelines[i].priority === 3 || guidelines[i].priority === 5) {
              normalAdAvalible =
                guidelines[i].to_hour != "NULL" &&
                guidelines[i].from_hour != "NULL" &&
                moment().isBetween(
                  moment(guidelines[i].from_hour + ":00", "HH:mm:ss"),
                  moment(guidelines[i].to_hour + ":59", "HH:mm:ss")
                ) &&
                currentSongIndex % guidelines[i].frequency_songs == 0;
              if (normalAdAvalible) {
                if (this.lists.currentPattern == null) {
                  this.lists.currentPattern = guidelines[i];
                }
                if (
                  this.lists.currentPattern.advertising_guidelines_id !=
                  guidelines[i].advertising_guidelines_id
                ) {
                  this.lists.currentPattern = guidelines[i];
                }
                var limit = 0;
                while (limit < guidelines[i].launcher_limiter) {
                  var encontrado = false;
                  for (
                    var j = 0;
                    j < this.lists.adBatches.length && !encontrado;
                    j++
                  ) {
                    let advertisingGuidelinesId = toJS(
                      this.lists.adBatches[j].advertising_guidelines_id
                    );
                    if (
                      advertisingGuidelinesId ==
                      guidelines[i].advertising_guidelines_id
                    ) {
                      let tempBatches = toJS(this.lists.adBatches[j]);
                      this.lists.currentAdBatches.push(tempBatches);
                      this.lists.adBatches.splice(j, 1);
                      this.lists.adBatches.push(tempBatches);
                      encontrado = true;
                      limit++;
                    }
                  }
                }
              }
            }
          }
        }
        this.startAdPlaying();
      }
    });
  };

  nextAd = () => {
    var that = this;
    if (that.lists.currentAdBatches.length === 0) {
      that.audioAdsDriver.pause();
      that.isPlayingAds = false;
      that.play();
      return;
    } else {
      that.startAdPlaying();
    }
  };

  autoNextAd = () => {
    var that = this;
    if (that.audioAdsDriver.ended && that.audioAdsDriver.duration !== 0) {
      that.nextAd();
    } else {
      that.audioAdsDriver.play();
    }
  };

  startAdPlaying = () => {
    var that = this;

    if (that.lists.currentAdBatches.length != 0) {
      let adBatches = toJS(that.lists.currentAdBatches[0]);
      let downloadedAd = that.downloadedAds.find(
        (a) => a.ad_id === adBatches.advertisements.id
      );
      if (!downloadedAd) return;
      let volume = this.volume;
      if (this.isMuted) {
        volume = 0;
      }
      this.audioAdsDriver.volume = volume;
      that.audioAdsDriver.setAttribute("src", downloadedAd.buffer);
      that.audioAdsDriver.load();

      if (that.isPlaying) {
        that.pause();
      }
      that.showDurationAds();

      that.audioAdsDriver
        .play()
        .then((res) => {})
        .catch((err) => {
          // that.nextAd();
        });
      that.isPlayingAds = true;
      that.currentAd = adBatches.advertisements;
      if (that.patternExact) {
        that.appStore
          .newAdLog(
            that.lists.currentPatternExact,
            adBatches.advertisements.id,
            that.durationAdSecs
          )
          .save();
      } else {
        that.appStore
          .newAdLog(
            that.lists.currentPattern,
            adBatches.advertisements.id,
            that.durationAdSecs
          )
          .save();
      }
      that.lists.currentAdBatches.shift();
    } else {
      if (that.patternExact) {
        that.lists.currentPatternExact = null;
        that.patternExact = false;
      }
    }
  };

  getNextExactAdPathOfBatches = () => {
    if (this.downloadedSongsOk == false) return;
    if (!this.isPlaying) return;
    var boolTimeAd = false;
    var time = moment().format("HH:mm:ss");
    this.lists.adsGuidelines.andThen((response) => {
      if (response.length > 0) {
        let guidelines = response.toArray();
        for (var i = 0; i < response.length; i++) {
          if (
            this.getBoolExpirationPattern(
              guidelines[i].start_date,
              guidelines[i].finish_date
            ) === 1
          ) {
            if (guidelines[i].priority === 4 || guidelines[i].priority === 6) {
              if (
                guidelines[i].to_hour != "NULL" &&
                guidelines[i].from_hour != "NULL" &&
                moment().isBetween(
                  moment(guidelines[i].from_hour + ":00", "HH:mm:ss"),
                  moment(guidelines[i].to_hour + ":59", "HH:mm:ss")
                )
              ) {
                if (this.lists.adNextCurrentTimes.length == 0) {
                  this.lists.adNextCurrentTimes.push({
                    id: guidelines[i].advertising_guidelines_id,
                    nextTime: moment(time, "HH:mm:ss")
                      .add(guidelines[i].frequency_minutes, "minutes")
                      .format("HH:mm:ss"),
                  });
                } else {
                  let currentAdTime = this.lists.adNextCurrentTimes.find(
                    (a) => a.id === guidelines[i].advertising_guidelines_id
                  );
                  if (!currentAdTime) {
                    this.lists.adNextCurrentTimes.push({
                      id: guidelines[i].advertising_guidelines_id,
                      nextTime: moment(time, "HH:mm:ss")
                        .add(guidelines[i].frequency_minutes, "minutes")
                        .format("HH:mm:ss"),
                    });
                  } else {
                    if (
                      moment(currentAdTime.nextTime, "HH:mm:ss").isBefore(
                        moment(time, "HH:mm:ss")
                      )
                    ) {
                      let objIndex = this.lists.adNextCurrentTimes.findIndex(
                        (obj) => obj.id == currentAdTime.id
                      );
                      this.lists.adNextCurrentTimes[objIndex].nextTime = moment(
                        currentAdTime.nextTime,
                        "HH:mm:ss"
                      )
                        .add(guidelines[i].frequency_minutes, "minutes")
                        .format("HH:mm:ss");
                    }
                    this.lists.currentPattern = guidelines[i];
                    boolTimeAd = moment(
                      currentAdTime.nextTime,
                      "HH:mm:ss"
                    ).isSame(moment(time, "HH:mm:ss"));
                  }
                }

                if (boolTimeAd) {
                  var limit = 0;
                  while (limit < guidelines[i].launcher_limiter) {
                    var encontrado = false;
                    for (
                      var j = 0;
                      j < this.lists.adBatches.length && !encontrado;
                      j++
                    ) {
                      let advertisingGuidelinesId = toJS(
                        this.lists.adBatches[j].advertising_guidelines_id
                      );
                      if (
                        advertisingGuidelinesId ==
                        guidelines[i].advertising_guidelines_id
                      ) {
                        let tempBatches = toJS(this.lists.adBatches[j]);
                        this.lists.currentAdBatches.push(tempBatches);
                        this.lists.adBatches.splice(j, 1);
                        this.lists.adBatches.push(tempBatches);
                        encontrado = true;
                        limit++;
                      }
                    }
                  }
                }
              }
            } else if (
              guidelines[i].priority == 1 ||
              guidelines[i].priority == 2
            ) {
              var exactAdAvalible = moment(moment(time, "HH:mm:ss")).isSame(
                moment(guidelines[i].exact_hour + ":00", "HH:mm:ss")
              );

              if (exactAdAvalible) {
                if (this.lists.currentPatternExact == null) {
                  this.lists.currentPatternExact = guidelines[i];
                  this.patternExact = true;
                }
                var limit = 0;
                while (limit < guidelines[i].launcher_limiter) {
                  var encontrado = false;
                  for (
                    var j = 0;
                    j < this.lists.adBatches.length && !encontrado;
                    j++
                  ) {
                    let advertisingGuidelinesId = toJS(
                      this.lists.adBatches[j].advertising_guidelines_id
                    );
                    if (
                      advertisingGuidelinesId ==
                      guidelines[i].advertising_guidelines_id
                    ) {
                      let tempBatches = toJS(this.lists.adBatches[j]);
                      this.lists.currentAdBatches.push(tempBatches);
                      this.lists.adBatches.splice(j, 1);
                      this.lists.adBatches.push(tempBatches);
                      encontrado = true;
                      limit++;
                    }
                  }
                }
              }
            }
          }
        }
        this.startAdPlaying();
      } else this.lists.adNextCurrentTimes = [];
    });
  };

  onBackwardsCategory() {
    var that = this;
    that.prevSelectedCategory = that.selectedCategory;
    this.selectedCategory = null;
  }

  onBackwardsCategoryPlaylist() {
    var that = this;
    this.selectedCategoryPlaylist = null;
  }

  onBackwards() {
    if (this.histories[this.historyPosition - 1] && this.historyPosition > 0) {
      const history = this.histories[this.historyPosition - 1];

      if (this.historyPosition >= 1) {
        this.historyPosition = this.historyPosition - 1;
      }

      return history;
    }

    if (this.selectedCategoryPlaylist) {
      this.selectedCategoryPlaylist = null;
    } else {
      this.selectedCategory = null;
    }
    this.reproducingView = false;
  }

  onForward() {
    if (this.histories[this.historyPosition + 1] && this.historyPosition >= 0) {
      const history = this.histories[this.historyPosition + 1];

      if (history) {
        this.historyPosition = this.historyPosition + 1;

        return history;
      }
    }
  }

  pushHistory = (route) => {
    const histories = this.histories;
    const historyPosition = this.historyPosition;

    if (histories[historyPosition] != route) {
      if (histories[historyPosition + 1]) {
        const items = [];

        let found = false;
        for (var i = 0; i < this.histories.length; i++) {
          if (!found) {
            items.push(this.histories[i]);
          }
          if (i === historyPosition + 1) {
            found = true;
            items[i] = route;
          }
        }

        this.histories = items;
        this.historyPosition = this.historyPosition + 1;
      } else {
        this.histories.push(route);
        this.historyPosition = this.historyPosition + 1;
      }
    }
  };

  getAutoSave = () => {
    const autosave = JSON.parse(localStorage.getItem("autosave"));
    if (autosave == null) {
      return true;
    }
    return autosave;
  };

  updateAutosave = () => {
    if (this.autoSave) {
      localStorage.setItem("autosave", JSON.stringify(false));
      this.autoSave = false;
      return false;
    } else {
      localStorage.setItem("autosave", JSON.stringify(true));
      this.autoSave = true;
      return true;
    }
  };

  signIn = () => {
    //this.loadBalancer();
    this._sessionOn = true;
    this.loadPreferences();
    this.loadHibernationPlansDay();
    this.loadPlansDay();
    this.loadAdsGuidelines();
    this.launchPlaylist();
  };

  signOut() {
    this._sessionOn = false;
    this.audioAdsDriver.pause();
    this.audioDriver.pause();

    this.audioDriver.setAttribute("src", null);
    this.audioDriver.load();

    this.audioAdsDriver.setAttribute("src", null);
    this.audioAdsDriver.load();

    this.freeMemory();
    if (!this._downloadingNextSongs) {
      this.lists = {
        songs: [],
        hibernationPlans: null,
        playlistPlans: null,
        adsGuidelines: null,
        adBatches: [],
        currentPattern: null,
        currentPatternExact: null,
        nextTimeAd: null,
        currentAdBatches: [],
        adNextCurrentTimes: [],
      };
    }

    this.library = {
      songs: null,
      search: "",
      singer: false,
      favorites: false,
    };
    this._downloadingPrevSongs = false;
    this._downloadingNextSongs = false;
    this.showMainSide = false;
    this.showFloatingPlayer = false;
    this.currentSong = null;
    this.currentCategoryPlaylist = null;
    this.currentPlaylist = null;
    this.currentPlaylistType = null;
    this.currentOwner = 1;
    this.currentDayP = null;
    this.currentDayH = null;
    this.currentDayA = null;
    this.isPlaying = false;
    this.preferences = null;
    this.isPlayingAds = false;
    this.downloadedSongsOk = false;
    this.unlock = false;
    this.currentFinishTime = 0;
    this.categoryId = 0;
    this.reproducingView = false;
    this.lastPlansHibernationId = -1;
    this.isHibernating = false;
    this.intervalsTxt = "";
    this.banderClick = false;
    this.selectedCategory = null;
    // Navigations
    this.histories = [];
    this.historyPosition = -1;
    this.isCategory = false;
    this.isReproducing = false;
    this.isPlayer = false;
    //this.adIndex = 0;
  }
}
