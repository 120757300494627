import neomediaLogo from "../assets/images/neomedia.png";
import neomediaLogoMobile from "../assets/images/neomedia_m.png";
import neomediaLogoSquare from "../assets/images/neomedia_square.png";
const AWS_BUCKET = 'https://sources.megaplayer.me';

const NEOMEDIA = {
    reseller_companies_id: 7,
    logo: neomediaLogo,
    logoMobile: neomediaLogoMobile,
    logoSquare: neomediaLogoSquare,
    name: 'Neomedia',
    favicon: AWS_BUCKET+'/favicon/neomedia.ico',
    web: 'www.neomediadigital.com',
    supportContact: 'info@neomediadigital.com',
    url_panel: 'https://panel.neomediadigital.com/'
}

export default NEOMEDIA;