import kasimuLogo from "../assets/images/kasimu.png"
import kasimuLogoMobile from "../assets/images/kasimu_m.png"
import kasimuLogoSquare from "../assets/images/kasimu_square.png"
const AWS_BUCKET = 'https://sources.megaplayer.me';

const KASIMU = {
    reseller_companies_id: 5,
    logo: kasimuLogo,
    logoMobile: kasimuLogoMobile,
    logoSquare: kasimuLogoSquare,
    name: 'Kasimu',
    favicon: AWS_BUCKET+'/favicon/kasimu.ico',
    web: 'www.kasimu.com',
    supportContact: 'soporte@kasimu.com',
    url_panel: 'https://panel.kasimu.com/'
}

export default KASIMU;