import _logo from "../assets/images/ml-business.png";
import _logoSquare from "../assets/images/ml-business_square.png";
const AWS_BUCKET = 'https://sources.megaplayer.me';

const MLBUISNESS = {
    reseller_companies_id: 10,
    logo: _logo,
    logoMobile: _logo,
    logoSquare: _logoSquare,
    name: 'ml-business',
    favicon: AWS_BUCKET+'/favicon/ml-business.ico',
    web: 'www.monitorlatino.com',
    supportContact: 'soporte@monitorlatino.com',
    url_panel: 'https://monitorlatino.com/panel/'
}

export default MLBUISNESS;