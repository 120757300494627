import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { Text, Button, Img } from "ks_storybook";

@observer
class AboutPage extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.store.ui.pushHistory("/player/about");
  }

  goToPage = (to) => {
    window.open("http://" + to, "_blank");
  };

  getDeveloped = () => {
    if (this.props.store.typeView === 0) {
      return `${this.props.store.language.label57} ${
        this.props.store.currentCompany.name
      }`;
    } else {
      return `${this.props.store.language.label58} ${
        this.props.store.currentCompany.name
      }`;
    }
  };

  renderHeader() {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flex: "auto",
          textAlign: "center",
        }}
      >
        <div className="flex flex-col items-center">
          <Img
            src={this.props.store.currentCompany.logoMobile}
            className="mb-2"
            width={150}
          />
          <Text weight="bold" size="md" lead>
            {this.props.store.currentCompany.name}{" "}
            {this.props.store.version}
          </Text>
          <div className="mt-2 mb-1">
            {/* <Text>{this.props.store.language.label55}: 2023-05-31</Text> */}
            <Text>{this.props.store.language.label56}</Text>
            <Text>{this.getDeveloped()}</Text>
          </div>
          <Button
            kind="link"
            color="primary"
            size="lg"
            onClick={() =>
              this.goToPage(this.props.store.currentCompany.web)
            }
            space
          >
            {this.props.store.currentCompany.web}
          </Button>
          <Button kind="link" color="primary" size="lg" className="mx-0" space>
            {this.props.store.currentCompany.supportContact}
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return this.renderHeader();
  }
}

export default withStore(AboutPage);
