import { Store } from "ks_storybook";
import { Songs } from "../models";

class SongsStore extends Store {
  model = Songs;

  fetchAllParams(per_page, filters, orders, free_rights, search) {
    return this.search(
      {
        per_page: per_page,
        filters: filters,
        orders: orders,
        free_rights: free_rights,
        search: search,
      },
      "songs_get_all_params",
      true,
      "/songs/get_all_params"
    );
  }

  fetchPage(per_page, page, filters, orders, free_rights, search) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        filters: filters,
        orders: orders,
        free_rights: free_rights,
        search: search,
      },
      "songs_get_all_params",
      true,
      "/songs/get_all_params"
    );
  }

  fetchSongs(per_page, page, search_by, search, by_favorites) {
    return this.search(
      {
        per_page: per_page,
        page: page,
        search_by: search_by,
        search: search,
        by_favorites: by_favorites,
      },
      "search_songs",
      true,
      "/songs/search_songs"
    );
  }
}

export default SongsStore;
