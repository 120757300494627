import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Layout,
  LayoutNavbar,
  LayoutNavbarStart,
  LayoutNavbarEnd,
  LayoutSide,
  LayoutContent,
  LayoutFooter,
} from "ks_storybook";
import { SignIn } from "../Session";
import { PlayerMenu, PlayerRouter } from "../Player";
import LoggedUserDropdown from "./components/LoggedUserDropdown";
import { AudioPlayer } from "../../Components/AudioPlayer";
import { LockModal, PermissionModal } from "../../Components/Preference";
import { Tooltip } from "react-tooltip";
import Header from "./components/Header";
import Toast from "./components/Toast";

// remove this later, when all routes exist -----------
//const FakeContent = withRouter(props => <div>{props.location.pathname}</div>);

@observer
class AppRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmedClose: false,
      container: null,
      navbar: false,
    };
    this.container = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    this.myInterval = setInterval(() => {
      this.updateConnection();
      this.props.store.ui.loadHibernationPlansDay();
      this.props.store.ui.loadPlansDay();
      this.props.store.ui.loadAdsPlansDay();
    }, this.props.store.refreshTime);
    this.plansAction = setInterval(() => {
      if (navigator.onLine) {
        document.body.classList.remove("offline");
      } else {
        document.body.classList.add("offline");
      }
      this.props.store.ui.setLock();
      // hibernation
      this.props.store.ui.hibernationShuttle();
      if (this.props.store.ui.getIsHibernation()) return;

      if (this.props.store.ui.getPlaylistPlannerOn()) {
        this.props.store.ui.launchActionPlaylistPlans();
      }
      if (this.props.store.ui.getAdsPlannerOn()) {
        this.props.store.ui.getNextExactAdPathOfBatches();
      }
    }, 1000);
  };

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    clearInterval(this.myInterval);
    clearInterval(this.plansAction);
  };

  handleBeforeUnload = (event) => {
    this.props.store.disconnect_or_logout(2);
    event.preventDefault();
    return event.returnValue = 'Are you sure you want to close?';
  };

  updateConnection = () => {
    this.props.store.updateConnection(this.props.store.ui.getConnectionType(),this.props.store.ui.isPlaying)
    .then((result) => {
      if (result.results.answer_code == 2) {
        if(this.props.store.checkUserSyncUp(result.results.version_sync_up)) {
          this.props.store.ui.loadUserSyncUp();
        }
        if (this.props.store.checkCatalogChange(result.results)) {
          this.handleSignOut();
        }
      } else {
        this.handleSignOut();
      }
    });
  };

  handleSignOut = () => {
    this.props.store.signOut();
  };

  onScroll = (event) => {
    const scrollTop = event.currentTarget.scrollTop;
    var navbar = document.getElementsByClassName("ks--layout-navbar")[0];
    const showCategory = document.getElementById("show-category");

    if (scrollTop > 112 / 3) {
      navbar.classList?.add("navbar-top");
      showCategory.style.display = "block";
      // this.setState({ navbar: true });

      // this.props.store.ui.showCategory = true;
    } else {
      // this.setState({ navbar: false });
      navbar.classList?.remove("navbar-top");
      showCategory.style.display = "none";
      // this.props.store.ui.showCategory = false;
    }
  };

  getContainer = () => {
    return this.container.current;
  };

  render() {
    const path = this.props.match.path;
    const root = "/player/";
    // if (this.props.store.isLoggedIn) {
    if (this.props.store.getIsLoggedIn()) {
      const uiSettings = this.props.store.ui.settings;

      if (uiSettings.isEmpty()) return null;

      return (
        <React.Fragment>
          <Layout showSide noNavbar>
            <LayoutSide>
              <Switch>
                <Route
                  path={`${path}${root}`}
                  component={() => (
                    <PlayerMenu
                      open={this.state.open}
                      onToggle={this.handleToggle}
                    />
                  )}
                />
              </Switch>
            </LayoutSide>
            <LayoutContent
              style={{
                minHeight: "100%",
                background: "#1a1419",
                overflow: "overlay",
                height: "100%",
              }}
              ref={this.container}
              onScroll={this.onScroll}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  // height: "100vh",
                  height: "100%",
                  background:
                    "linear-gradient(180deg, #2700E9 -5.97%, #1A1419 30.82%)",
                  margin: "0vw -4.5vw",
                }}
              ></div>
              <LayoutNavbar>
                <LayoutNavbarStart>
                  <Header type="LayoutHeaderStart" />
                </LayoutNavbarStart>
                <LayoutNavbarEnd fillWidth>
                  <LoggedUserDropdown />
                </LayoutNavbarEnd>
              </LayoutNavbar>

              <div
                style={{
                  position: "relative",
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: 20,
                }}
              >
                <Switch>
                  <Route
                    exact
                    path={`${path}${root}`}
                    component={PlayerRouter}
                  />
                  <PlayerRouter
                    defaultComponent={PlayerRouter}
                    container={this.getContainer}
                  />
                  <Redirect to={`${path}${root}`} component={PlayerRouter} />
                </Switch>
              </div>
            </LayoutContent>
            <LayoutFooter style={{ padding: 0 }}>
              <AudioPlayer />
            </LayoutFooter>
          </Layout>
          {(this.props.store.ui.unlock ||
            this.props.store.ui.isHibernating) && <LockModal />}
          <PermissionModal />
          <Toast />
          <Tooltip id="open" place="left" style={{ zIndex: 20 }}>
            <span>{this.props.store.language.label74}</span>
          </Tooltip>
          <Tooltip id="close" place="bottom" style={{ zIndex: 20 }}>
            <span>{this.props.store.language.label75}</span>
          </Tooltip>
          <Tooltip id="home" place="left" style={{ zIndex: 20 }}>
            <span>{this.props.store.language.label39}</span>
          </Tooltip>
          <Tooltip id="search-playlist" place="left" style={{ zIndex: 20 }}>
            <span>{this.props.store.language.label40}</span>
          </Tooltip>
          <Tooltip id="more-options" style={{ zIndex: 20 }}>
            <span style={{ whiteSpace: "nowrap" }}>
              {this.props.store.language.label76}
            </span>
          </Tooltip>
          <Tooltip id="heart" place="top" style={{ zIndex: 3 }}>
            <span>{this.props.store.language.label77}</span>
          </Tooltip>
          <Tooltip id="panel" place="top">
            <span>{this.props.store.language.label84}</span>
          </Tooltip>

          <Tooltip id="go-back" place="bottom" style={{ zIndex: 20 }}>
            <span>{this.props.store.language.label20}</span>
          </Tooltip>
          <Tooltip id="go-forward" place="bottom" style={{ zIndex: 20 }}>
            <span>{this.props.store.language.label85}</span>
          </Tooltip>

          <Tooltip id="library" place="right" style={{ zIndex: 3 }}>
            <span>{this.props.store.language.label88}</span>
          </Tooltip>
        </React.Fragment>
      );
    }

    return (
      <Switch>
        <Redirect to={`/session/signIn`} component={SignIn} />
      </Switch>
    );
  }
}

export default withStore(withRouter(AppRouter));
