import { Store } from "ks_storybook";
import { ConexionLog } from "../models";

class ConexionsLogsStore extends Store {
  model = ConexionLog;

  fethAccountsLogs() {
    return this.search(
      {},
      "account-logs",
      false,
      "/conexions_logs/last_accounts_log"
    );
  }
}

export default ConexionsLogsStore;
