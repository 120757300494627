import { Store } from "ks_storybook";
import { UserHibernation } from "../models";

class UserHibernationStore extends Store {
  model = UserHibernation;

  getHibernationPlansCurrentDay(day) {
    return this.search(
      { per_page: 10000 },
      "users_hibernation",
      true,
      `/users_hibernation/get_hibernation_plans_current_day/${day}`
    );
  }

  getHibernationPlansUser(value) {
    return this.search(
      { per_page: 1000 },
      "users_hibernation",
      true,
      `/users_hibernation/${value}/get_hibernation_planner_user`
    );
  }
}

export default UserHibernationStore;
