import smcmLogo from "../assets/images/smcm.png";
import smcmLogoMobile from "../assets/images/smcm_m.png";
import smcmLogoSquare from "../assets/images/smcm_square.png";
const AWS_BUCKET = 'https://sources.megaplayer.me';

const SMARTMCMUSIC = {
    reseller_companies_id: 3,
    logo: smcmLogo,
    logoMobile: smcmLogoMobile,
    logoSquare: smcmLogoSquare,
    name: 'SmartMC Music',
    favicon: AWS_BUCKET+'/favicon/smcm.ico',
    web: 'www.mediachannel.net',
    supportContact: 'soporte@mediachannel.net',
    url_panel: 'https://www.mediachannel.net/smpanel/'
}

export default SMARTMCMUSIC;