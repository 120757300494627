const et_EE = {
  languages: {
    1: "Hispaania",
    2: "Inglise",
    3: "Prantsuse",
    4: "Portugali",
    5: "Eesti",
    6: "Saksa",
    7: "Itaalia"
  }, 
  label1: "Keeled",
  label2: "Keelt vahetada",
  label3: "Saate valida ja muuta soovitud keelt.",
  label4: "Ilma kirjelduseta",
  label5: "Rakendus laadimisel...",
  label6: `Teie tellimus on aegunud. Mine `,
  label7: "See konto on ühendatud teisest asukohast.",
  label8: "Viga, vale kasutajanimi või parool.",
  label9: "Halduskontodel puudub juurdepääs veebipleierile!",
  label10: "Kasutaja on peatatud! Võtke ühendust ",
  label11: "Palun sisestage kasutajanimi ja parool.",
  label12: "Kasutaja on peatatud administraatori poolt",
  label13: "Viga serveriga ühenduse loomisel, kontrollige oma ühendust.",
  label14: `Kasutajanimi`,
  label15: `(Ärge kasutage e-posti)`,
  label16: `Parool`,
  label17: `Logi sisse`,
  label18: `Kategooria`,
  label19: `Minu esitusloendid`,
  label20: `Tagasi`,
  label21: `Ühenda juhtpaneeliga, et luua esitusloendeid.`,
  label22: `Hetkel puuduvad andmed`,
  label23: `Viga, PIN-kood peab olema 4-kohaline.`,
  label24: `Viga, vale PIN-kood.`,
  label25: `Mängija on lukustatud`,
  label26: `Palun sisesta oma PIN-kood selle avamiseks.`,
  label27: `Kohustuslik`,
  label28: `Logi välja`,
  label29: `Vali koha tüübi järgi`,
  label30: `Vali muusika stiili järgi`,
  label31: `Vali muude kriteeriumide järgi`,
  label32: `Mängija on talveunerežiimis`,
  label33: `Palun sisesta oma PIN-kood talveunerežiimist väljumiseks.`,
  label34: `Avage`,
  label35: `Tühista`,
  label36: `Avaleht`,
  label37: `Esitab`,
  label38: `Laulud`,
  label39: `Avaleht`,
  label40: `Otsi esitusloendeid`,
  label41: `Muusikakogu`,
  label42: `Artist`,
  label43: `Otsi laulu nime järgi`,
  label44: `Otsi kunstniku järgi`,
  label45: `Tulemused`,
  label46: `Lisa järgmiseks esitamiseks`,
  label47: `Esitusloendi planeerija`,
  label48: `Isikupärastamine`,
  label49: `Reklaamiplaneerija`,
  label50: `Reklaamid`,
  label51: `Juhtpaneel`,
  label52: `Teave`,
  label53: `Sisse lülitatud`,
  label54: `Välja lülitatud`,
  label55: `Andmebaasi värskendamise versioon`,
  label56: `Muusika rakendus`,
  label57: `Selle rakenduse on välja töötanud`,
  label58: `Selle rakenduse jaoks on välja töötatud`,
  label59: "Jaanuar,Veebruar,Märts,Aprill,Mai,Juuni,Juuli,August,September,Oktoober,November,Detsember",
  label60: "Jaan,Veeb,Märts,Apr,Mai,Juuni,Juuli,Aug,Sept,Okt,Nov,Dets",
  label61: "Pühapäev,Esmaspäev,Talitluspäev,Kolmapäev,Neljapäev,Reede,Laupäev",
  label62: "P,E,T,K,N,R,L",
  label63: "P,N,T,K,N,R,L",
  label64: "Ühendatud",
  label65: "Ühenduseta",
  label66: `Kas soovite välja logida?`,
  label67: "Jah",
  label68: "Ei",
  label69: `Liiguta järgmiseks esitamiseks`,
  label70: `Märgi järgmiseks esitamiseks`,
  label71: `Eemalda laul esitusloendist`,
  label72: `Otsimine...`,
  label73: `Otsi...`,
  label74: `Ava`,
  label75: `Sulge`,
  label76: `Veel valikuid`,
  label77: `Lemmik`,
  label78: `Lukusta`,
  label79: `Eelmine`,
  label80: `Esita`,
  label81: `Paus`,
  label82: `Järgmine`,
  label83: `Esitusloendis`,
  label84: `Mine veebipaneelile`,
  label85: `Edasi`,
  label86: `Laajenda muusikakogu`,
  label87: `Ahenda muusikakogu`,
  label88: `Laienda muusikakogu`,
  label89: `Vaigistatud`,
  label90: `Kas soovid seda laulu märkida soovimatuna?`,
  label91: `Seda enam ei kuulata.`,
  label92: `Laadin Andmeid...`,
  label93: "Seaded",
  label94: "Konto",
  label95: "Tellitud teenus",
  label96: "Eelistused",
  label97: "Helitugevus",
  label98: "Saate muuta või vaigistada helitugevust veebipaneelilt.",
  label99: "Plaanijad ja reklaamid",
  label100: "Planeeri lemmikloendid soovitud ajakavaga, seda saab teha veebipaneelilt.",
  label101: "Loo ja määra reklaamikampaaniaid soovitud ajakavaga, seda saab teha veebipaneelilt.",
  label102: "Laadi üles oma reklaamid, seda saab teha veebipaneelilt.",
  label103: "Teave",
  label104: "Versioon",
  label105: "Arendaja",
  label106: "Veebisait",
  label107: "Privaatsuspoliitika",
  label108: "Oluline nii sulle kui ka meile.",
  label109: "Toetus",
  label110: "Loe meie abi.",
  label111: "Muud",
  label112: "Logi välja",
  label113: "Oled sisse loginud kui",
  label114: "ESITATAKSE ALATES",
  label115: "Meeldib",
  label116: "Peida see laul",
  label117: "Liiguta järgmiseks esitamiseks",
  label118: "Märgi järgmiseks esitamiseks",
  label119: "Eemalda praegusest nimekirjast",
  label120: "Lisa järgmiseks esitamiseks",
  label121: `Reklaam`,
  label122: "Salvesta muudatused",
  label123: "Automaatsalvestus (Sisse lülitatud)",
  label124: "Automaatsalvestus (Välja lülitatud)",
  label125: "Automaatsalvestus sisse lülitatud",
  label126: "Automaatsalvestus välja lülitatud",
  label127: "Laul lisati esitusloendisse edukalt",
  label128: "Laulu ei õnnestunud lisada esitusloendisse",
  label129: "Esitusloend loodi edukalt",
  label130: "Esitusloendi nimi on juba olemas, valige teine nimi",
  label131: "Uue esitusloendi jaoks tuleb märkida nimi",
  label132: "Laulud lisati esitusloendisse edukalt",
  label133: "Laulude lisamine esitusloendisse ebaõnnestus",
  label134: "Valige esitusloend",
  label135: "Kasutajal puudub baaspiirangute konfiguratsioon, võtke ühendust toega",
  label136: "Laul märgiti lemmikuks, see kavandatakse alati",
  label137: "Laulu pole enam lemmik, seda ei kavandata enam alati",
  label138: "Laul lisati esitusloendi reproduktsiooniks",
  label139: "Valige esitusloend",
  label140: "Käsitsi esitusloendid",
  label141: "Uue esitusloendi nimi",
  label142: "Lisa",
  label143: "Salvesta",
  label144: "Lisa olemasolevasse esitusloendisse",
  label145: "Lisa uude esitusloendisse",
  label146: "Selle tegevuse jaoks on vajalik internetiühendus, kontrollige ühendust",
  label147: "Lisa laulud olemasolevasse esitusloendisse",
  label148: "Kõik esitusloendis olevad laulud lisatakse olemasolevasse esitusloendisse",
  label149: "Lisa laulud uude esitusloendisse",
  label150: "Kõik esitusloendis olevad laulud lisatakse uude esitusloendisse",
  label151: "Teie kasutajal pole käsitsi loodud esitusloendeid",
  label152: "Salvestatud edukalt",
  label153: "Lisamine...",
  label154: "Salvestamine...",
  label155: "Salvestamine ebaõnnestus",
  label156: "Teil pole õigust minu esitusloenditesse pääseda",
  label157: "Teil pole õigust minu lemmikmänguloenditesse pääseda",
  label158: "Teil pole õigust kategooriatele ja esitusloenditele pääseda",
  label159: "Teil pole õigust kogu muusika sisule pääseda, ainult lemmikutele",
  label160: "Teil pole õigust muusikakogule pääseda",
  label161: "Teil pole õigust seadetele pääseda",
  label162: "Teil pole õigust välja logida",
  label163: "Teil pole õigust juhtpaneelile pääseda",
  label164: "Teil pole õigust esitusloendi planeerijale pääseda",
  label165: "Teil pole õigust reklaami planeerijale pääseda",
  label166: "Teil pole õigust reklaamidele pääseda",
  label167: "Teil pole õigust rakendusest väljuda",
  label168: "Teil pole õigust Esita/Paus nuppudele",
  label169: "Teil pole õigust muuta praegust laulu",
  label170: "Teil pole õigust muuta laulu edenemisriba",
  label171: "Teil pole õigust miniatuurmängija vaatamisele",
  label172: "Teil pole õigust laule esitusloendis liigutada",
  label173: "Teil pole õigust muuta käsitöö esitusloendi muudatusi",
  label174: "Teil pole õigust lisada laule uude või olemasolevasse käsitöö esitusloendisse",
  label175: "Teil pole õigust märkida laule lemmikuteks või eemaldada märgetest",
  label176: "Teil pole õigust peita laule esitusloendis",
  label177: "Teil pole õigust eemaldada laule esitusloendist",
  label178: "Lemmikloendid",
  label179: "Kõik teie ja teie administraatori lemmikloendid",
  label180: "Piiratud juurdepääs PIN-koodi abil",
  label181: "Lemmikud",
  label182: "See esitusloend on administraatori oma, seda ei saa muuta",
  label183: "Iga päev",
  label184: "Esmaspäevast reedeni",
  label185: "Laupäev ja pühapäev",
  label186: "Täpne päev",
  label187: "Täpne aeg",
  label188: "Intervall",
  label189: "Iga",
  label190: "Sagedus",
  label191: "Laulud",
  label192: "Reklaamide limiit tands",
  label193: "Pauta algus",
  label194: "Pauta lõpp",
  label195: "Administraatori omad",
  label196: "Kasutaja omad",
  label197: `(Administraator) - `,
  label198: `(Kasutaja) - `,
  label199: `Talveunerežiim`,
  label200: "Kehtetu tellimus, võtke ühendust teenusepakkujaga"
};

export default et_EE;