import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { observable, computed } from "mobx";
import withStore from "../../hocs/withStore";
import {
  Img,
  RectSkeleton,
  Text,
  Button,
  Columns,
  Column,
  TextInput,
  CardSong,
  RadioGroup,
  Radio,
} from "ks_storybook";
import { SongRow } from ".";
import { v4 as uuidv4 } from "uuid";

@observer
class PlayerView extends Component {
  @observable playlist;
  @observable songs;

  componentDidMount() {
    const { category, categoryPlaylist } = this.props;
    const ctpl = categoryPlaylist.toJS
      ? categoryPlaylist.toJS()
      : categoryPlaylist;
    localStorage.setItem("currentCategoryPlaylist", JSON.stringify(ctpl));
    this.props.store.ui.currentCategoryPlaylist = categoryPlaylist;
    this.props.store.ui.categoryId = category.id;
    let playlistType = category.id == 5000 ? 4 : 0;
    this.props.store.ui.freeMemory();
    this.playlist = this.getPlaylist(playlistType);
    playlistType =
      category.id == 5000
        ? this.props.store.ui.getPlaylistsTypeUser(
            this.playlist.static,
            this.playlist.random
          )
        : 0;
    const that = this;
    that.playlist.andThen((result) => {
      if (this.playlist.static === 1) {
        this.getSongs(this.playlist, playlistType);
      } else {
        that.songs = this.playlist.build();
        that.songs.andThen((res) => {
          for (let song of res.toArray()) {
            song.key = uuidv4();
          }
          that.props.store.ui.currentPlaylistType = playlistType;
          if (playlistType < 4) this.props.store.ui.ownerClick = 1;
          if (
            playlistType == 1 ||
            playlistType == 3 ||
            playlistType == 5 ||
            playlistType == 7
          )
            that.props.store.ui.lists.songs = this.props.store.ui.shuffle(
              res.toArray()
            );
          else that.props.store.ui.lists.songs = res.toArray();

          const arr = [];

          that.props.store.ui.lists.songs.map((p) =>
            arr.push({ ...p.toJS(), key: p.key })
          );

          localStorage.setItem("songs", JSON.stringify(arr));

          that.props.store.ui.startPlaying();
          that.props.store.ui.hideMiniPlayer = !that.props.mobile;

          this.props.store.ui.isPlayer = false;
          this.props.store.ui.isReproducing = true;
          this.props.store.ui.pushHistory("/player/playlist");
          // this.props.store.ui.reproducingView = true;
        });
      }
    });
  }

  getSongs = (playlist, playlistType) => {
    const that = this;
    this.props.store.kasimuAPIClient
      .search("/users_playlist/build_list", {
        playlist_id: playlist.playlist_id,
        user_id: playlist.user_id,
      })
      .then((res) => {
        const data = res.results;
        for (let song of data) {
          song.key = uuidv4();
        }
        that.props.store.ui.currentPlaylistType = playlistType;
        if (playlistType < 4) this.props.store.ui.ownerClick = 1;
        if (
          playlistType == 1 ||
          playlistType == 3 ||
          playlistType == 5 ||
          playlistType == 7
        )
          that.props.store.ui.lists.songs = this.props.store.ui.shuffle(data);
        else that.props.store.ui.lists.songs = data;

        const arr = [];

        that.props.store.ui.lists.songs.map((p) =>
          arr.push({ ...p, key: p.key })
        );

        localStorage.setItem("songs", JSON.stringify(arr));

        that.props.store.ui.startPlaying();
        that.props.store.ui.hideMiniPlayer = !that.props.mobile;

        this.props.store.ui.isPlayer = false;
        this.props.store.ui.isReproducing = true;
        this.props.store.ui.pushHistory("/player/playlist");
      });
  };

  getOwner(user_id) {
    if (this.props.store.loggedInUser.id == user_id) return 2;
    return 3;
  }

  getPlaylist(playlistType) {
    const { categoryPlaylist } = this.props;
    if (playlistType < 4) {
      this.props.store.ui.banderClick = true;
      this.props.store.ui.updatePreference(categoryPlaylist.id);
      return this.props.store.playlists.get(categoryPlaylist.playlist);
    } else {
      this.props.store.ui.banderClick = true;
      this.props.store.ui.ownerClick = this.getOwner(categoryPlaylist.user_id);
      this.props.store.ui.updatePreferenceUser(
        categoryPlaylist.playlist_id,
        categoryPlaylist.user_id,
        categoryPlaylist.random,
        categoryPlaylist.static
      );
      return this.props.store.userPlaylist.get(categoryPlaylist.id);
    }
  }

  handleTogglePlay = () => {
    if (!this.props.store.ui.isPlaying) {
      this.props.store.ui.play();
    } else {
      this.props.store.ui.pause();
    }
  };

  @computed
  get renderSongs() {
    if (!this.songs || !this.songs.isOk()) {
      return this.props.store.playlists
        .getDummy(10)
        .map(() => <RectSkeleton height="60px" width="100%" />);
    }

    return this.songs
      .toArray()
      .map((song, index) => (
        <SongRow key={`song-${index}`} song={song} idx={index} />
      ));
  }

  renderSkeleton() {
    return (
      <React.Fragment>
        <div>
          <Columns style={{ gridGap: "2rem" }} isVCentered>
            <Column isSize={{ mobile: 12, desktop: 4, tablet: 4 }}>
              <RectSkeleton width="100%" height="250px" />
            </Column>
            <Column isSize={{ mobile: 12, desktop: 8, tablet: 8 }}>
              <div>
                <RectSkeleton width="170px" height="15px" />
                <div className="py-1">
                  <RectSkeleton width="370px" height="50px" />
                </div>
                <RectSkeleton width="370px" height="15px" />
                <RectSkeleton width="170px" height="15px" />
              </div>
            </Column>
          </Columns>
          <Columns className="mt-4">
            <Column
              isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
              style={{
                backgroundColor: "#000000",
                padding: "20px",
                borderRadius: "15px",
              }}
            >
              {this.renderSongs}
            </Column>
          </Columns>
        </div>
      </React.Fragment>
    );
  }

  render() {
    // Render skeleton when you get the songs and then you will display the reproducingView
    // const { category } = this.props;

    // if (
    //   !this.playlist ||
    //   this.playlist.isBusy() ||
    //   !this.songs ||
    //   this.songs.isEmpty()
    // ) {
    return <div>{this.renderSkeleton()}</div>;
    // }
    // return this.props.store.ui.banderClick
    //   ? this.renderHeader(this.props.store.ui.ownerClick, category)
    //   : this.renderHeader(this.props.store.ui.currentOwner, category);
  }
}

PlayerView.defaultProps = {
  categoryPlaylist: null,
  category: null,
  onGoback: null,
  mobile: false,
};

PlayerView.propTypes = {
  categoryPlaylist: PropTypes.object,
  category: PropTypes.object,
  onBackwards: PropTypes.func,
  mobile: PropTypes.bool,
};

export default withStore(PlayerView);
