import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../../hocs/withStore";
import {
  Text,
  Button,
  Paragraph,
  Panel,
  TextInput,
  Loader,
  Columns,
  Column,
} from "ks_storybook";
import { EmptyContent } from "../../../../Components/Common/";
import { AdsRow } from "../../../../Components/Ads";

@observer
class AdsListPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ads: this.getAdAll(),
      currentAd: null,
      search: "",
    };
  }

  componentDidMount() {
    this.props.store.menuTitle = this.props.store.language.label50;
    this.props.store.ui.pushHistory("/player/ads/list");
  }

  componentWillUnmount() {
    this.props.store.menuTitle = null;
  }

  getAdAll = () => {
    if (!this.props.store.failsafeMode) {
      return this.props.store.ads.fetchAll();
    } else {
      return null;
    }
  };

  handleChangeSearch = (sender, value, name) => {
    this.setState({ search: value });
    this.props.store.ui.destroy();
  };

  getSearch = (obj, search) => {
    var arr = [];
    if (obj.length != 0) {
      arr = obj.filter(
        (e) => e.name.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }
    return arr;
  };

  renderEmptyContent() {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  }

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label92}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  renderHeaderOk = () => {
    const { ads, search } = this.state;
    const currentAdId = this.props.store.ui.currentAdId;
    const arrayAds = this.getSearch(ads.toArray(), search);
    return (
      <React.Fragment>
        {arrayAds.length === 0 ? (
          <EmptyContent />
        ) : (
          arrayAds.map((ad) => (
            <AdsRow
              ad={ad}
              active={currentAdId && ad.id == currentAdId}
              onDeleteClick={this.handleShowDeleteModal}
            />
          ))
        )}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { ads } = this.state;
    if (!ads) return this.renderEmptyContent();
    return !ads.isOk() ? this.renderLoader() : this.renderHeaderOk();
  };

  render() {
    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <div
                className="flex items-center"
                style={{
                  justifyContent: "space-between",
                }}
              >
                <Text
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  lead
                  weight="black"
                  lineBreak
                >
                  {this.props.store.language.label50}
                </Text>

                <Button
                  className="px-2"
                  onClick={() =>
                    this.props.store.getPermission(8, 8, () => {
                      this.props.store.goToPanel();
                    })}
                  size="lg"
                  color="basic"
                  kind="outline"
                  id="panel"
                >
                  {this.props.store.language.label51}
                </Button>
              </div>
            )}
            <div
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            ></div>
            {this.renderHeader()}
          </Column>
        </Columns>
      </React.Fragment>
    );
  }
}

export default withStore(AdsListPage);
