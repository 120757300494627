import { Store } from "ks_storybook";
import { UserPersonalization } from "../models";

class UserPersonalizationStore extends Store {
  model = UserPersonalization;

  getUsersPersonalization() {
    return this.search(
      {},
      "users_personalization",
      true,
      `/users_personalization`
    );
  }
}

export default UserPersonalizationStore;
