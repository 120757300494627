import { Store } from "ks_storybook";
import { UserKSPermissions } from "../models";

class UserKSPermissionsStore extends Store {
  model = UserKSPermissions;

  fetchAllParams() {
    return this.search(
      { per_page: 1000, apply_in: 2 },
      "get_params",
      true,
      "/ks_permissions/get_params"
    );
  }
}

export default UserKSPermissionsStore;
