import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { Button, Text } from "ks_storybook";

@observer
class MobileHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      container: null,
      navbar: false,
    };
    this.container = React.createRef();
  }

  handleBackwards = () => {
    const path = this.props.match.path;
    const history = this.props.store.ui.onBackwards();

    if (history) {
      if (history == "/player/categories") {
        this.props.store.ui.isCategory = true;
        this.props.store.ui.isReproducing = false;
        this.props.history.push(path + "/player/home");
      } else if (history == "/player/playlist") {
        this.props.store.ui.isReproducing = true;
        this.props.store.ui.isCategory = false;
        this.props.history.push(path + "/player/home");
      } else {
        this.props.store.ui.isCategory = false;
        this.props.store.ui.isReproducing = false;
        this.props.history.push(path + history);
      }
    }
  };

  getExistsBackwards = () => {
    const histories = this.props.store.ui.histories;
    const historyPosition = this.props.store.ui.historyPosition;

    return !histories[historyPosition - 1];
  };

  showHeader = () => {
    const { pathname } = this.props.history.location;
    if (pathname === "/app/player/home") {
      return false;
    }

    return true;
  };

  renderHeaderStart = () => {
    return (
      this.props.store.renderHeaderStart || (
        <Button
          size="xl"
          onClick={() => this.handleBackwards()}
          iconKasimu="arrow-left"
          color="black"
          className="mr-1"
          id="go-back"
          space
          disabled={this.getExistsBackwards()}
        />
      )
    );
  };

  renderHeaderContent = () => {
    return (
      this.props.store.renderHeaderContent || (
        <Text size="xxl" weight="bold">
          {this.props.store.menuTitle}
        </Text>
      )
    );
  };

  renderHeaderEnd = () => {
    return this.props.store.renderHeaderEnd;
  };

  render() {
    const { type } = this.props;

    if (type === "MobileLayoutHeaderStart") return this.renderHeaderStart();
    if (type === "MobileLayoutHeaderContent") return this.renderHeaderContent();
    if (type === "MobileLayoutHeaderEnd") return this.renderHeaderEnd();
  }
}

export default withStore(withRouter(MobileHeader));
