import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Text,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
} from "ks_storybook";
import { computed } from "mobx";
import { withToastManager } from "react-toast-notifications";
import { Tooltip } from "react-tooltip";

@observer
class PermissionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pin: null,
      messageError: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUnlock = this.handleUnlock.bind(this, true);
  }

  handleClose = () => {
    this.props.store.permissionPin = false;
  };

  validLengthPin = (value) => {
    if (value.length >= 4 && value.length <= 4) {
      return true;
    } else {
      return false;
    }
  };

  handleChange(sender, value, name, validation) {
    switch (name) {
      case "pin":
        if (this.validLengthPin(value)) {
          this.setState({ messageError: null });
          this.setState({ pin: value });
        } else {
          this.setState({ messageError: this.props.store.language.label23 });
        }
        break;
      default:
        break;
    }
  }

  handleKeyPress = (event) => {
    if (event.key == "Enter" && !this.canSave) {
      this.handleUnlock();
    }
  };

  handleUnlock() {
    if (this.state.pin == this.props.store.loggedInUser.configs.pin) {
      this.setState({ pin: null });
      this.props.store.validatePinPermission();
    } else {
      this.setState({ messageError: this.props.store.language.label24 });
    }
  }

  handleCancelHibernation = () => {
    this.props.store.ui.cancelHibernation();
  };

  handleTogglePlay = () => {
    if (!this.props.store.ui.isPlaying) {
      this.props.store.ui.play();
    } else {
      this.props.store.ui.pause();
    }
  };

  @computed
  get canSave() {
    if (this.state.messageError) return true;
    if (this.state.pin == null) return true;
    return false;
  }

  renderButtons() {
    if (this.props.store.ui.isHibernating) {
      return (
        <div>
          <Button
            disabled={this.canSave}
            className="px-3"
            onClick={this.handleUnlock}
            size={this.props.store.viewMobile ? "lg" : "md"}
          >
            <strong>{this.props.store.language.label35}</strong>
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <Button
            disabled={this.canSave}
            className="px-3"
            onClick={this.handleUnlock}
            size={this.props.store.viewMobile ? "lg" : "md"}
            weight="bold"
          >
            <strong>{this.props.store.language.label34}</strong>
          </Button>
        </div>
      );
    }
  }

  renderButtonsCancel() {
    return (
      <div className="flex" style={{ justifyContent: "center" }}>
        <Button
          className="px-3"
          onClick={this.handleCancelHibernation}
          size={this.props.store.viewMobile ? "lg" : "md"}
        >
          <strong>{this.props.store.language.label35}</strong>
        </Button>
      </div>
    );
  }

  render() {
    if (!this.props.store.permissionPin) {
      return null;
    }
    return (
      <Modal
        show
        width={
          this.props.store.viewMobile
            ? this.props.store.ui.isHibernating
              ? "500px"
              : "380px"
            : "90%"
        }
        height={
          this.props.store.viewMobile
            ? this.props.store.ui.isHibernating
              ? "350px"
              : "250px"
            : "auto"
        }
        onClose={this.handleClose}
      >
        <ModalHeader></ModalHeader>
        <ModalContent>
          <div
            className="flex flex-col items-center pb-1"
            style={{
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
            >
              {this.props.store.language.label180}
            </Text>

            <br />
            <div className="flex items-center">
              <div
                style={{
                  width: 120,
                }}
              >
                <TextInput
                  borderColor="none"
                  autocomplete="new-password"
                  className="is-fullwidth"
                  size="lg"
                  type="password"
                  name="pin"
                  onKeyPress={this.handleKeyPress}
                  onChange={this.handleChange}
                  placeholder={"PIN"}
                  textAlign="center"
                  paddingInput="20px 0px !important"
                  id="pin"
                />
              </div>
            </div>

            {this.state.messageError && (
              <>
                <Panel color="error" className="mt-2" invert>
                  <Text multiline>{this.state.messageError}</Text>
                </Panel>
              </>
            )}

            <br />

            {this.renderButtons()}
          </div>
        </ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

PermissionModal.propTypes = {};

PermissionModal.defaultProps = {};

export default withToastManager(withStore(PermissionModal));
