import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import { Button, Text, Img, Columns, Column } from "ks_storybook";
import Progress from "./Progress";
import { Tooltip } from "react-tooltip";
import ReproducingView from "./ReproducingView";

@observer
class AudioPlayer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0,
      reproducingView: false,
      open: false,
    };

    this.handleTogglePlay = this.handleTogglePlay.bind(this);
    this.handleNextClick = this.handleNextClick.bind(this);
    this.handlePrevClick = this.handlePrevClick.bind(this);
  }

  handleTogglePlay() {
    if (!this.props.store.ui.isPlaying) {
      this.props.store.ui.play();
    } else {
      this.props.store.ui.pause();
    }
  }

  handleNextClick() {
    this.props.store.ui.next();
  }

  handlePrevClick() {
    this.props.store.ui.prev();
  }

  openReproducingView = (event) => {
    this.showReproducingView();
  };

  showReproducingView = () => {
    this.setState({ reproducingView: true });
  };

  hideReproducingView = () => {
    this.setState({ reproducingView: false });
  };

  handleReproducingViewTrue = () => {
    const path = this.props.match.path;

    this.props.store.ui.isReproducing = true;
    this.props.store.ui.pushHistory("/player/playlist");
    this.props.history.push(`${path}/player/home`);
  };

  updateSong = () => {
    this.props.store.ui.updateSong(this.state.value);
  };

  handleSong = (value) => {
    this.setState({ value: (this.props.store.ui.durationSecs / 100) * value });
  };

  getUserFav = (song) => {
    if (song.preference == 0) return true;

    return false;
  };

  getUserBan = (song) => {
    if (song.preference == 10) return true;

    return false;
  };

  handleFav = (event) => {
    const song = this.props.store.ui.currentSong;
    const index = this.props.store.ui.currentSongIndex();
    const songId = song.id;

    const isFav = this.getUserFav(song);
    const { updateList } = this;

    if (!this.props.store.failsafeMode) {
      if (isFav) {
        this.props.store.kasimuAPIClient
          .putOne("/songs/unmark_song_as_like", "?song_id=" + songId)
          .then(
            (res) => {
              const { preference } = res.results;
              updateList(index, "preference", preference);
            },
            (error) => {
              console.log("error", error);
            }
          );
      } else {
        this.props.store.kasimuAPIClient
          .putOne("/songs/mark_song_as_like", "?song_id=" + songId)
          .then(
            (res) => {
              const { preference } = res.results;
              updateList(index, "preference", preference);
            },
            (error) => {
              console.log("error", error);
            }
          );
      }
    }
  };

  handleBan = () => {
    const { blockSong, state } = this.props;
    const song = this.props.store.ui.currentSong;
    const index = this.props.store.ui.currentSongIndex();
    const songId = song.id;

    const items = state.columns["column-0"].items;

    const songIdx = items
      .map((x) => {
        return x.song.id;
      })
      .indexOf(songId);

    const songKey = items[songIdx].key;

    blockSong(this.getUserBan(song), song, songKey);
  };

  updateList = (index, field, value) => {
    const lists = this.props.store.ui.lists.songs;

    lists[index][field] = value;

    this.changeListSongs(lists);
  };

  changeListSongs = (songs) => {
    let newList = [];
    for (let item of songs) {
      if (!this.getUserBan(item)) {
        newList.push(item);
      }
    }
    this.props.store.ui.lists.songs = newList;
  };

  renderKasimu(mobileStyle, desktopStyle, kasimu) {
    if ("mediaSession" in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: this.props.store.ui.currentSong.name,
        artist: this.props.store.ui.currentSong?.singer?.name,
        album:
          this.props.store.ui.currentCategoryPlaylist?.alias ||
          this.props.store.ui.currentCategoryPlaylist?.name,
        artwork: [
          {
            src: this.props.store.currentCompany.logoSquare,
            type: "image/png",
          },
        ],
      });
    }

    if (this.props.mobile) {
      const isPlayingAds = this.props.store.ui.isPlayingAds;
      return (
        <div
          key={
            kasimu
              ? `${this.props.store.ui.currentCategoryPlaylist}-${this.props.store.ui.currentSong}`
              : `${this.props.store.ui.currentSong}`
          }
          className="is-v-centered is-centered"
          style={this.props.mobile ? mobileStyle : desktopStyle}
          onClick={this.openReproducingView}
        >
          <Columns
            className="flex items-center pt-1 px-1"
            style={{
              margin: "0 auto",

              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <Column
              className="is-row "
              style={{
                alignItems: "center",
                overflow: "hidden",
                justifyContent: "space-between",
              }}
              isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
            >
              <div className="flex items-center" style={{ overflow: "hidden" }}>
                <Img
                  height="40px"
                  width="40px"
                  src={
                    kasimu
                      ? this.props.store.replaceBucketUrl(this.props.store.ui.currentCategoryPlaylist?.icon_path)
                      : this.props.store.ui.currentCategoryPlaylist?.static ===
                        1
                      ? this.props.store.ui._iconMyplaylists2
                      : this.props.store.ui._iconMyplaylists
                  }
                  style={{
                    borderRadius: "4px",
                    boxShadow: "0 0 10px rgba(0,0,0,.3)",
                  }}
                />

                {isPlayingAds ? (
                  <div
                    style={{
                      margin: `0 ${this.props.mobile ? "10px" : "20px"}`,
                      overflow: "hidden",
                    }}
                  >
                    <Text
                      weight="regular"
                      size="lg"
                      color="white"
                      maxLength={this.props.mobile ? 50 : 250}
                    >
                      {this.props.store.ui.currentAd?.name}
                    </Text>
                    <Text
                      size="md"
                      color="special"
                      maxLength={this.props.mobile ? 50 : 250}
                    >
                      {this.props.store.language.label121}
                    </Text>
                  </div>
                ) : (
                  <div
                    style={{
                      margin: `0 ${this.props.mobile ? "10px" : "20px"}`,
                      overflow: "hidden",
                    }}
                  >
                    <Text
                      weight="regular"
                      size="lg"
                      color="white"
                      maxLength={this.props.mobile ? 50 : 250}
                    >
                      {this.props.store.ui.currentSong?.name}
                    </Text>
                    <Text
                      size="md"
                      color="special"
                      maxLength={this.props.mobile ? 50 : 250}
                    >
                      {this.props.store.ui.currentSong?.singer?.name}
                    </Text>
                  </div>
                )}
              </div>
              <div className="flex">
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.store.getPermission(20, 20, () => {
                      this.handleFav();
                    });
                  }}
                >
                  <Button
                    iconKasimu={
                      this.getUserFav(this.props.store.ui.currentSong)
                        ? "heart-solid"
                        : "heart"
                    }
                    kind="link"
                    color={
                      this.getUserFav(this.props.store.ui.currentSong)
                        ? "primary"
                        : "basic"
                    }
                    size="lg"
                    id="heart"
                  />
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    this.props.store.ui.playAutoError
                      ? this.handleTogglePlay(e)
                      : this.props.store.getPermission(13, 13, () => {
                          this.handleTogglePlay(e);
                        });
                  }}
                >
                  <Button
                    icon={this.props.store.ui.isPlaying ? "pause" : "play"}
                    kind="link"
                    color="basic"
                    size="lg"
                    id="play-button"
                  />
                </div>
              </div>
            </Column>

            <Column
              className="flex"
              style={{ flexDirection: "column", width: "100%" }}
              isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
            >
              <progress
                value={this.props.store.ui.currentProgress}
                max={String(this.props.store.ui.durationSecs)}
                className="progress"
                style={{ height: "2px" }}
              ></progress>
            </Column>
          </Columns>
        </div>
      );
    }

    if (this.props.small) {
      return (
        <>
          <div
            key={
              kasimu
                ? `${this.props.store.ui.currentCategoryPlaylist}-${this.props.store.ui.currentSong}`
                : `${this.props.store.ui.currentSong}`
            }
            className="py-2 px-2 is-v-centered is-centered"
            style={this.props.mobile ? mobileStyle : desktopStyle}
          >
            <Columns
              className="flex items-center"
              style={{
                margin: "0 auto",

                justifyContent: "space-between",
                gap: 8,
              }}
            >
              <Column
                className="is-row"
                style={{ alignItems: "center" }}
                isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
              >
                <div className="flex items-center is-fullwidth">
                  <div
                    style={{
                      minWidth: 0,
                    }}
                  >
                    <Text
                      size="xxl"
                      color="white"
                      weight="bold"
                      maxLength={this.props.mobile ? 50 : 250}
                    >
                      {this.props.store.ui.currentSong?.name}
                    </Text>
                    <Text
                      size="xl"
                      color="special"
                      weight="bold"
                      maxLength={this.props.mobile ? 50 : 250}
                    >
                      {this.props.store.ui.currentSong?.singer?.name}
                    </Text>
                  </div>
                </div>
              </Column>

              <Column
                className="flex"
                style={{
                  flexDirection: "column",
                  width: "100%",
                  margin: "0 auto",
                }}
                isSize={{ mobile: 12, tablet: 12, desktop: 12 }}
              >
                <div className="flex">
                  <Progress
                    value={
                      (this.props.store.ui.currentProgress /
                        this.props.store.ui.durationSecs) *
                      100
                    }
                    max={this.props.store.ui.durationSecs}
                    onChange={this.handleSong}
                    update={() => {
                      this.props.store.getPermission(15, 15, () => {
                        this.updateSong();
                      });
                    }}
                    className="mx-0"
                  />
                </div>
                <div
                  className="flex items-center pt-1"
                  style={{ justifyContent: "space-between" }}
                >
                  <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                    {this.props.store.ui.currentDuration == "NaN:NaN"
                      ? "00:00"
                      : this.props.store.ui.currentProgressTime}
                  </Text>
                  <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                    {this.props.store.ui.currentDuration == "NaN:NaN"
                      ? "00:00"
                      : this.props.store.ui.currentDuration}
                  </Text>
                </div>

                <div
                  className="flex items-center"
                  style={{ justifyContent: "space-between" }}
                >
                  <Button
                    iconKasimu={"ban"}
                    kind="link"
                    color={
                      this.getUserBan(this.props.store.ui.currentSong)
                        ? "primary"
                        : "basic"
                    }
                    size="xl"
                    id="ban"
                    onClick={() =>
                      this.props.store.getPermission(21, 21, () => {
                        this.handleBan();
                      })
                    }
                    space
                  />

                  <div className="flex" style={{ justifyContent: "center" }}>
                    <Button
                      icon={"step-backward"}
                      kind="link"
                      color="basic"
                      size="xl"
                      onClick={() => {
                        this.props.store.getPermission(14, 14, () => {
                          this.handlePrevClick();
                        });
                      }}
                      id="prev"
                      className="mx-1"
                    />

                    <Button
                      icon={this.props.store.ui.isPlaying ? "pause" : "play"}
                      color="basic"
                      size="xl"
                      onClick={(e) =>
                        this.props.store.ui.playAutoError
                          ? this.handleTogglePlay(e)
                          : this.props.store.getPermission(13, 13, () => {
                              this.handleTogglePlay(e);
                            })
                      }
                      id="play-button"
                      borderWidth={4}
                      className="mx-1"
                      style={{ borderRadius: "100%", padding: "20px" }}
                    />

                    <Button
                      icon={"step-forward"}
                      kind="link"
                      color="basic"
                      size="xl"
                      onClick={() => {
                        this.props.store.getPermission(14, 14, () => {
                          this.handleNextClick();
                        });
                      }}
                      id="next"
                      className="mx-1"
                    />
                  </div>

                  <Button
                    iconKasimu={
                      this.getUserFav(this.props.store.ui.currentSong)
                        ? "heart-solid"
                        : "heart"
                    }
                    kind="link"
                    color={
                      this.getUserFav(this.props.store.ui.currentSong)
                        ? "primary"
                        : "basic"
                    }
                    size="xl"
                    id="heart"
                    onClick={() =>
                      this.props.store.getPermission(20, 20, () => {
                        this.handleFav();
                      })
                    }
                    space
                  />
                </div>
              </Column>
            </Columns>
          </div>
          <div className="offline-status">{"offline"}</div>
        </>
      );
    }

    return (
      <>
        <div
          key={
            kasimu
              ? `${this.props.store.ui.currentCategoryPlaylist}-${this.props.store.ui.currentSong}`
              : `${this.props.store.ui.currentSong}`
          }
          className="py-2 px-2 is-v-centered is-centered"
          style={this.props.mobile ? mobileStyle : desktopStyle}
        >
          <Columns
            className="flex items-center"
            style={{
              margin: "0 auto",
              justifyContent: "space-between",
            }}
          >
            <Column
              className="is-row"
              style={{ alignItems: "center" }}
              isSize={{ mobile: 3, tablet: 3, desktop: 3 }}
            >
              <div className="flex items-center is-fullwidth">
                <Img
                  height="70px"
                  width="70px"
                  src={
                    kasimu
                      ? this.props.store.replaceBucketUrl(this.props.store.ui.currentCategoryPlaylist.icon_path)
                      : this.props.store.ui.currentCategoryPlaylist.static ===
                        1
                      ? this.props.store.ui._iconMyplaylists2
                      : this.props.store.ui._iconMyplaylists
                  }
                  style={{
                    borderRadius: "4px",
                    boxShadow: "0 0 10px rgba(0,0,0,.3)",
                  }}
                />

                <div
                  style={{
                    margin: `0 ${this.props.mobile ? "10px" : "20px"}`,
                    minWidth: 0,
                  }}
                >
                  <Text
                    size="lg"
                    color="white"
                    maxLength={this.props.mobile ? 50 : 250}
                  >
                    {this.props.store.ui.currentSong?.name}
                  </Text>
                  <Text
                    size="md"
                    color="special"
                    maxLength={this.props.mobile ? 50 : 250}
                  >
                    {this.props.store.ui.currentSong?.singer?.name}
                  </Text>
                  <Text
                    weight="regular"
                    size="sm"
                    color="special"
                    maxLength={this.props.mobile ? 35 : 250}
                  >
                    {this.props.store.ui.currentCategoryPlaylist?.name ||
                      this.props.store.ui.currentCategoryPlaylist?.alias}
                  </Text>
                </div>

                <Button
                  iconKasimu={
                    this.getUserFav(this.props.store.ui.currentSong)
                      ? "heart-solid"
                      : "heart"
                  }
                  kind="link"
                  color={
                    this.getUserFav(this.props.store.ui.currentSong)
                      ? "primary"
                      : "basic"
                  }
                  size="lg"
                  id="heart"
                  className="ml-1"
                  onClick={() =>
                    this.props.store.getPermission(20, 20, () => {
                      this.handleFav();
                    })
                  }
                />
              </div>
            </Column>

            <Column
              className="flex"
              style={{
                flexDirection: "column",
                width: "100%",
                maxWidth: 722,
                margin: "0 auto",
              }}
              isSize={{ mobile: 6, tablet: 6, desktop: 6 }}
            >
              <div className="flex" style={{ justifyContent: "center" }}>
                <Button
                  icon={"step-backward"}
                  kind="link"
                  color="basic"
                  size="lg"
                  onClick={(e) =>
                    this.props.store.getPermission(14, 14, () => {
                      this.handlePrevClick(e);
                    })
                  }
                  id="prev"
                  className="mx-1"
                />

                <Button
                  icon={this.props.store.ui.isPlaying ? "pause" : "play"}
                  kind="outline"
                  color="basic"
                  size="lg"
                  onClick={(e) =>
                    this.props.store.ui.playAutoError
                      ? this.handleTogglePlay(e)
                      : this.props.store.getPermission(13, 13, () => {
                          this.handleTogglePlay(e);
                        })
                  }
                  id="play-button"
                  borderWidth={4}
                  className="mx-1"
                />

                <Button
                  icon={"step-forward"}
                  kind="link"
                  color="basic"
                  size="lg"
                  onClick={(e) =>
                    this.props.store.getPermission(14, 14, () => {
                      this.handleNextClick(e);
                    })
                  }
                  id="next"
                  className="mx-1"
                />
              </div>
              <div className="flex items-center pt-1">
                <div style={{ width: 40 }}>
                  <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                    {this.props.store.ui.currentDuration == "NaN:NaN"
                      ? "00:00"
                      : this.props.store.ui.currentProgressTime}
                  </Text>
                </div>

                <Progress
                  value={
                    (this.props.store.ui.currentProgress /
                      this.props.store.ui.durationSecs) *
                    100
                  }
                  max={this.props.store.ui.durationSecs}
                  onChange={this.handleSong}
                  update={() => {
                    this.props.store.getPermission(15, 15, () => {
                      this.updateSong();
                    });
                  }}
                />

                <div style={{ width: 40 }}>
                  <Text size="md" maxLength={this.props.mobile ? 50 : 250}>
                    {this.props.store.ui.currentDuration == "NaN:NaN"
                      ? "00:00"
                      : this.props.store.ui.currentDuration}
                  </Text>
                </div>
              </div>
            </Column>

            <Column
              isSize={{ mobile: 3, tablet: 3, desktop: 3 }}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                iconKasimu={"playlist-solid"}
                kind="link"
                color="basic"
                size="lg"
                onClick={this.handleReproducingViewTrue}
                id="reproduction"
              />
              {this.props.store.ui.isMuted ? (
                <Text
                  size="lg"
                  className="ml-1"
                  weight="medium"
                  color="primary"
                >
                  {this.props.store.language.label89}
                </Text>
              ) : (
                <Text size="md" className="ml-1">
                  {"vol: " + Math.floor(this.props.store.ui.volume * 100) + "%"}
                </Text>
              )}
            </Column>
          </Columns>
        </div>
        <div className="offline-status">{"offline"}</div>
      </>
    );
  }

  render() {
    if (!this.props.store.ui.currentSong) {
      return null;
    }

    const mobileStyle = {
      //height: "86px",
      width: "auto",
      borderRadius: "0px",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      backdropFilter: "blur(86.985px)",
      position: "sticky",
      bottom: "5px",
      left: "0px",
      zIndex: 1,
      borderRadius: "6px",
      margin: 5,
    };

    const desktopStyle = {
      //height: "95px",
      width: "100%",
      borderRadius: "0px",
      backgroundColor: "rgba(0, 0, 0, 0.7)",
      backdropFilter: "blur(86.985px)",
      position: "sticky",
      bottom: "0px",
      left: "0px",
      zIndex: 1,
    };

    return (
      <>
        {/* {this.props.store.ui.banderClick
          ? this.props.store.ui.ownerClick == 1
            ? this.renderKasimu(mobileStyle, desktopStyle)
            : this.renderMyplaylist(mobileStyle, desktopStyle)
          : this.props.store.ui.currentOwner == 1
          ? this.renderKasimu(mobileStyle, desktopStyle)
          : this.renderMyplaylist(mobileStyle, desktopStyle)} */}

        {this.props.store.ui.banderClick
          ? this.renderKasimu(
              mobileStyle,
              desktopStyle,
              this.props.store.ui.ownerClick == 1
            )
          : this.renderKasimu(
              mobileStyle,
              desktopStyle,
              this.props.store.ui.currentOwner == 1
            )}
        {this.state.reproducingView && (
          <ReproducingView close={this.hideReproducingView} screen />
        )}
        {/* return this.props.store.ui.banderClick
      ? this.renderHeader(this.props.store.ui.ownerClick, category)
      : this.renderHeader(this.props.store.ui.currentOwner, category); */}

        {this.props.store.viewMobile && (
          <>
            <Tooltip id="prev" place="top" style={{ zIndex: 3 }}>
              <span>{this.props.store.language.label79}</span>
            </Tooltip>
            <Tooltip id="play-button" place="top" style={{ zIndex: 3 }}>
              <span>
                {this.props.store.ui.isPlaying
                  ? this.props.store.language.label81
                  : this.props.store.language.label80}
              </span>
            </Tooltip>
            <Tooltip id="next" place="top" style={{ zIndex: 3 }}>
              <span>{this.props.store.language.label82}</span>
            </Tooltip>

            <Tooltip id="reproduction" place="top" style={{ zIndex: 3 }}>
              <span>{this.props.store.language.label83}</span>
            </Tooltip>
          </>
        )}
      </>
    );
  }
}

AudioPlayer.protoTypes = {
  className: PropTypes.string,
  mobile: PropTypes.bool,
};

AudioPlayer.defaultProps = {
  className: null,
  mobile: false,
};

export default withStore(withRouter(AudioPlayer));
