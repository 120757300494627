import { Store } from "ks_storybook";
import { PlaylistPlan } from "../models";

class PlaylistsPlansStore extends Store {
  model = PlaylistPlan;

  getPlaylistPlansCurrentDay(day) {
    return this.search(
      { per_page: 10000 },
      "actions_playlist_plans",
      true,
      `/actions_playlist_plans/get_playlist_plans_current_day/${day}`
    );
  }
}

export default PlaylistsPlansStore;
