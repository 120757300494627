import { Model } from "ks_storybook";

class Playlist extends Model {
  urlRoot = "/playlists";

  build() {
    return this._store.search(
      {},
      "build_list",
      true,
      `/playlists/${this.id}/build_list`
    );
  }
}

export default Playlist;
