import React, { Component } from "react";
import { observer } from "mobx-react";
import {
  Text,
  Panel,
  Button,
  Toggle,
  Field,
  Loader,
  Paragraph,
  Columns,
  Column,
} from "ks_storybook";
import withStore from "../../../../hocs/withStore";
import { PlaylistPlanRow } from "../../../../Components/Plans";
import { EmptyContent } from "../../../../Components/Common";

@observer
class PlaylistsPlanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: this.props.store.loggedInUser.id,
      showModal: false,
      showDeletedAllModal: false,
      weekDay: 1,
      playlistsPlans: this.getPlaylistsPlans(this.props.store.loggedInUser.id),
      hibernationPlans: this.getHibernationPlans(this.props.store.loggedInUser.id),
      datesSelected: [],
      plans: [],
    };
    this.plans = [];
    this.weekdays = this.props.store.language.label61.split(",");
  }

  componentDidMount() {
    this.props.store.ui.pushHistory("/player/playlists_plans");
    this.props.store.menuTitle = this.props.store.language.label47;
  }

  componentDidUpdate = (prevProps, prevState) => {
    const syncPlaylistPlanner = prevProps.store.ui.syncPlaylistPlanner;

    if (syncPlaylistPlanner) {
      this.setState({
        playlistsPlans: this.getPlaylistsPlans(
          this.props.store.loggedInUser.id
        ),
        hibernationPlans: this.getHibernationPlans(
          this.props.store.loggedInUser.id
        ),
      });
      this.props.store.ui.syncPlaylistPlanner = false;
      this.props.store.renderHeaderEnd = null;
    }
  };

  componentWillUnmount() {
    this.props.store.handleHeader(null, ["renderHeaderEnd"]);
    this.props.store.menuTitle = null;
  }

  getPPlans = (plans) => {
    return plans?.toArray().map((plan) => ({
      id: plan.id,
      day: plan.day,
      start_hour: plan.start_hour,
      finish_hour: plan.finish_hour,
      categories_playlists: plan.categories_playlists,
      playlist_type: plan.playlist_type,
      playlist_planner_on: plan.playlist_planner_on,
      owner: plan.owner,
      type: 1,
    }));
  };

  getHPlans = (plans) => {
    return plans?.toArray().map((plan) => ({
      id: plan.id,
      day: plan.day,
      start_hour: plan.start_hour,
      finish_hour: plan.finish_hour,
      categories_playlists: null,
      playlist_type: null,
      playlist_planner_on: plan.playlist_planner_on,
      owner: null,
      type: 2,
    }));
  };

  validLength = (day) => {
    let arr = this.plans.filter((plan) => plan.day === day);
    if (arr.length === 0) return true;
    return false;
  };

  getPlaylistsPlans = (value) => {
    if (!this.props.store.failsafeMode) {
      return this.props.store.playlistsPlansUser.getPlaylistPlansUser(
        value,
        this.props.store.lang
      );
    } else {
      return null;
    }
  };

  getHibernationPlans = (value) => {
    if (!this.props.store.failsafeMode) {
      return this.props.store.userHibernation.getHibernationPlansUser(value);
    } else {
      return null;
    }
  };

  renderFrame = (plans, weekDay) => {
    return (
      <React.Fragment>
        <Panel
          color="grayDark"
          className="mt-1"
          padding={this.props.store.viewMobile ? "10px 25px" : "5px 15px"}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                name="arrow-down"
                kind="link"
                color="basic"
                size={this.props.store.viewMobile ? "xl" : "lg"}
                icon={
                  this.state.datesSelected.some((e) => e == weekDay)
                    ? "chevron-down"
                    : "chevron-up"
                }
                space
                onClick={() => {
                  let valid = this.state.datesSelected.indexOf(weekDay);

                  if (valid >= 0) {
                    this.setState({
                      datesSelected: this.state.datesSelected.filter(
                        (i) => i !== weekDay
                      ),
                    });
                  } else {
                    this.setState({
                      datesSelected: [...this.state.datesSelected, weekDay],
                    });
                  }
                }}
              />
              <div className="ml-2">
                <Text
                  weight="strong"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                >
                  {this.getTitleForWeekDay(weekDay)}
                </Text>
              </div>
            </div>
          </div>
        </Panel>
        {this.state.datesSelected.some((e) => e == weekDay) ? null : (
          <>
            {plans.length > 0
              ? plans
                  .filter((p) => p.day === weekDay)
                  .map((p) => (
                    <PlaylistPlanRow plan={p} onDeleted={this.handleReload} />
                  ))
              : null}
          </>
        )}
      </React.Fragment>
    );
  };

  getPlans = () => {
    const { playlistsPlans, hibernationPlans } = this.state;

    const pplans = this.getPPlans(playlistsPlans);

    const hplans = this.getHPlans(hibernationPlans);

    const arrayPlans = pplans?.concat(hplans);
    arrayPlans?.sort(function (a, b) {
      return parseInt(a.start_hour) - parseInt(b.start_hour);
    });

    return arrayPlans || [];
  };

  renderHeaderOk = () => {
    const options = ["1", "2", "3", "4", "5", "6", "7"];

    const plans = this.getPlans();

    if (plans.length > 0 && !this.props.store.renderHeaderEnd)
      this.props.store.handleHeader(
        <Button
          size="lg"
          iconSize="28px"
          iconKasimu="turn-off"
          color={plans[0].playlist_planner_on ? "confirm" : "remove"}
          kind="link"
        />,
        ["renderHeaderEnd"]
      );

    return (
      <React.Fragment>
        {options.map((i) => this.renderFrame(plans, i))}
      </React.Fragment>
    );
  };

  renderHeader = () => {
    const { playlistsPlans } = this.state;
    if (!playlistsPlans) return this.renderEmptyContent();
    return !playlistsPlans.isOk() ? this.renderLoader() : this.renderHeaderOk();
  };

  getTitleForWeekDay = (weekDay) => {
    const titles = {
      1: this.weekdays[1],
      2: this.weekdays[2],
      3: this.weekdays[3],
      4: this.weekdays[4],
      5: this.weekdays[5],
      6: this.weekdays[6],
      7: this.weekdays[0],
    };
    return titles[weekDay];
  };

  renderEmptyContent = () => {
    return (
      <React.Fragment>
        <EmptyContent />
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label92}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  render() {
    const plans = this.getPlans();

    return (
      <React.Fragment>
        <Columns isCentered isGapless>
          <Column isSize={{ mobile: 12, desktop: 12, tablet: 12 }}>
            {this.props.store.viewMobile && (
              <div
                className="flex items-center"
                style={{
                  justifyContent: "space-between",
                  paddingTop: !this.props.store.viewMobile && "16px",
                }}
              >
                <div className="flex items-center">
                  <Text
                    lineBreak
                    size={this.props.store.viewMobile ? "lg" : "md"}
                    lead
                    weight="black"
                    className={this.props.store.viewMobile ? "mr-2" : "mr-1"}
                  >
                    {this.props.store.language.label47}
                  </Text>

                  {plans.length > 0 && (
                    <Text
                      size={this.props.store.viewMobile ? "xl" : "lg"}
                      weight="medium"
                      color="special"
                      className={
                        plans[0].playlist_planner_on ? "success" : "danger"
                      }
                    >
                      {plans[0].playlist_planner_on
                        ? this.props.store.language.label53
                        : this.props.store.language.label54}
                    </Text>
                  )}
                </div>

                <Button
                  className="px-2"
                  onClick={() =>
                    this.props.store.getPermission(8, 8, () => {
                      this.props.store.goToPanel();
                    })}
                  size="lg"
                  color="basic"
                  kind="outline"
                  id="panel"
                >
                  {this.props.store.language.label51}
                </Button>
              </div>
            )}

            {/* {this.plans.length > 0 &&
              this.props.store.renderHeaderEnd(
                <Text
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  weight="medium"
                  color="special"
                  className={
                    this.plans[0].playlist_planner_on ? "success" : "danger"
                  }
                >
                  {this.plans[0].playlist_planner_on
                    ? this.props.store.language.label53
                    : this.props.store.language.label54}
                </Text>
              )} */}

            {/* {plans.length > 0 &&
              this.props.store.handleHeaderEnd(
                <Button
                  size="lg"
                  iconSize="28px"
                  iconKasimu="turn-off"
                  color={plans[0].playlist_planner_on ? "confirm" : "remove"}
                  kind="link"
                />
              )} */}

            <div
              className={this.props.store.viewMobile ? "my-3" : "my-1"}
            ></div>

            {this.renderHeader()}
          </Column>
        </Columns>
      </React.Fragment>
    );
  }
}

export default withStore(PlaylistsPlanner);
