const fr_FR = {
  languages: {
    1: "Espagnol",
    2: "Anglais",
    3: "Français",
    4: "Portugais",
    5: "Estonien",
    6: "Allemand",
    7: "Italien"
  },
  label1: "Idiomes",
  label2: "Changer de langue",
  label3: "Vous pouvez choisir et modifier la langue que vous souhaitez.",
  label4: "Sans descriptif",
  label5: "Chargement de l´application...",
  label6: `Votre abonnement a expiré. Allez sur `,
  label7: "Ce compte est connecté depuis un autre emplacement.",
  label8: `Erreur, nom d'utilisateur ou mot de passe incorrect.`,
  label9: `Les comptes d'administrateur n'ont pas accès au lecteur Web!`,
  label10: "Utilisateur suspendu! Contactez ",
  label11: `Veuillez fournir un nom d'utilisateur et un mot de passe.`,
  label12: `Utilisateur suspendu par l'administrateur`,
  label13: "Erreur de connexion au serveur, vérifiez votre connexion.",
  label14: `Utilisateur`,
  label15: `(N'utilisez pas de courrier électronique)`,
  label16: `Mot de passe`,
  label17: `Commencer la session`,
  label18: `Catégorie`,
  label19: `Mon playlists`,
  label20: `Revenir`,
  label21: `Connectez-vous au panneau de contrôle pour créer des listes de lecture.`,
  label22: `Aucune donnée pour le moment`,
  label23: `Erreur, le code PIN doit être composé de 4 chiffres.`,
  label24: `Erreur, mauvais code PIN.`,
  label25: `Joueur verrouillé`,
  label26: `Veuillez saisir votre code PIN pour le déverrouiller.`,
  label27: `Obligatoire`,
  label28: `Fermer la session`,
  label29: `Choisissez par type local`,
  label30: `Choisissez par style de musique`,
  label31: `Choisissez selon d´autres critères`,
  label32: `Joueur en hibernation`,
  label33: `Veuillez entrer votre code PIN pour sortir de l'hibernation.`,
  label34: `Déverouiller`,
  label35: `Annuler`,
  label36: `Début`,
  label37: `Jouant`,
  label38: `Chansons`,
  label39: `Accueil`,
  label40: `Rechercher Playlists`,
  label41: `Bibliothèque musicale`,
  label42: `Artiste`,
  label43: `Rechercher le nom de la chanson`,
  label44: `Rechercher l'artiste`,
  label45: `Résultats`,
  label46: `Ajouter à la suite de la lecture`,
  label47: `Planificateur de playlists`,
  label48: `Personnalisation`,
  label49: `Planificateur d'annonces`,
  label50: `Annonces`,
  label51: `Panneau de commande`,
  label52: `A propos de`,
  label53: "Activé",
  label54: "Désactivé",
  label55: `Version de la mise à jour de la base de données`,
  label56: `Application musicale`,
  label57: `Cette application a été développée par`,
  label58: `Cette application a été développée pour`,
  label59: "Janvier,Février,Mars,Avril,Mai,Juin,Juillet,Août,Septembre,Octobre,Novembre,Décembre",
  label60: "Jan,Feb,Mar,Abr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec",
  label61: "Dimanche,Lundi,Mardi,Mercredi,Jeudi,Vendredi,Samedi",
  label62: "Dim,Lun,Mar,Mer,Jeu,Ven,Sam",
  label63: "Di,Lu,Ma,Me,Ju,Ve,Sa",
  label64: "Connecté",
  label65: "Débranché",
  label66: `Voulez-vous vous déconnecter?`,
  label67: `Oui`,
  label68: "Non",
  label69: `Déplacer comme prochain à jouer`,
  label70: `Marquer comme prochain à jouer`,
  label71: `Supprimer une chanson de la playlist`,
  label72: `Recherche...`,
  label73: `Chercher...`,
  label74: `Ouvrir`,
  label75: `Fermer`,
  label76: `Plus d'options`,
  label77: `Favoris`,
  label78: `Bloc`,
  label79: `Précédent`,
  label80: `Play`,
  label81: `Pause`,
  label82: `Suivant`,
  label83: `Playlistt en cours de lecture`,
  label84: `Aller dans le panneau de commande web`,
  label85: `Avancer`,
  label86: `Agrandir la bibliothèque`,
  label87: `Réduire la bibliothèque`,
  label88: `Agrandir la bibliothèque`,
  label89: `Sourdine`,
  label90: `Vous souhaitez marquer cette chanson comme spam?`,
  label91: `il sera à nouveau entendu comme d'habitude.`,
  label92: `Chargement des données...`,
  label93: "Réglage",
  label94: "Compte",
  label95: "Service contractuel",
  label96: "Préférences",
  label97: "Volume",
  label98: "Vous pouvez modifier ou désactiver le volume à partir du panneau de configuration Web.",
  label99: "Planificateurs et annonces",
  label100: "Programmez les listes de lecture que vous aimez le plus à I'heure que vous voulez, vous pouvez le faire à partir du panneau de contrôle Web.",
  label101: "Créez et attribuez des directives publicitaires à I'heure que vous souhaitez, vous pouvez le faire à partir du panneau de contrôle Web.",
  label102: "Téléchargez vos publicitiés, vous pouvez le faire à partir du panneau de contrôle Web.",
  label103: "Information",
  label104: "Version",
  label105: "Développeur",
  label106: "Site Web",
  label107: "Les politiques de confidentialité",
  label108: "Important pour vous et pour nous.",
  label109: "Moyen",
  label110: "Comptez sur notre aide.",
  label111: "Autre",
  label112: "Fermer la session",
  label113: "Vous étes connecté en tant que",
  label114: "JOUER DE",
  label115: "J'aime",
  label116: "Cache cette chanson",
  label117: "Déplacez-vous comme prochain pour jouer",
  label118: "Marquer comme prochain à jouer",
  label119: "Supprimer de la liste actuelle",
  label120: "Ajouter comme prochain pour jouer",
  label121: "Annonce",
  label122: "Enregistrer les modifications",
  label123: "Enregistrement automatique (activé)",
  label124: "Sauvegarde automatique (désactivée)",
  label125: "Sauvegarde automatique activée",
  label126: "Sauvegarde automatique désactivée",
  label127: "La chanson a été ajoutée avec succès à la playlist",
  label128: "Impossible d'ajouter la chanson à la playlist",
  label129: "La playlist a été créée avec succès",
  label130: "Le nom de la playlist existe déjà, veuillez entrer un autre nom",
  label131: "Vous devez saisir un nom pour la nouvelle playlist",
  label132: "Les chansons ont été ajoutées avec succès à la playlist",
  label133: "Impossible d'ajouter des chansons à la playlist",
  label134: "Vous devez sélectionner une playlist",
  label135: "L'utilisateur ne dispose pas d'une configuration de base de limites, contacter le support",
  label136: "La chanson a été marquée comme favorite, elle sera toujours programmée",
  label137: "Le morceau n'a pas été marqué comme favori, il n'est plus toujours programmé",
  label138: "La chanson a été ajoutée à la playlist en cours de lecture",
  label139: "Sélectionnez une playlist",
  label140: "Listes de lecture manuelles",
  label141: "Nom de la nouvelle playlist",
  label142: "Ajouter",
  label143: "Enregistrer",
  label144: "Ajouter à une playlist existante",
  label145: "Ajouter à une nouvelle playlist",
  label146: "Une connexion Internet est requise pour effectuer cette action, vérifiez votre connexion",
  label147: "Ajouter des chansons à une playlist existante",
  label148: "Toutes les chansons en cours de lecture seront ajoutées à une playlist existante",
  label149: "Ajouter des chansons à une nouvelle playlist",
  label150: "Toutes les chansons en cours de lecture seront ajoutées à une nouvelle playlist",
  label151: "Vous n'avez aucune playlist créée manuellement par l'utilisateur",
  lavel152: "Enregistré avec succès",
  label153: "Ajout...",
  label154: "Enregistrement...",
  label155: "Impossible d'enregistrer",
  label156: "Vous n'avez pas la permission d'accéder à mes playlists",
  label157: "Vous n'avez pas la permission d'accéder à mes playlists préférées",
  label158: "Vous n'avez pas la permission d'accéder aux catégories et playlists",
  label159: "Vous n'êtes pas autorisé à rechercher tout le contenu musical uniquement dans les favoris",
  label160: "Vous n'avez pas la permission d'accéder à la bibliothèque musicale",
  label161: "Vous n'êtes pas autorisé à accéder aux paramètres",
  label162: "Vous n'êtes pas autorisé à vous déconnecter",
  label163: "Vous n'avez pas la permission d'accéder au panneau de contrôle",
  label164: "Vous n'avez pas la permission d'accéder au planificateur de playlist",
  label165: "Vous n'êtes pas autorisé à accéder au planificateur de publicités",
  label166: "Vous n'êtes pas autorisé à accéder aux annonces",
  label167: "Vous n'avez pas la permission de quitter l'application",
  label168: "Vous n'avez pas la permission de lire/mettre en pause la lecture",
  label169: "Vous n'avez pas la permission de changer la chanson en cours de lecture",
  label170: "Vous n'êtes pas autorisé à modifier la barre de progression de la chanson en cours de lecture",
  label171: "Vous n'avez pas la permission d'accéder à la vue du mini-lecteur",
  label172: "Vous n'êtes pas autorisé à déplacer des chansons dans la playlist en cours de lecture",
  label173: "Vous n'êtes pas autorisé à enregistrer les modifications apportées à une liste de lecture en cours de lecture manuelle",
  label174: "Vous n'êtes pas autorisé à ajouter des chansons à une liste de lecture manuelle nouvelle ou existante",
  label175: "Vous n'êtes pas autorisé à marquer ou à décocher des chansons comme favorites",
  label176: "Vous n'êtes pas autorisé à masquer des chansons afin qu'elles ne puissent pas être jouées",
  label177: "Vous n'êtes pas autorisé à supprimer des chansons dans la playlist en cours de lecture",
  label178: "Playlists préférées",
  label179: "Toutes vos playlists préférées et celles de votre administrateur",
  label180: "Accès restreint par code PIN",
  label181: "Favoris",
  label182: "Cette playlist appartient à l'administrateur et ne peut pas être modifiée.",
  label183: "Tous les jours",
  label184: "Du lundi au vendredi",
  label185: "Samedi et dimanche",
  label186: "Jour exact",
  label187: "Heure exacte",
  label188: "Intervalle",
  label189: "Chacun",
  label190: "Fréquence",
  label191: "chansons",
  label192: "Ad limit per batch",
  label193: "Début de la ligne directrice",
  label194: "Fin de la ligne directrice",
  label195: "De l'Administrateur",
  label196: "De l'Utilisateur",
  label197: `(Administrateur) -`,
  label198: `(Utilisateur) -`,
  label199: `Hibernation`,
  label200: "Abonnement invalide, contacter le fournisseur de services"
};

export default fr_FR;
