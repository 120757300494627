import React, { Component } from "react";
import PropTypes from "prop-types";
import { ClickableImg, RectSkeleton, Text, Column, Img } from "ks_storybook";
import withStore from "../../hocs/withStore";

class CategoryListItem extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { category } = this.props;
    this.props.onClick && this.props.onClick(category);
  }

  renderSkelenton() {
    return (
      <Column isSize={{ mobile: 6, desktop: 3, tablet: 3 }}>
        <RectSkeleton width="100%" height="180px" />
      </Column>
    );
  }

  getColor(value) {
    if (value.includes("c-1.png")) return "#8A084B";
    else if (value.includes("c-2.png")) return "#01A9DB";
    else if (value.includes("c-3.png")) return "#8000FF";
    else if (value.includes("c-4.png")) return "#FF0040";
    else if (value.includes("c-5.png")) return "#688A08";
    else if (value.includes("c-6.png")) return "#BE81F7";
    else if (value.includes("c-7.png")) return "#848484";
    else if (value.includes("c-8.png")) return "#00FF00";
    else if (value.includes("c-9.png")) return "#0101DF";
    else if (value.includes("c-10.png")) return "#B45F04";
    else if (value.includes("c-11.png")) return "#8181F7";
    else if (value.includes("c-12.png")) return "#088A85";
    else if (value.includes("c-13.png")) return "#5882FA";
    else if (value.includes("c-14.png")) return "#5E610B";
    else if (value.includes("c-15.png")) return "#2F0B3A";
    else if (value.includes("c-16.png")) return "#AEB404";
    else if (value.includes("c-17.png")) return "#0B610B";
    else if (value.includes("c-18.png")) return "#0B173B";
    else if (value.includes("c-19.png")) return "#088A08";
    else if (value.includes("c-20.png")) return "#FF8000";
    else if (value.includes("c-21.png")) return "#2E2E2E";
    else if (value.includes("c-22.png")) return "#DF0101";

    return "#121212";
  }

  render() {
    const { category, main, idx, id } = this.props;

    if (category.id != 5000 && category.id != 23) {
      if (category.isEmpty()) {
        return this.renderSkelenton();
      }
    }

    return (
      <Column isSize={{ mobile: 6, desktop: 2, tablet: 3 }}>
        <div onClick={this.handleClick} style={{ position: "relative" }}>
          {main ? (
            <ClickableImg
              src={this.props.store.replaceBucketUrl(category.icon_path)}
              width="100%"
              height="180px"
              title={category?.name.replace(" | ", " ")}
              style={{
                background: "red",
              }}
            />
          ) : (
            <div
              style={{
                background: this.getColor(this.props.store.replaceBucketUrl(category.icon_path)),
                borderRadius: "16px",
                width: "100%",
                // height: "180px",
                aspectRatio: "1 / 1",
                position: "relative",
                overflow: "hidden",
                textAlign: "start",
                cursor: "pointer",
              }}
            >
              <div
                style={{
                  width: 100,
                  position: "absolute",
                  bottom: "-5px",
                  right: "-10px",
                  transform: "rotate(20deg)",
                }}
              >
                <Img
                  src={this.props.store.replaceBucketUrl(category.icon_path)}
                  width="100%"
                  height="180px"
                  // title={category?.name.replace(" | ", " ")}
                  // textBg={idx % 2 === 0 && "black"}
                  style={{ borderRadius: 8 }}
                />
              </div>
              <div style={{ padding: "16px", position: "relative" }}>
                <Text color="white" weight="strong" size="md" lineBreak lead>
                  {category?.name.replace(" | ", " ")}
                </Text>
              </div>
            </div>
          )}

          {/* <br />
          {!main && (
            <Text size="xl" weight="strong">
              {category.name}
            </Text>
          )} */}
        </div>
      </Column>
    );
  }
}

CategoryListItem.propTypes = {
  category: PropTypes.object,
  onClick: PropTypes.func,
};

CategoryListItem.defaultProps = {
  category: null,
  onClick: null,
};

export default withStore(CategoryListItem);
