import { Store } from "ks_storybook";
import { UserPlaylist } from "../models";

class UserPlaylistStore extends Store {
  model = UserPlaylist;

  fetchAll() {
    return this.search(
      {},
      "users_playlists_all",
      true,
      "/users_playlist/get_for_subuser"
    );
  }

  getPlaylistFather(playlist_id) {
    return this.search(
      { playlist_id: playlist_id },
      "playlist_father",
      true,
      `/users_playlist/get_playlist`
    );
  }

  getMyManualPlatlists() {
    return this.search(
      {},
      "get_my_manual_playlists",
      true,
      `/users_playlist/get_my_manual_playlists`
    );
  }
}

export default UserPlaylistStore;
