import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  MobileLayout,
  MobileLayoutHeader,
  MobileLayoutHeaderEnd,
  MobileLayoutHeaderStart,
  MobileLayoutContent,
  MobileLayoutHeaderContent,
  Button,
  Text,
  Dropdown,
  DropdownSideDrawer,
  LayoutSide,
  Sidebar,
  MobileLayoutMenu,
} from "ks_storybook";
import { SignIn } from "../Session";
import {
  PlayerMenu,
  PlayerPage,
  PlayerRouter,
  PlayerBottomMenu,
} from "../Player";
import LoggedUserDropdown from "./components/LoggedUserDropdown";
import { AudioPlayer } from "../../Components/AudioPlayer";
import { LockModal, PermissionModal } from "../../Components/Preference";
import MobileHeader from "./components/MobileHeader";
import Toast from "./components/Toast";

// remove this later, when all routes exist -----------
//const FakeContent = withRouter(props => <div>{props.location.pathname}</div>);

@observer
class MobileAppRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      container: null,
      navbar: false,
    };
    this.container = React.createRef();
  }

  componentDidMount() {
    this.myInterval = setInterval(() => {
      this.updateConnection();
      this.props.store.ui.loadHibernationPlansDay();
      this.props.store.ui.loadPlansDay();
      this.props.store.ui.loadAdsPlansDay();
    }, this.props.store.refreshTime);
    this.plansAction = setInterval(() => {
      if (navigator.onLine) {
        document.body.classList.remove("offline");
      } else {
        document.body.classList.add("offline");
      }
      this.props.store.ui.setLock();
      // hibernation
      this.props.store.ui.hibernationShuttle();
      if (this.props.store.ui.getIsHibernation()) return;
      if (this.props.store.ui.getPlaylistPlannerOn()) {
        this.props.store.ui.launchActionPlaylistPlans();
      }
      if (this.props.store.ui.getAdsPlannerOn()) {
        this.props.store.ui.getNextExactAdPathOfBatches();
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
    clearInterval(this.plansAction);
  };

  updateConnection = () => {
    this.props.store.updateConnection(this.props.store.ui.getConnectionType(),this.props.store.ui.isPlaying)
    .then((result) => {
      if (result.results.answer_code == 2) {
        if(this.props.store.checkUserSyncUp(result.results.version_sync_up)) {
          this.props.store.ui.loadUserSyncUp();
        }
        if (this.props.store.checkCatalogChange(result.results)) {
          this.handleSignOut();
        }
      } else {
        this.handleSignOut();
      }
    });
  };

  handleSignOut = () => {
    this.props.store.signOut();
  };

  handleClick = () => {
    if (this.props.store.toggleMenu) {
      this.props.store.toggleMenu = false;
    } else {
      this.props.store.toggleMenu = true;
    }
  };

  onScroll = (event) => {
    const scrollTop = event.currentTarget.scrollTop;
    const showHeader = document.getElementById("show-header");
    const showCategory = document.getElementById("show-category");

    if (showCategory && showHeader) {
      if (scrollTop > 220) {
        showHeader.style.background = "black";
        showCategory.style.display = "block";
      } else {
        if (scrollTop < 50) {
          showHeader.style.background = "transparent";
          showCategory.style.display = "none";
        }
      }
    }
  };

  showHeader = () => {
    // const { pathname } = this.props.history.location;
    const history =
      this.props.store.ui.histories[this.props.store.ui.historyPosition];
    if (
      history === "/player/home" ||
      history === "/player/categories" ||
      history === "/player/playlist" ||
      this.props.store.ui.isReproducing
    ) {
      return false;
    }

    return true;
  };

  getContainer = () => {
    return this.container.current;
  };

  render() {
    const path = this.props.match.path;
    const root = "/player/";

    // if (this.props.store.isLoggedIn || this.props.store.isLoggingIn) {
    if (this.props.store.getIsLoggedIn()) {
      const uiSettings = this.props.store.ui.settings;

      if (uiSettings.isEmpty()) return null;

      return (
        <React.Fragment>
          <div className="ks--layout">
            <MobileLayout
              style={{
                // background:
                //   "linear-gradient(180deg, #2700E9 -5.97%, #1A1419 30.82%)",
                minHeight: "100%",
              }}
            >
              {this.showHeader() && (
                <MobileLayoutHeader>
                  <MobileLayoutHeaderStart>
                    <MobileHeader type="MobileLayoutHeaderStart" />
                  </MobileLayoutHeaderStart>
                  <MobileLayoutHeaderContent>
                    <MobileHeader type="MobileLayoutHeaderContent" />
                  </MobileLayoutHeaderContent>
                  <MobileLayoutHeaderEnd>
                    <MobileHeader type="MobileLayoutHeaderEnd" />
                  </MobileLayoutHeaderEnd>
                </MobileLayoutHeader>
              )}
              <MobileLayoutContent
                style={{
                  minHeight: "100%",
                  background: "#1a1419",
                  overflow: "overlay",
                  height: "100%",
                  paddingBottom: this.showHeader() ? 8 * 26 : 8 * 16,
                }}
                ref={this.container}
                onScroll={this.onScroll}
              >
                <Switch>
                  {/* <Route exact path={`${path}/`} component={PlayerPage} />
                <Redirect to={`${path}/`} component={PlayerPage} /> */}
                  <Route
                    exact
                    path={`${path}${root}`}
                    component={PlayerRouter}
                  />
                  <PlayerRouter
                    defaultComponent={PlayerRouter}
                    container={this.getContainer}
                  />
                  <Redirect to={`${path}${root}`} component={PlayerRouter} />
                </Switch>
              </MobileLayoutContent>
              <MobileLayoutMenu>
                <AudioPlayer mobile />
                <Switch>
                  <Route
                    path={`${path}${root}`}
                    render={(props) => (
                      <PlayerBottomMenu
                        {...props}
                        handleToogle={this.handleClick}
                        className={"ks--mobile-layout-bottom"}
                      />
                    )}
                  />
                </Switch>
              </MobileLayoutMenu>
            </MobileLayout>
          </div>
          {(this.props.store.ui.unlock ||
            this.props.store.ui.isHibernating) && <LockModal />}
          <PermissionModal />
          <Toast />
        </React.Fragment>
      );
    }

    return (
      <Switch>
        <Redirect to={`/session/signIn`} component={SignIn} />
      </Switch>
    );
  }
}

export default withStore(withRouter(MobileAppRouter));
