import React, { Component } from "react";
// import { Columns, Column } from "bloomer";
import { Button, Panel, Columns, Column } from "ks_storybook";
import PropTypes from "prop-types";
import withStore from "../../../hocs/withStore";
import { createUltimatePagination } from "react-ultimate-pagination";

const Buttons = ({ value, isActive, disabled, onClick }) => {
  return isActive ? (
    <Button
      upper
      onClick={onClick}
      disabled={disabled}
      color="basic"
      size="sm"
      marginSeparator="10px"
    >
      {value}
    </Button>
  ) : (
    <Button
      kind="outline"
      onClick={onClick}
      disabled={disabled}
      color="basic"
      size="sm"
      marginSeparator="10px"
    >
      {value}
    </Button>
  );
};

function Ellipsis(props) {
  //   return (
  //     <Button
  //       kind="outline"
  //       onClick={props.onClick}
  //       disabled={props.disabled}
  //       marginSeparator="10px"
  //     >
  //       ...
  //     </Button>
  //   );
  return null;
}

function FirstPageLink(props) {
  //return <Button upper onClick={props.onClick} disabled={props.disabled}>Primera</Button>
  return null;
}

function PreviousPageLink(props) {
  return (
    <Button
      upper
      onClick={props.onClick}
      disabled={props.disabled}
      color="basic"
      icon="chevron-left"
      size="sm"
    ></Button>
  );
}

function NextPageLink(props) {
  return (
    <Button
      upper
      onClick={props.onClick}
      disabled={props.disabled}
      color="basic"
      icon="chevron-right"
      size="sm"
      marginSeparator="10px"
    ></Button>
  );
}

function LastPageLink(props) {
  //   return (
  //     <Button upper onClick={props.onClick} disabled={props.disabled}>
  //       Última
  //     </Button>
  //   );
  return null;
}

const PaginatedPage = createUltimatePagination({
  itemTypeToComponent: {
    PAGE: Buttons,
    ELLIPSIS: Ellipsis,
    FIRST_PAGE_LINK: FirstPageLink,
    PREVIOUS_PAGE_LINK: PreviousPageLink,
    NEXT_PAGE_LINK: NextPageLink,
    LAST_PAGE_LINK: LastPageLink,
  },
});

const styles = {
  centerPaginaton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

class Pagination extends Component {
  constructor(props) {
    super(props);
  }

  onPageChange = (page) => {
    const { onPageChangeClick } = this.props;
    onPageChangeClick && onPageChangeClick(page);
  };

  withpanel = () => {
    return (
      <>
        <Panel
          className="my-1"
          color="gray"
          padding={!this.props.store.viewMobile && "22px 19px"}
        >
          <Columns isCentered>
            <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
              <PaginatedPage
                className="is-fullwidth"
                style={styles.centerPaginaton}
                totalPages={this.props.totalPages}
                currentPage={this.props.currentPage}
                onChange={(page) => this.onPageChange(page)}
              />
            </Column>
          </Columns>
        </Panel>
      </>
    );
  };

  nopanel = () => {
    return (
      <>
        <Columns isCentered>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 12 }}>
            <PaginatedPage
              className="is-fullwidth"
              style={styles.centerPaginaton}
              totalPages={this.props.totalPages}
              currentPage={this.props.currentPage}
              onChange={(page) => this.onPageChange(page)}
              boundaryPagesRange={1}
              siblingPagesRange={0}
              hideEllipsis
            />
          </Column>
        </Columns>
      </>
    );
  };

  render() {
    return this.props.panel ? this.withpanel() : this.nopanel();
  }
}

Pagination.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  panel: PropTypes.bool,
  onPageChangeClick: PropTypes.func,
};

Pagination.defaultProps = {
  totalPages: 0,
  currentPage: 0,
  panel: false,
  onPageChangeClick: null,
};

export default withStore(Pagination);
