import React, { Component } from "react";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import {
  SidebarMenu,
  Text,
  TextInput,
  Button,
  Loader,
  Sidebar,
} from "ks_storybook";
import withStore from "../../hocs/withStore";
import PlaylistsManageSong from "./Playlists/PlaylistsManageSong";
import { Pagination } from "../../Components/Common";
import { Songs } from "../../models";
import { v4 as uuidv4 } from "uuid";
import { Tooltip } from "react-tooltip";
import { LibraryRow, SongModal } from "../../Components/Library";
import { UserPlaylist } from "../../models";
import { withToastManager } from "react-toast-notifications";

@observer
class PlayerMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: false,
      search: "",
      page: 1,
      per_page: 50,
      direction: false,
      open: false,
      sidebarExpand: false,
      showModalSong: false,
      disabled: this.props.store.verifyPermission(5),
    };

    this.userPlaylistsNew = null;
  }

  componentDidMount() {
    const favorites = !this.props.store.ui.library.favorites;
    this.props.store.verifyPermission(4) && favorites && this.handleFavorites();
  }

  getHome = () => {
    this.props.store.ui.selectedCategoryPlaylist = null;
    this.props.store.ui.isCategory = false;
    this.props.store.ui.isReproducing = false;
  };

  searhPlaylists = () => {
    this.props.store.ui.pushHistory("/player/categories");

    this.props.store.ui.selectedCategory = this.props.store.ui.allPlaylists;
    this.props.store.ui.selectedCategoryPlaylist = null;
    this.props.store.ui.isCategory = true;
    this.props.store.ui.isReproducing = false;
  };

  addToList = (item) => {
    const lists = this.props.store.ui.lists.songs;
    let newList = [...lists];
    let newItem = item.clone();

    const index = this.props.store.ui.currentSongIndex();

    newItem.key = uuidv4();
    newList.splice(index + 1, 0, newItem);

    this.changeListSongs(newList);
  };

  changeListSongs = (songs) => {
    let newList = [];
    for (let item of songs) {
      newList.push(item);
    }

    this.props.store.ui.lists.songs = newList;

    const playlist = this.props.store.ui.currentCategoryPlaylist;

    if (this.props.store.ui.autoSave && playlist.static === 1) {
      if (!this.props.store.verifyPermission(18)) {
        this.userPlaylistsNew = this.newUserPlaylists();
        this.handleSave();
      }
    }
  };

  newUserPlaylists = () => {
    const playlist = this.props.store.ui.currentCategoryPlaylist;

    const userPlaylistsStore = this.props.store.userPlaylist;
    const attrs = {
      id: playlist.id,
      user_id: playlist.user_id,
      playlist_id: playlist.playlist_id,
      song_lenguages_id: playlist.song_lenguages_id,
      name: playlist.name,
      description: playlist.description,
      free_rights: playlist.free_rights,
      static: playlist.static,
      explicit: playlist.explicit,
      random: playlist.random,
      num_blocks: playlist.num_blocks,
      origin_type: 0,
      deleted: 0,
      playlist_songs: [],
    };

    return new UserPlaylist(attrs, userPlaylistsStore);
  };

  handleSave = () => {
    this.userPlaylistsNew.playlist_songs = this.props.store.ui.lists.songs.map(
      (s) => s.id
    );
    if (this.props.store.loggedInUser.id == this.userPlaylistsNew.user_id) {
      this.userPlaylistsNew.save();
    }
  };

  handleChange = (sender, value, name, validation) => {
    this.props.store.ui.library.find = false;
    this.setState({ page: 1, loading: true });

    this.props.store.ui.library.search = value;
    const selected = this.props.store.ui.library.singer;
    const favorites = this.props.store.ui.library.favorites;

    const { per_page } = this.state;
    const isSinger = selected ? 2 : 1;
    const isFavorites = favorites ? 1 : 0;
    if (!this.props.store.failsafeMode) {
      const songs = this.props.store.songs.fetchSongs(
        per_page,
        1,
        isSinger,
        value,
        isFavorites
      );
      songs.andThen((res) => {
        if (this.props.store.ui.library.search === value) {
          this.props.store.ui.library.find = true;
          this.props.store.ui.library.songs = res;

          this.setState({ loading: false });
        } else {
          if (!this.props.store.ui.library.find) {
            this.props.store.ui.library.songs = res;
            this.setState({ loading: false });
          }
        }
      });
    } else {
      this.props.store.ui.library.songs = null;
      this.setState({ loading: false });
    }
  };

  handleSelected = () => {
    const selected = !this.props.store.ui.library.singer;
    this.props.store.ui.library.singer = selected;
    this.setState({ loading: true });
    const { per_page } = this.state;
    const search = this.props.store.ui.library.search;
    const favorites = this.props.store.ui.library.favorites;

    const isSinger = selected ? 2 : 1;
    const isFavorites = favorites ? 1 : 0;

    if (!this.props.store.failsafeMode) {
      const songs = this.props.store.songs.fetchSongs(
        per_page,
        1,
        isSinger,
        search,
        isFavorites
      );
      songs.andThen((res) => {
        this.props.store.ui.library.songs = res;
        this.setState({ loading: false });
      });
    } else {
      this.setState({ loading: false });
    }
  };

  handleFavorites = () => {
    const favorites = !this.props.store.ui.library.favorites;
    const search = this.props.store.ui.library.search;
    const selected = this.props.store.ui.library.singer;

    this.props.store.ui.library.favorites = favorites;
    this.setState({ favorites: favorites, loading: true });
    const { per_page } = this.state;

    const isSinger = selected ? 2 : 1;
    const isFavorites = favorites ? 1 : 0;

    if (!this.props.store.failsafeMode) {
      const songs = this.props.store.songs.fetchSongs(
        per_page,
        1,
        isSinger,
        search,
        isFavorites
      );
      songs.andThen((res) => {
        this.props.store.ui.library.songs = res;
        this.setState({ loading: false });
      });
    } else {
      this.setState({ loading: false });
    }
  };

  onPageChange = (page) => {
    const selected = this.props.store.ui.library.singer;
    const search = this.props.store.ui.library.search;
    const favorites = this.props.store.ui.library.favorites;

    this.setState({ page: page, loading: true });
    const { per_page } = this.state;

    const isSinger = selected ? 2 : 1;
    const isFavorites = favorites ? 1 : 0;

    if (!this.props.store.failsafeMode) {
      const songs = this.props.store.songs.fetchSongs(
        per_page,
        page,
        isSinger,
        search,
        isFavorites
      );
      songs.andThen((res) => {
        this.props.store.ui.library.songs = res;
        this.setState({ loading: false });
      });
    }
  };

  renderPaginator = (songs) => {
    if (!songs.totalPages) return null;
    if (songs.totalPages == 1) return null;
    return (
      <React.Fragment>
        <div
          className="flex items-center"
          style={{
            width: "100%",
            padding: "4px 0",
            justifyContent: "space-between",
            paddingRight: 3,
          }}
        >
          <Text size="md">
            {this.props.store.language.label45}: {songs.count}
          </Text>

          <Pagination
            totalPages={songs.totalPages}
            currentPage={songs.currentPage}
            onPageChangeClick={this.onPageChange}
          />
        </div>
      </React.Fragment>
    );
  };

  renderLoader = () => {
    return (
      <Loader
        icon="sync-alt"
        label={this.props.store.language.label72}
        animation="spin"
        backgroundColor="transparent"
      />
    );
  };

  expandSidebar = () => {
    const direction = this.state.sidebarExpand;

    if (direction) {
      this.setState({ sidebarExpand: false });
      this.props.store.ui.sidebarExpand = false;
    } else {
      this.setState({ sidebarExpand: true });
      this.props.store.ui.sidebarExpand = true;
    }
  };

  handleToggle = () => {
    this.props.store.ui.open = !this.props.store.ui.open;
    this.setState({ open: !this.state.open });
  };

  getUserBan = (song) => {
    if (song.preference == 10) return true;

    return false;
  };

  render() {
    const songs = this.props.store.ui.library.songs;
    const search = this.props.store.ui.library.search;
    const selected = this.props.store.ui.library.singer;
    const favorites = this.props.store.ui.library.favorites;
    const songsMemmory = songs?.toArray() || [];

    const path = this.props.match.path;
    const direction = this.props.store.ui.sidebarExpand;
    const open = this.props.store.ui.open;

    const disabled = this.state.disabled;

    return (
      <Sidebar
        className={direction ? "ks--sidebar--large" : ""}
        open={open}
        onToggle={this.handleToggle}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <React.Fragment>
            <div onClick={this.getHome}>
              <SidebarMenu
                title={this.props.store.language.label39}
                iconKasimu="home"
                to={`${path}home`}
                id="home"
                classNameLink="px-0"
                iconStyle={{ height: "auto" }}
              ></SidebarMenu>
            </div>
            <div onClick={() => 
              this.props.store.getPermission(3, 3, () => {
                this.searhPlaylists();
              })
            }>
              <SidebarMenu
                title={this.props.store.language.label40}
                iconKasimu="search-solid"
                to={`${path}home`}
                id="search-playlist"
                classNameLink="px-0"
                iconStyle={{ height: "auto" }}
              ></SidebarMenu>
            </div>

            <div className="my-1" style={{ border: "1px solid #999999" }} />

            <div
              onClick={() => {
                if (this.state.disabled) {
                  this.props.store.getPermission(5, 5, () => {
                    this.setState({ disabled: false });
                  });
                }
              }}
            >
              <SidebarMenu
                title={this.props.store.language.label41}
                iconKasimu="song"
                classNameLink="px-0"
                renderButton={
                  <Button
                    size="lg"
                    color="basic"
                    kind="link"
                    icon={direction ? "arrow-left" : "arrow-right"}
                    onClick={(_, value, name, event) => {
                      event.stopPropagation();
                      if (this.state.disabled) {
                        this.props.store.getPermission(5, 5, () => {
                          this.expandSidebar();
                          this.setState({ disabled: false });
                        });
                      } else {
                        this.expandSidebar();
                      }
                    }}
                    id="expand-library"
                    space
                  />
                }
                iconClick={() => open && this.handleToggle()}
                noClick
                id="library"
              ></SidebarMenu>
            </div>
            {!disabled && (
              <div
                className="media-content"
                style={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                  margin: "0 -10px",
                }}
              >
                <div className="my-1" style={{ marginLeft: 5, marginRight: 5 }}>
                  <Button
                    color="basic"
                    kind={selected ? "solid" : "outline"}
                    className="px-2 mx-0"
                    onClick={() => {
                      this.props.store.getPermission(4, 4, () => {
                        this.handleSelected();
                      });
                    }}
                  >
                    {this.props.store.language.label42}
                  </Button>
                  <Button
                    color="basic"
                    kind={favorites ? "solid" : "outline"}
                    className="px-2 mx-0 ml-1"
                    onClick={() =>
                      this.props.store.getPermission(4, 4, () => {
                        this.handleFavorites();
                      })
                    }
                  >
                    {this.props.store.language.label181}
                  </Button>
                  <TextInput
                    invert
                    backgroundColor="black"
                    className="is-fullwidth mt-1"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    name="search"
                    autocomplete="new-song"
                    placeholder={
                      selected
                        ? this.props.store.language.label44
                        : this.props.store.language.label43
                    }
                    iconKasimu="search"
                    colorIcon="dark"
                    onChange={this.handleChange}
                    value={search}
                    double
                    iconDouble="times"
                    onClickIcon={() => {
                      const favorites = this.props.store.ui.library.favorites;
                      this.props.store.ui.library.search = "";
                      if (favorites == 0)
                        this.props.store.ui.library.songs = null;
                    }}
                  />
                </div>

                <div
                  style={{
                    position: "relative",
                    minHeight: 0,
                    flex: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {this.state.loading && (
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 1,
                        width: "100%",
                        height: "100%",
                        background: " rgba(0, 0, 0, 0.80)",
                        left: 0,
                        right: 0,
                      }}
                    >
                      <div
                        className="flex items-center"
                        style={{ justifyContent: "center", height: "100%" }}
                      >
                        {this.renderLoader()}
                      </div>
                    </div>
                  )}
                  <div style={{ overflow: "auto", height: "100%" }}>
                    {songsMemmory.map(
                      (song, idx) =>
                        !this.getUserBan(song) && (
                          <>
                            {/* <CardSong
                            name={song.name}
                            description={song.singer.name}
                            time={this.props.store.ui.convertTime(
                              Math.floor(song.duration / 1000)
                            )}
                            items={[
                              {
                                name: this.props.store.language.label46,
                                onClick: () => this.addToList(song),
                              },
                              {
                                name: "Añadir a una playlist existente",
                                onClick: () => {},
                              },
                              {
                                name: "Añadir a una playlist nueva",
                                onClick: () => {},
                              },
                            ]}
                            key={idx}
                          /> */}
                            <LibraryRow
                              song={song}
                              addToList={this.addToList}
                            />
                          </>
                        )
                    )}
                  </div>
                  {songs && this.renderPaginator(songs)}
                </div>
              </div>
            )}
          </React.Fragment>
        </div>

        <Tooltip
          id="expand-library"
          place="top"
          style={{ zIndex: 3, position: "fixed" }}
        >
          <span>
            {this.props.store.ui.sidebarExpand
              ? this.props.store.language.label87
              : this.props.store.language.label86}
          </span>
        </Tooltip>
      </Sidebar>
    );
  }
}

export default withToastManager(withStore(withRouter(PlayerMenu)));
