const de_DE = {
  languages: {
    1: "Spanisch",
    2: "Englisch",
    3: "Französisch",
    4: "Portugiesisch",
    5: "Estnisch",
    6: "Deutsch",
    7: "Italienisch"
  }, 
  label1: "Sprachen",
  label2: "Sprache ändern",
  label3: "Sie können die Sprache Ihrer Wahl auswählen und ändern.",
  label4: "Keine Beschreibung",
  label5: "Anwendung wird geladen...",
  label6: `Ihr Abonnement ist abgelaufen. Gehe zu `,
  label7: "Dieses Konto ist von einem anderen Ort aus verbunden.",
  label8: "Fehler, Benutzername oder Passwort falsch.",
  label9: "Administratoren haben keinen Zugriff auf den Webplayer!",
  label10: "Benutzer gesperrt! Kontaktiere ",
  label11: "Bitte geben Sie Benutzernamen und Passwort ein.",
  label12: "Benutzer gesperrt durch den Administrator",
  label13: "Fehler bei der Verbindung zum Server. Überprüfen Sie Ihre Verbindung.",
  label14: `Benutzername`,
  label15: `(Keine E-Mail verwenden)`,
  label16: `Passwort`,
  label17: `Anmelden`,
  label18: `Kategorie`,
  label19: `Meine Playlists`,
  label20: `Zurück`,
  label21: `Verbinde dich mit dem Kontrollpanel, um Playlists zu erstellen.`,
  label22: `Derzeit keine Daten verfügbar`,
  label23: `Fehler, die PIN muss 4-stellig sein.`,
  label24: `Fehler, falsche PIN.`,
  label25: `Player gesperrt`,
  label26: `Bitte gib deine PIN ein, um ihn zu entsperren.`,
  label27: `Erforderlich`,
  label28: `Abmelden`,
  label29: `Nach Ortstyp auswählen`,
  label30: `Nach Musikstil auswählen`,
  label31: `Nach anderen Kriterien auswählen`,
  label32: `Player im Ruhezustand`,
  label33: `Bitte gib deine PIN ein, um aus dem Ruhezustand zu erwachen.`,
  label34: `Entsperren`,
  label35: `Abbrechen`,
  label36: `Start`,
  label37: `Wiedergabe`,
  label38: `Lieder`,
  label39: `Start`,
  label40: `Playlists durchsuchen`,
  label41: `Musikbibliothek`,
  label42: `Künstler`,
  label43: `Suche nach Songtitel`,
  label44: `Suche nach Künstler`,
  label45: `Ergebnisse`,
  label46: `Als nächstes in Wiedergabe hinzufügen`,
  label47: `Playlist-Planer`,
  label48: `Anpassung`,
  label49: `Anzeigen-Planer`,
  label50: `Anzeigen`,
  label51: `Kontrollpanel`,
  label52: `Über`,
  label53: `Aktiviert`,
  label54: `Deaktiviert`,
  label55: `Datenbank-Aktualisierungsversion`,
  label56: `Musik-App`,
  label57: `Diese App wurde entwickelt von`,
  label58: `Diese App wurde entwickelt für`,
  label59: "Januar,Februar,März,April,Mai,Juni,Juli,August,September,Oktober,November,Dezember",
  label60: "Jan,Feb,Mär,Apr,Mai,Jun,Jul,Aug,Sep,Okt,Nov,Dez",
  label61: "Sonntag,Montag,Dienstag,Mittwoch,Donnerstag,Freitag,Samstag",
  label62: "So,Mo,Di,Mi,Do,Fr,Sa",
  label63: "So,Mo,Di,Mi,Do,Fr,Sa",
  label64: "Verbunden",
  label65: "Getrennt",
  label66: `Möchten Sie sich abmelden?`,
  label67: "Ja",
  label68: "Nein",
  label69: `Als nächstes in Wiedergabe verschieben`,
  label70: `Als nächstes in Wiedergabe markieren`,
  label71: `Song aus der abgespielten Playlist entfernen`,
  label72: `Suchen...`,
  label73: `Suchen...`,
  label74: `Öffnen`,
  label75: `Schließen`,
  label76: `Weitere Optionen`,
  label77: `Favorit`,
  label78: `Sperren`,
  label79: `Zurück`,
  label80: `Play`,
  label81: `Pause`,
  label82: `Weiter`,
  label83: `Aktuelle Playlist`,
  label84: `Zum Web-Kontrollpanel gehen`,
  label85: `Vorwärts`,
  label86: `Bibliothek erweitern`,
  label87: `Bibliothek verkleinern`,
  label88: `Bibliothek erweitern`,
  label89: `Stummgeschaltet`,
  label90: `Möchten Sie dieses Lied als unerwünscht markieren?`,
  label91: `Es wird nicht mehr abgespielt.`,
  label92: `Daten werden geladen...`,
  label93: "Einstellungen",
  label94: "Konto",
  label95: "Abonniertes Service",
  label96: "Einstellungen",
  label97: "Lautstärke",
  label98: "Sie können die Lautstärke im Web-Kontrollpanel ändern oder stummschalten.",
  label99: "Zeitplaner und Werbung",
  label100: "Planen Sie Ihre Lieblings-Playlists zu jeder gewünschten Zeit. Dies können Sie im Web-Kontrollpanel tun.",
  label101: "Erstellen Sie und planen Sie Werbekampagnen nach Ihrem Zeitplan. Dies können Sie im Web-Kontrollpanel tun.",
  label102: "Laden Sie Ihre Werbeanzeigen hoch. Dies können Sie im Web-Kontrollpanel tun.",
  label103: "Information",
  label104: "Version",
  label105: "Entwickler",
  label106: "Website",
  label107: "Datenschutzrichtlinien",
  label108: "Wichtig für Sie und für uns.",
  label109: "Support",
  label110: "Zählen Sie auf unsere Hilfe.",
  label111: "Andere",
  label112: "Abmelden",
  label113: "Sie haben sich angemeldet als",
  label114: "WIEDERGABE VON",
  label115: "Gefällt mir",
  label116: "Diesen Song ausblenden",
  label117: "Als nächstes in Wiedergabe verschieben",
  label118: "Als nächstes in Wiedergabe markieren",
  label119: "Aus der aktuellen Liste entfernen",
  label120: "Als nächstes in Wiedergabe hinzufügen",
  label121: `Werbung`,
  label122: "Änderungen speichern",
  label123: "Autosave (Aktiviert)",
  label124: "Autosave (Deaktiviert)",
  label125: "Autosave aktiviert",
  label126: "Autosave deaktiviert",
  label127: "Der Song wurde erfolgreich zur Playlist hinzugefügt",
  label128: "Der Song konnte nicht zur Playlist hinzugefügt werden",
  label129: "Die Playlist wurde erfolgreich erstellt",
  label130: "Der Playlist-Name existiert bereits. Bitte geben Sie einen anderen Namen an",
  label131: "Sie müssen einen Namen für die neue Playlist angeben",
  label132: "Die Songs wurden erfolgreich zur Playlist hinzugefügt",
  label133: "Die Songs konnten nicht zur Playlist hinzugefügt werden",
  label134: "Sie müssen eine Playlist auswählen",
  label135: "Der Benutzer hat keine grundlegende Limit-Konfiguration. Bitte kontaktieren Sie den Support",
  label136: "Der Song wurde als Favorit markiert und wird immer geplant",
  label137: "Der Song wurde als Favorit aufgehoben und wird nicht mehr immer geplant",
  label138: "Der Song wurde zur aktiven Playlist hinzugefügt",
  label139: "Bitte wählen Sie eine Playlist aus",
  label140: "Manuelle Playlists",
  label141: "Name der neuen Playlist",
  label142: "Hinzufügen",
  label143: "Speichern",
  label144: "Zu bestehender Playlist hinzufügen",
  label145: "Zu neuer Playlist hinzufügen",
  label146: "Für diese Aktion ist eine Internetverbindung erforderlich. Überprüfen Sie Ihre Verbindung",
  label147: "Füge die Songs zu einer bestehenden Playlist hinzu",
  label148: "Alle Songs in der Wiedergabe werden zu einer bestehenden Playlist hinzugefügt",
  label149: "Füge die Songs zu einer neuen Playlist hinzu",
  label150: "Alle Songs in der Wiedergabe werden zu einer neuen Playlist hinzugefügt",
  label151: "Sie haben keine benutzererstellten manuellen Playlists",
  lavel152: "Erfolgreich gespeichert",
  label153: "Hinzufügen...",
  label154: "Speichern...",
  label155: "Konnte nicht gespeichert werden",
  label156: "Sie haben keine Berechtigung, auf meine Playlists zuzugreifen",
  label157: "Sie haben keine Berechtigung, auf meine Favoriten-Playlists zuzugreifen",
  label158: "Sie haben keine Berechtigung, auf Kategorien und Playlists zuzugreifen",
  label159: "Sie haben keine Berechtigung, im gesamten Musikinhalt zu suchen, nur in Favoriten",
  label160: "Sie haben keine Berechtigung, auf die Musikbibliothek zuzugreifen",
  label161: "Sie haben keine Berechtigung, auf die Einstellungen zuzugreifen",
  label162: "Sie haben keine Berechtigung, sich abzumelden",
  label163: "Sie haben keine Berechtigung, auf das Kontrollpanel zuzugreifen",
  label164: "Sie haben keine Berechtigung, auf den Playlist-Planer zuzugreifen",
  label165: "Sie haben keine Berechtigung, auf den Anzeigen-Planer zuzugreifen",
  label166: "Sie haben keine Berechtigung, auf Anzeigen zuzugreifen",
  label167: "Sie haben keine Berechtigung, die Anwendung zu verlassen",
  label168: "Sie haben keine Berechtigung, Play/Pause der Wiedergabe zu steuern",
  label169: "Sie haben keine Berechtigung, das aktuelle Lied zu ändern",
  label170: "Sie haben keine Berechtigung, die Fortschrittsleiste des aktuellen Songs zu ändern",
  label171: "Sie haben keine Berechtigung, auf die Mini-Player-Ansicht zuzugreifen",
  label172: "Sie haben keine Berechtigung, Songs in der aktiven Playlist zu verschieben",
  label173: "Sie haben keine Berechtigung, Änderungen an einer manuellen Playlist in der Wiedergabe zu speichern",
  label174: "Sie haben keine Berechtigung, Songs zu einer neuen oder vorhandenen manuellen Playlist hinzuzufügen",
  label175: "Sie haben keine Berechtigung, Songs als Favoriten zu markieren oder zu entfernen",
  label176: "Sie haben keine Berechtigung, Songs zu verstecken, sodass sie nicht abgespielt werden können",
  label177: "Sie haben keine Berechtigung, Songs in der aktiven Playlist zu löschen",
  label178: "Lieblings-Playlists",
  label179: "Alle Ihre Lieblings-Playlists und die Ihres Administrators",
  label180: "Zugriff durch PIN eingeschränkt",
  label181: "Favoriten",
  label182: "Diese Playlist gehört dem Administrator und kann nicht geändert werden",
  label183: "Täglich",
  label184: "Montag bis Freitag",
  label185: "Samstag und Sonntag",
  label186: "Genauer Tag",
  label187: "Genaue Uhrzeit",
  label188: "Intervall",
  label189: "Jedes",
  label190: "Frequenz",
  label191: "Lieder",
  label192: "Anzeigenlimit pro Runde",
  label193: "Start der Anzeige",
  label194: "Ende der Anzeige",
  label195: "Vom Administrator",
  label196: "Vom Benutzer",
  label197: `(Administrator) - `,
  label198: `(Benutzer) - `,
  label199: `Ruhezustand`,
  label200: "Ungültiges Abonnement. Wenden Sie sich an den Dienstanbieter"
};

export default de_DE;