import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Text,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
} from "ks_storybook";
import { computed } from "mobx";
import { withToastManager } from "react-toast-notifications";
import { Tooltip } from "react-tooltip";

@observer
class LockModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pin: null,
      messageError: null,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleUnlock = this.handleUnlock.bind(this, true);
  }

  validLengthPin = (value) => {
    if (value.length >= 4 && value.length <= 4) {
      return true;
    } else {
      return false;
    }
  };

  handleChange(sender, value, name, validation) {
    switch (name) {
      case "pin":
        if (this.validLengthPin(value)) {
          this.setState({ messageError: null });
          this.setState({ pin: value });
        } else {
          this.setState({ messageError: this.props.store.language.label23 });
        }
        break;
      default:
        break;
    }
  }

  handleKeyPress = (event) => {
    if (event.key == "Enter" && !this.canSave) {
      this.handleUnlock();
    }
  };

  handleUnlock() {
    if (this.props.store.ui.isHibernating) {
      if (this.state.pin == this.props.store.loggedInUser.configs.pin) {
        this.props.store.ui.cancelHibernation();
      } else {
        this.setState({ messageError: this.props.store.language.label24 });
      }
    } else {
      if (this.state.pin == this.props.store.loggedInUser.configs.pin) {
        this.props.store.ui.manualUnlock = true;
        this.props.store.ui.setLock();
      } else {
        this.setState({ messageError: this.props.store.language.label24 });
      }
    }
  }

  handleCancelHibernation = () => {
    this.props.store.ui.cancelHibernation();
  };

  handleTogglePlay = () => {
    if (!this.props.store.ui.isPlaying) {
      this.props.store.ui.play();
    } else {
      this.props.store.ui.pause();
    }
  };

  @computed
  get canSave() {
    if (this.state.messageError) return true;
    if (this.state.pin == null) return true;
    return false;
  }

  renderButtons() {
    if (this.props.store.ui.isHibernating) {
      return (
        <div>
          <Button
            disabled={this.canSave}
            className="px-3"
            onClick={this.handleUnlock}
            size={this.props.store.viewMobile ? "lg" : "md"}
          >
            <strong>{this.props.store.language.label35}</strong>
          </Button>
        </div>
      );
    } else {
      return (
        <div>
          <Button
            disabled={this.canSave}
            className="px-3"
            onClick={this.handleUnlock}
            size={this.props.store.viewMobile ? "lg" : "md"}
            weight="bold"
          >
            <strong>{this.props.store.language.label34}</strong>
          </Button>
        </div>
      );
    }
  }

  renderButtonsCancel() {
    return (
      <div className="flex" style={{ justifyContent: "center" }}>
        <Button
          className="px-3"
          onClick={this.handleCancelHibernation}
          size={this.props.store.viewMobile ? "lg" : "md"}
        >
          <strong>{this.props.store.language.label35}</strong>
        </Button>
      </div>
    );
  }

  render() {
    return (
      <Modal
        show
        width={
          this.props.store.viewMobile
            ? this.props.store.ui.isHibernating
              ? "500px"
              : "380px"
            : "90%"
        }
        height={
          this.props.store.viewMobile
            ? this.props.store.ui.isHibernating
              ? "350px"
              : "250px"
            : "auto"
        }
      >
        <ModalHeader noIcon></ModalHeader>
        <ModalContent>
          <div
            className="flex flex-col items-center pb-1"
            style={{
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Text
              lineBreak
              size={this.props.store.viewMobile ? "md" : "sm"}
              lead
              weight="strong"
              // className="mb-2"
            >
              {this.props.store.ui.isHibernating
                ? this.props.store.language.label32
                : this.props.store.language.label25}
            </Text>
            {this.props.store.ui.isHibernating && (
              <Text multiline lineBreak className="mt-2">
                {this.props.store.ui.intervalsTxt}
              </Text>
            )}
            {/* {this.props.store.ui.unlock ? (
              <Text multiline lineBreak>
                {this.props.store.ui.isHibernating
                  ? this.props.store.language.label33
                  : this.props.store.language.label26}
              </Text>
            ) : null} */}
            {this.props.store.ui.unlock ? (
              // <Field
              //   marginH="10px"
              //   label={"PIN"}
              //   labelNote={this.props.store.language.label27}
              //   size="xl"
              //   NoteSize="xs"
              //   upper
              // >
              <>
                <br />
                <div
                  className="flex items-center"
                  style={{
                    gap: 30,
                  }}
                >
                  <div
                    style={{
                      width: 120,
                    }}
                  >
                    <TextInput
                      borderColor="none"
                      autocomplete="new-password"
                      className="is-fullwidth"
                      size="lg"
                      type="password"
                      name="pin"
                      onKeyPress={this.handleKeyPress}
                      onChange={this.handleChange}
                      placeholder={"PIN"}
                      textAlign="center"
                      paddingInput="20px 0px !important"
                      id="pin"
                    />
                  </div>
                  {this.props.store.ui.unlock &&
                    !this.props.store.ui.isHibernating && (
                      <>
                        <Button
                          icon={
                            this.props.store.ui.isPlaying ? "pause" : "play"
                          }
                          kind="outline"
                          color="basic"
                          className={
                            this.props.store.ui.playAutoError
                              ? "ks--playauto-error"
                              : ""
                          }
                          size={this.props.store.viewMobile ? "xl" : "lg"}
                          onClick={(e) =>
                            this.props.store.ui.playAutoError
                              ? this.handleTogglePlay(e)
                              : this.props.store.getPermission(13, 13, () => {
                                  this.handleTogglePlay(e);
                                })
                          }
                          borderWidth={4}
                          id="play-button"
                        />
                        <Tooltip id="play-button" place="top">
                          <span>
                            {this.props.store.ui.isPlaying
                              ? this.props.store.language.label81
                              : this.props.store.language.label80}
                          </span>
                        </Tooltip>
                      </>
                    )}
                </div>
              </>
            ) : // </Field>
            null}

            {this.state.messageError && (
              <>
                <Panel color="error" className="mt-2" invert>
                  <Text multiline>{this.state.messageError}</Text>
                </Panel>
              </>
            )}

            <br />

            {this.props.store.ui.unlock
              ? this.renderButtons()
              : this.renderButtonsCancel()}
          </div>
        </ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

LockModal.propTypes = {};

LockModal.defaultProps = {};

export default withToastManager(withStore(LockModal));
