import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { CategoriesList } from "../../../Components/Categories";
import { CategoryPlaylists } from "../../../Components/CategoryPlaylists";
import { ReproducingView } from "../../../Components/AudioPlayer";
import { Column, Columns } from "ks_storybook";
import LoggedUserDropdown from "../../AppRouter/components/LoggedUserDropdown";

@observer
class PlayerPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: null,
      freeRights: this.props.store.getFreeRights(),
      selectedCategory: null,
    };

    this.handleCategoryClick = this.handleCategoryClick.bind(this);
    this.handleBackwards = this.handleBackwards.bind(this);
  }

  componentDidMount() {
    this.setState({ categories: this.getCategories() });
    if (!this.props.store.ui.isCategory && !this.props.store.ui.isReproducing) {
      this.props.store.ui.pushHistory("/player/home");
    }
  }

  handleCategoryClick(category) {
    this.props.store.ui.selectedCategory = category;
    this.props.store.ui.isCategory = true;
    this.props.store.ui.pushHistory("/player/categories");
  }

  getCategories() {
    if (!this.props.store.failsafeMode) {
      return this.props.store.categories.fetchAllLang(this.props.store.lang);
    }
  }

  renderError() {
    return <div> {this.props.store.language.label13} </div>;
  }

  getListBy(id) {
    const { categories, freeRights } = this.state;

    const categoriesOrSkeletons = !categories.isOk()
      ? this.props.store.categories.getDummy(10)
      : categories
          .toArray()
          .filter((c) => c.list_view === id && c.free_rights === freeRights);

    if (id === 3) {
      categoriesOrSkeletons.unshift(
        this.props.store.newMyPlaylistsCategories(freeRights)
      );
      categoriesOrSkeletons.push(
        this.props.store.newFavoritesPlaylistsCategories(freeRights)
      );
    }

    //Agregar cada vez que se agrega un catalogo
    let allPlaylists = categoriesOrSkeletons.filter(
      (c) => c.id === 24 || c.id === 32 || c.id === 43 || c.id === 46
    );

    if (allPlaylists.length > 0) {
      this.props.store.ui.allPlaylists = allPlaylists[0];
      localStorage.setItem(
        "allPlaylists",
        JSON.stringify(allPlaylists[0].toJS())
      );
    }

    return categoriesOrSkeletons;
  }

  getTitleBy(id) {
    const titles = {
      1: this.props.store.language.label29,
      2: this.props.store.language.label30,
      3: this.props.store.language.label31,
    };
    return titles[id.toString()];
  }

  renderLists() {
    const { categories } = this.state;

    if (categories) {
      return (
        <React.Fragment>
          {this.handleShowLockModal}
          {!this.props.store.ui.hideMiniPlayer && this.props.mobile && (
            <div className="is-hidden-desktop">
              <div className="mt-6"></div>
            </div>
          )}
          {!this.props.store.viewMobile && (
            <div
              className="flex my-3"
              style={{
                justifyContent: "flex-end",
              }}
            >
              <LoggedUserDropdown mobile />
            </div>
          )}
          <Columns isCentered isVCentered>
            <Column isSize={12}>
              <React.Fragment>
                {[3, 1, 2].map((id) => {
                  const list = this.getListBy(id);
                  return (
                    list.length > 0 && (
                      <CategoriesList
                        title={this.getTitleBy(id)}
                        categories={list}
                        main={id === 3}
                        id={id}
                        mobile={!this.props.store.viewMobile}
                        sizeText={this.props.store.viewMobile ? "md" : "sm"}
                        key={id}
                        onClick={(category) => {
                          switch (category.id) {
                            case 23:
                              this.props.store.getPermission(2, 2, () => {
                                this.handleCategoryClick(category);
                              });
                              break;
                            case 5000:
                              this.props.store.getPermission(1, 1, () => {
                                this.handleCategoryClick(category);
                              });
                              break;
                            default:
                              this.props.store.getPermission(3, 3, () => {
                                this.handleCategoryClick(category);
                              });
                          }
                        }}
                      />
                    )
                  );
                })}
              </React.Fragment>
            </Column>
          </Columns>
        </React.Fragment>
      );
    }

    return null;
  }

  handleBackwards() {
    // this.setState({
    //   selectedCategory: null,
    //   prevSelectedCategory: this.state.selectedCategory,
    // });
  }

  render() {
    const { categories } = this.state;
    const { selectedCategory, isCategory, isReproducing, isPlayer } =
      this.props.store.ui;

    if (categories && categories.isError()) {
      return this.renderError();
    }

    if (isReproducing && !isPlayer) {
      return (
        <React.Fragment>
          <Columns isCentered isVCentered>
            <Column
              style={{
                maxWidth: "1450px",
              }}
              isSize={12}
            >
              <ReproducingView
                mobile={this.props.mobile}
                categories={categories}
                category={selectedCategory}
                container={this.props.container}
              />
            </Column>
          </Columns>
        </React.Fragment>
      );
    } else {
      if (selectedCategory && isCategory) {
        return (
          <React.Fragment>
            <Columns isCentered isVCentered>
              <Column
                style={{
                  maxWidth: "1450px",
                }}
                isSize={12}
              >
                <CategoryPlaylists
                  mobile={this.props.mobile}
                  category={selectedCategory}
                  onBackwards={this.handleBackwards}
                />
              </Column>
            </Columns>
          </React.Fragment>
        );
      }
    }

    return this.renderLists();
  }
}

export default withStore(PlayerPage);
