import React, { Component, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { computed } from "mobx";
import withStore from "../../hocs/withStore";
import {
  Img,
  RectSkeleton,
  Text,
  Button,
  Columns,
  Column,
  Dropdown,
  DropdownToggle,
  DropdownPanel,
  DropdownPanelItem,
} from "ks_storybook";
import { SongRow } from ".";
import { v4 as uuidv4 } from "uuid";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { withRouter } from "react-router";
import ReactDOM from "react-dom";
import "react-virtualized/styles.css";
import { WindowScroller, List, AutoSizer } from "react-virtualized";
import { Tooltip } from "react-tooltip";
import { BlockModal } from "../../Components/Preference";
import { Screen } from "../../Components/Screen";
import AudioPlayer from "./AudioPlayer";
import PlaylistModal from "./PlaylistModal";
import { UserPlaylist } from "../../models";
import { withToastManager } from "react-toast-notifications";
import { ModalSheet } from "../Common";

function getStyle({ provided, style, isDragging }) {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...style,
    ...provided.draggableProps.style,
  };
  // const grid = 8;
  // const marginBottom = 0;
  const withSpacing = {
    ...combined,
    // height: isDragging ? combined.height : combined.height - marginBottom,
    background: isDragging ? "#1a1a1a" : "transparent",
    // // display: "flex",
    // alignItems: "center",
    // justifyContent: "space-between",
    // borderRadius: grid,
    // marginBottom,
  };
  return withSpacing;
}

const Item = ({
  provided,
  item,
  style,
  isDragging,
  index,
  columnId,
  func,
  func1,
  func2,
  func3,
  func4,
  disabledClick,
}) => {
  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      isDragging={isDragging}
      ref={provided.innerRef}
      style={getStyle({ provided, style, isDragging })}
      className={`item ${isDragging ? "is-dragging" : ""}`}
      onClick={disabledClick}
    >
      <SongRow
        key={`song-${index}`}
        song={item.song}
        idx={index}
        songKey={item.key}
        moveSong={() => {
          func(item.key);
        }}
        markNext={() => {
          func1(item.key);
        }}
        deleteSong={() => {
          func2(item.key);
        }}
        updateList={(key, field, value) => {
          func3(key, field, value);
        }}
        blockSong={func4}
      />
    </div>
  );
};

const Row = (props) => {
  const { data, index, style } = props;
  const item = data.items[index];
  // We are rendering an extra item for the placeholder
  if (!item) {
    return null;
  }

  return (
    <Draggable draggableId={`${item.key}`} index={index} key={item.key}>
      {(provided) => (
        <Item
          provided={provided}
          item={item}
          style={style}
          columnId={data.columnId}
          func={data.func}
          func1={data.func1}
          func2={data.func2}
          index={index}
        />
      )}
    </Draggable>
  );
};

const getRowRender =
  (data) =>
  ({ index, style }) => {
    const item = data.items[index];

    if (!item) {
      return null;
    }

    return (
      <Draggable
        draggableId={`${item.key}`}
        index={index}
        key={item.key}
        isDragDisabled={data.disabled}
      >
        {(provided, snapshot) => (
          <Item
            isDragging={snapshot.isDragging}
            provided={provided}
            item={item}
            style={style}
            columnId={data.columnId}
            func={data.func}
            func1={data.func1}
            func2={data.func2}
            func3={data.func3}
            func4={data.func4}
            index={index}
            disabledClick={data.disabledClick}
          />
        )}
      </Draggable>
    );
  };

const ItemList = ({
  column,
  index,
  func,
  func1,
  func2,
  func3,
  func4,
  container,
  mobile,
  disabled,
  disabledClick,
}) => {
  // There is an issue I have noticed with react-window that when reordered
  // react-window sets the scroll back to 0 but does not update the UI
  // I should raise an issue for this.
  // As a work around I am resetting the scroll to 0
  // on any list that changes it's index
  const containerRef = React.useRef();

  return (
    <Droppable
      droppableId={column.id}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <Item
          provided={provided}
          isDragging={snapshot.isDragging}
          item={column.items[rubric.source.index]}
          index={rubric.source.index}
          columnId={column.id}
        />
      )}
    >
      {(provided, snapshot) => {
        // Add an extra item to our list to make space for a dragging item
        // Usually the DroppableProvided.placeholder does this, but that won't
        // work in a virtual list
        const itemCount = snapshot.isUsingPlaceholder
          ? column.items.length + 1
          : column.items.length;

        const element = ReactDOM.findDOMNode(container);

        return (
          // <div
          // style={{
          //   overflow: "scroll",
          //   height: 500,
          //   width: "100%",
          // }}
          // ref={containerRef}
          // >
          <WindowScroller scrollElement={element}>
            {({ width, height, isScrolling, onChildScroll, scrollTop }) => (
              // <div
              //   style={{
              //     flex: 1,
              //     // height: itemCount * 60,
              //     // height: 600,
              //     overflow: "visible",
              //   }}
              // >
              //   <AutoSizer disableHeight>
              // {({ width }) => (

              <List
                autoHeight
                rowCount={itemCount}
                height={height}
                isScrolling={isScrolling}
                onScroll={onChildScroll}
                scrollTop={scrollTop}
                rowHeight={mobile ? 48 : 60}
                width={width}
                ref={(ref) => {
                  // react-virtualized has no way to get the list's ref that I can so
                  // So we use the `ReactDOM.findDOMNode(ref)` escape hatch to get the ref
                  if (ref) {
                    // eslint-disable-next-line react/no-find-dom-node
                    const whatHasMyLifeComeTo = ReactDOM.findDOMNode(ref);

                    if (whatHasMyLifeComeTo instanceof HTMLElement) {
                      provided.innerRef(whatHasMyLifeComeTo);
                    }
                  }
                }}
                rowRenderer={getRowRender({
                  items: column.items,
                  columnId: column.id,
                  func: func,
                  func1: func1,
                  func2: func2,
                  func3: func3,
                  func4: func4,
                  disabled: disabled,
                  disabledClick: disabledClick,
                })}
              />

              //   )}
              // </AutoSizer>
              // </div>
            )}
          </WindowScroller>
        );
      }}
    </Droppable>
  );
};

class ColumnDraggable extends Component {
  getColumnStyle = (index) => ({
    border: index == 1 && "2px solid #e8e8e8",
    borderRadius: "8px",
    ".item": {
      background: "red",
    },
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  });

  render() {
    const {
      column,
      index,
      func,
      func1,
      func2,
      func3,
      func4,
      label1,
      label2,
      container,
      mobile,
      disabled,
      disabledClick,
    } = this.props;
    return (
      <Draggable draggableId={column.id} index={index}>
        {(provided) => (
          <div
            className={`ks--column is-12-desktop is-12-tablet is-12-mobile`}
            {...provided.draggableProps}
            ref={provided.innerRef}
            style={this.getColumnStyle(index)}
          >
            <div {...provided.dragHandleProps} />
            <ItemList
              column={column}
              index={index}
              func={func}
              func1={func1}
              func2={func2}
              func3={func3}
              func4={func4}
              container={container}
              mobile={mobile}
              disabled={disabled}
              disabledClick={disabledClick}
            />
          </div>
        )}
      </Draggable>
    );
  }
}

@observer
class ReproducingView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      state: {
        columns: {
          "column-0": {
            id: "column-0",
            title: "First column",
            items: [],
          },
        },
        columnOrder: ["column-0"],
      },
      showModalBlock: false,
      showModalPlaylist: false,
      createPlaylist: false,
      selected: { isFav: null, song: null, songKey: null },
      close: false,
      open: false,
      loading: false,
    };

    this.container = React.createRef();
    this.userPlaylistsNew = this.newUserPlaylists();
  }

  componentDidMount() {
    if (!this.props.screen) this.props.store.ui.isCategory = false;

    const arrayItems = this.getItemsDataKasimu();
    const state = {
      columns: {
        "column-0": {
          id: "column-0",
          title: "First column",
          items: arrayItems,
        },
      },
      columnOrder: ["column-0"],
    };

    this.setState({ state: state });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.store.ui.lists.songs.length !==
      this.state.state.columns["column-0"].items.length
    ) {
      this.refreshList();
    }
  }

  handleShowModalBlock = (isFav, song, songKey) => {
    this.setState({ showModalBlock: true, selected: { isFav, song, songKey } });
  };

  handleCloseModalBlock = () => {
    this.setState({ showModalBlock: false });
  };

  handleShowModalPlaylist = (create = false) => {
    this.setState({ showModalPlaylist: true, createPlaylist: create });
  };

  handleCloseModalPlaylist = () => {
    this.setState({ showModalPlaylist: false });
  };

  handleBlock = () => {
    const { isFav, song, songKey } = this.state.selected;
    const { updateList, deleted } = this;

    if (!isFav) {
      this.props.store.kasimuAPIClient
        .putOne("/songs/hide_song", "?song_id=" + song.id)
        .then(
          (res) => {
            deleted(songKey, true);

            this.handleCloseModalBlock();
          },
          (error) => {
            console.log("error", error);
          }
        );
    }
  };

  handleReproducingView = () => {
    if (this.props.store.ui.reproducingView == false)
      this.props.store.ui.reproducingView = true;
    else this.props.store.ui.reproducingView = false;
  };

  handleTogglePlay = () => {
    if (!this.props.store.ui.isPlaying) {
      this.props.store.ui.play();
    } else {
      this.props.store.ui.pause();
    }
  };

  @computed
  get renderSongs() {
    return this.props.store.ui.lists.songs.map((song, index) => (
      <SongRow key={`song-${index}`} song={song} idx={index} />
    ));
  }

  renderSkenleton() {
    return (
      <React.Fragment>
        <div>
          <Columns style={{ gridGap: "2rem" }} isVCentered>
            <Column isSize={{ mobile: 12, desktop: 4, tablet: 4 }}>
              <RectSkeleton width="100%" height="250px" />
            </Column>
            <Column isSize={{ mobile: 12, desktop: 8, tablet: 8 }}>
              <div>
                <RectSkeleton width="170px" height="15px" />
                <div className="py-1">
                  <RectSkeleton width="370px" height="50px" />
                </div>
                <RectSkeleton width="370px" height="15px" />
                <RectSkeleton width="170px" height="15px" />
              </div>
            </Column>
          </Columns>
          {this.props.store.viewMobile ? (
            <Columns className="mt-4">
              <Column
                isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
                style={{
                  backgroundColor: "#000000",
                  padding: "20px",
                  borderRadius: "15px",
                }}
              >
                {this.renderSongs}
              </Column>
            </Columns>
          ) : (
            <Columns className="mt-4">
              <Column
                isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
                style={{
                  backgroundColor: "#000000",
                  padding: "20px",
                  borderRadius: "15px",
                }}
              >
                {this.renderSongs}
              </Column>
            </Columns>
          )}
        </div>
      </React.Fragment>
    );
  }

  getCategoriesName(categoryId) {
    var cat = this.props.categories
      .toArray()
      .filter((c) => c.id === categoryId);
    return cat[0].name;
  }

  getItemsDataKasimu = () => {
    const songsStyles = this.props.store.ui.lists.songs;
    var arr = [];
    songsStyles &&
      songsStyles.map((a) =>
        arr.push({
          key: uuidv4(),
          song: a,
        })
      );
    return arr;
  };

  deleted = (key, next = false) => {
    const lists = this.state.state.columns["column-0"].items;
    const currentIndex = this.props.store.ui.currentSongIndex();
    var index = lists
      .map((x) => {
        return x.key;
      })
      .indexOf(key);

    if (lists[index].song?.id === this.props.store.ui.nextSong?.id) {
      this.props.store.ui.nextSong = null;
    }

    const newList = [];
    let lastItem = 0;
    if (next) {
      for (let i = 0; i < lists.length; i++) {
        if (lists[index]["song"].id !== lists[i]["song"].id) {
          newList.push(lists[i]);
        } else {
          lastItem = i;
        }
      }
      this.updateLibrary(lists[index]["song"], 10);
    } else {
      lists.splice(index, 1);
    }

    if (next && currentIndex === index) {
      let findIndex = lastItem + 1;

      if (lists.length - 1 < findIndex) {
        findIndex = 0;
      }
      this.props.store.ui.nextSong = lists[findIndex].song;
      this.props.store.ui.next();
    }

    const newSate = this.state.state;
    newSate.columns["column-0"].items = newList.length > 0 ? newList : lists;

    this.changeListSongs(newList.length > 0 ? newList : lists);

    this.setState({
      state: newSate,
      messageError: null,
    });
  };

  refreshList = () => {
    const arrayItems = this.getItemsDataKasimu();
    const state = {
      columns: {
        "column-0": {
          id: "column-0",
          title: "First column",
          items: arrayItems,
        },
      },
      columnOrder: ["column-0"],
    };
    this.setState({ state: state });
  };

  move = (key) => {
    const currentindex = this.props.store.ui.currentSongIndex();
    const lists = this.state.state.columns["column-0"].items;

    var index = lists
      .map((x) => {
        return x.key;
      })
      .indexOf(key);

    if (currentindex >= lists.length) {
      var k = currentindex - lists.length + 1;
      while (k--) {
        lists.push(undefined);
      }
    }

    let nextIndex = currentindex > index ? currentindex : currentindex + 1;
    lists.splice(nextIndex, 0, lists.splice(index, 1)[0]);

    const newSate = this.state.state;
    newSate.columns["column-0"].items = lists;

    this.setState({
      state: newSate,
    });
    this.changeListSongs(lists);
  };

  updateList = (key, field, value) => {
    const lists = this.state.state.columns["column-0"].items;

    var index = lists
      .map((x) => {
        return x.key;
      })
      .indexOf(key);

    // lists[index]["song"][field] = value;

    for (let i = 0; i < lists.length; i++) {
      if (lists[index]["song"].id === lists[i]["song"].id) {
        lists[i]["song"][field] = value;
      }
    }

    this.updateLibrary(lists[index]["song"], value);

    this.changeListSongs(lists);
  };

  updateLibrary = (song, value) => {
    const lists = this.props.store.ui.library?.songs?.toArray();

    if (lists) {
      for (let item of lists) {
        if (item.id === song.id) {
          item.preference = value;
        }
      }
    }
  };

  markNext = (key) => {
    const lists = this.state.state.columns["column-0"].items;

    var index = lists
      .map((x) => {
        return x.key;
      })
      .indexOf(key);

    const { song } = lists[index];

    this.props.store.ui.nextSong = song;
    this.props.store.ui.downloadNextSongs(index, false, true);
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  copy = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const item = sourceClone[droppableSource.index];
    destClone.splice(droppableDestination.index, 0, { ...item, key: uuidv4() });
    return destClone;
  };

  reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  changeListSongs = (songs) => {
    let newList = [];
    for (let item of songs) {
      newList.push(item.song);
    }

    this.props.store.ui.lists.songs = newList;
    const playlist = this.props.store.ui.currentCategoryPlaylist;
    if (this.props.store.ui.autoSave && playlist.static === 1) {
      if (!this.props.store.verifyPermission(18)) {
        this.handleSave(false);
      }
    }
  };

  onDragEnd = (result) => {
    const { state } = this.state;

    if (!result.destination) {
      return;
    }

    if (result.type === "column") {
      // if the list is scrolled it looks like there is some strangeness going on
      // with react-window. It looks to be scrolling back to scroll: 0
      // I should log an issue with the project
      const columnOrder = this.reorderList(
        state.columnOrder,
        result.source.index,
        result.destination.index
      );
      this.setState({
        state: {
          ...state,
          columnOrder,
        },
      });
      return;
    }

    // reordering in same list
    if (result.source.droppableId === result.destination.droppableId) {
      const column = state.columns[result.source.droppableId];
      const items = this.reorderList(
        column.items,
        result.source.index,
        result.destination.index
      );

      // updating column entry
      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [column.id]: {
            ...column,
            items,
          },
        },
      };
      this.changeListSongs(items);
      this.setState({ state: newState });
      return;
    }
  };

  renderDragDrop = (container) => {
    const { state } = this.state;

    return (
      <React.Fragment>
        {container && state && state.columnOrder && (
          <DragDropContext onDragEnd={this.onDragEnd}>
            <div className="app">
              <Droppable
                droppableId="all-droppables"
                direction="horizontal"
                type="column"
              >
                {(provided) => (
                  <div
                    className="ks--columns"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {state.columnOrder.map((columnId, index) => (
                      <>
                        <ColumnDraggable
                          key={columnId}
                          column={state.columns[columnId]}
                          index={index}
                          func={this.move}
                          func1={this.markNext}
                          func2={this.deleted}
                          func3={this.updateList}
                          func4={this.handleShowModalBlock}
                          label1={this.props.store.language.label606}
                          label2={this.props.store.language.label89}
                          container={container}
                          mobile={!this.props.store.viewMobile}
                          disabled={this.props.store.verifyPermission(17)}
                          disabledClick={() => {
                            this.props.store.getPermission(17, 17, () => {});
                          }}
                        />
                      </>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </DragDropContext>
        )}
      </React.Fragment>
    );
  };

  handleBackwards = () => {
    const path = "/app";

    const history = this.props.store.ui.onBackwards();

    if (history) {
      if (history == "/player/categories") {
        this.props.store.ui.isCategory = true;
        this.props.store.ui.isReproducing = false;
        this.props.history.push(path + "/player/home");
      } else if (history == "/player/playlist") {
        this.props.store.ui.isReproducing = true;
        this.props.store.ui.isCategory = false;
        this.props.history.push(path + "/player/home");
      } else {
        this.props.store.ui.isCategory = false;
        this.props.store.ui.isReproducing = false;
        this.props.history.push(path + history);
      }
    }
  };

  handleClose = () => {
    this.setState({ close: true });
    setTimeout(() => {
      this.setState({ close: false });
      this.props.close();
    }, 1000);
  };

  renderScreen = (owner, category) => {
    const { showModalBlock, showModalPlaylist } = this.state;
    return (
      <Screen
        title={
          <div
            className="flex flex-col items-center"
            style={{ flexWrap: "wrap", textAlign: "center" }}
          >
            <Text size="sm" weight="regular" lineBreak upper>
              {this.props.store.language.label114}
            </Text>
            <Text lineBreak size="lg" weight="bold">
              {owner == 1
                ? this.props.store.ui.currentCategoryPlaylist?.alias
                : this.props.store.ui.currentCategoryPlaylist?.name}
            </Text>
          </div>
        }
        right={
          <Button
            kind="link"
            color="basic"
            size="xl"
            iconKasimu="three-dots"
            onClick={() => this.setState({ open: true })}
            space
          />
        }
        onBack={this.handleClose}
        style={{
          padding: 0,
          paddingBottom: "280px",
        }}
        iconProps={{ iconKasimu: "arrow-down", size: "lg", lead: false }}
        className={this.state.close && "close"}
        renderBottom={
          <AudioPlayer
            small
            blockSong={this.handleShowModalBlock}
            state={this.state.state}
          />
        }
      >
        <React.Fragment>
          <div ref={this.container}>
            <Columns>
              <Column
                isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
                style={{
                  backgroundColor: "#000000",
                  padding: "4px",
                  borderRadius: "15px",
                }}
              >
                {this.container?.current &&
                  this.renderDragDrop(this.container.current)}
              </Column>
            </Columns>

            <Tooltip id="favorite">
              <span>{this.props.store.language.label77}</span>
            </Tooltip>
            <Tooltip id="ban">
              <span>{this.props.store.language.label78}</span>
            </Tooltip>
            {showModalBlock && (
              <BlockModal
                onClose={this.handleCloseModalBlock}
                onAccept={this.handleBlock}
                song={this.state.selected.song}
              />
            )}
          </div>
          {this.state.open && this.renderOptions(owner)}
          {showModalPlaylist && (
            <PlaylistModal
              onClose={this.handleCloseModalPlaylist}
              onAccept={this.handleCloseModalPlaylist}
              create={this.state.createPlaylist}
              handleSavePlaylist={this.handleSavePlaylist}
              handleCreatePlaylist={this.handleCreatePlaylist}
              loading={this.state.loading}
            />
          )}
        </React.Fragment>
      </Screen>
    );
  };

  handleSavePlaylist = (playlist_id) => {
    const { toastManager } = this.props;
    const { state } = this.state;
    const playlist_songs = state.columns["column-0"].items.map(
      (s) => s.song.id
    );

    if (playlist_id) {
      this.setState({ loading: true });
      const params = {
        playlist_id: playlist_id,
        playlist_songs: playlist_songs,
      };

      this.props.store.kasimuAPIClient
        .post("/users_playlist/add_songs_to_playlist", params)
        .then(
          (res) => {
            this.setState({ loading: false });
            const { answer_code } = res.results;
            switch (answer_code) {
              case 1:
                this.handleCloseModalPlaylist();
                toastManager.add(this.props.store.language.label132, {
                  appearance: "success",
                  autoDismiss: true,
                });
                break;
              case 2:
                toastManager.add(this.props.store.language.label133, {
                  appearance: "error",
                  autoDismiss: true,
                });
                break;
            }
          },
          (error) => {
            this.setState({ loading: false });
            toastManager.add(this.props.store.language.label133, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        )
        .catch((error) => {
          this.setState({ loading: false });
          toastManager.add(this.props.store.language.label133, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    } else {
      toastManager.add(this.props.store.language.label134, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  handleCreatePlaylist = (name) => {
    const { toastManager } = this.props;
    const { state } = this.state;
    const playlist_songs = state.columns["column-0"].items.map(
      (s) => s.song.id
    );

    if (name) {
      this.setState({ loading: true });
      const params = {
        playlist_id: 0,
        song_lenguages_id: 1,
        name: name,
        description: "",
        free_rights: 0,
        static: 1,
        explicit: 0,
        random: 0,
        num_blocks: 0,
        origin_type: 0,
        deleted: 0,
        playlist_songs: playlist_songs,
      };

      this.props.store.kasimuAPIClient
        .post("/users_playlist/create_playlist_all", params)
        .then(
          (res) => {
            this.setState({ loading: false });
            const { answer_code } = res.results;
            switch (answer_code) {
              case 1:
                this.handleCloseModalPlaylist();
                toastManager.add(this.props.store.language.label129, {
                  appearance: "success",
                  autoDismiss: true,
                });
                break;
              case 2:
                toastManager.add(this.props.store.language.label130, {
                  appearance: "error",
                  autoDismiss: true,
                });
                break;
            }
          },
          (error) => {
            this.setState({ loading: false });
            toastManager.add(this.props.store.language.label133, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        )
        .catch((error) => {
          this.setState({ loading: false });
          toastManager.add(this.props.store.language.label133, {
            appearance: "error",
            autoDismiss: true,
          });
        });
    } else {
      toastManager.add(this.props.store.language.label131, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  newUserPlaylists = () => {
    const playlist = this.props.store.ui.currentCategoryPlaylist;

    const userPlaylistsStore = this.props.store.userPlaylist;
    const attrs = {
      id: playlist.id,
      user_id: playlist.user_id,
      playlist_id: playlist.playlist_id,
      song_lenguages_id: playlist.song_lenguages_id,
      name: playlist.name,
      description: playlist.description,
      free_rights: playlist.free_rights,
      static: playlist.static,
      explicit: playlist.explicit,
      random: playlist.random,
      num_blocks: playlist.num_blocks,
      origin_type: 0,
      deleted: 0,
      playlist_songs: [],
    };
    return new UserPlaylist(attrs, userPlaylistsStore);
  };

  handleSave = (notif = true) => {
    const { toastManager } = this.props;

    this.userPlaylistsNew.playlist_songs = this.props.store.ui.lists.songs.map(
      (s) => s.id
    );

    if (this.props.store.loggedInUser.id == this.userPlaylistsNew.user_id) {
      this.userPlaylistsNew.save().andThen((res, responseError) => {
        if (responseError) {
          toastManager.add(this.props.store.language.label155, {
            appearance: "error",
            autoDismiss: true,
          });
        } else {
          if (notif) {
            toastManager.add(this.props.store.language.lavel152, {
              appearance: "success",
              autoDismiss: true,
            });
          }
        }
      });
    } else {
      toastManager.add(this.props.store.language.label182, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  updateAutosave = () => {
    const { toastManager } = this.props;
    const update = this.props.store.ui.updateAutosave();
    if (update) {
      toastManager.add(this.props.store.language.label125, {
        appearance: "success",
        autoDismiss: true,
      });
    } else {
      toastManager.add(this.props.store.language.label126, {
        appearance: "success",
        autoDismiss: true,
      });
    }
  };

  getAutoSave = () => {
    return this.props.store.ui.autoSave
      ? this.props.store.language.label123
      : this.props.store.language.label124;
  };

  renderOptions = (owner) => {
    return (
      <ModalSheet
        onClose={() => {
          this.setState({ open: false });
        }}
      >
        <div className="px-2 pb-2">
          <div className="flex" style={{ gap: 8 }}>
            <div className="flex items-center justify-center">
              <Img
                height="auto"
                width="40"
                src={
                  owner == 1
                    ? this.props.store.replaceBucketUrl(this.props.store.ui.currentCategoryPlaylist?.icon_path)
                    : this.props.store.ui.currentCategoryPlaylist?.static === 1
                    ? this.props.store.ui._iconMyplaylists2
                    : this.props.store.ui._iconMyplaylists
                }
                style={{
                  borderRadius: "6px",
                  boxShadow: "0 4px 60px rgba(0,0,0,.5)",
                }}
              />
            </div>
            <div style={{ overflow: "hidden" }}>
              <Text color="white" weight="bold" size="xl" multiline>
                {owner == 1
                  ? this.props.store.ui.currentCategoryPlaylist?.alias
                  : this.props.store.ui.currentCategoryPlaylist?.name}
              </Text>
              <Text color="special" sixe="lg">
                {this.props.store.ui.currentCategoryPlaylist?.description}
              </Text>
            </div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            height: 1,
            background: "#999999",
          }}
        ></div>

        <div
          className="flex px-2 py-2"
          style={{ flexDirection: "column", gap: 8 }}
          onClick={() => this.setState({ open: false })}
        >
          {this.props.store.ui.currentCategoryPlaylist?.static === 1 ? (
            <>
              <div
                className="flex items-center"
                style={{ padding: "5px 0px" }}
                onClick={() =>
                  this.props.store.getPermission(18, 18, () => {
                    this.handleSave();
                  })
                }
              >
                <Text size="xl" color="white">
                  {this.props.store.language.label122}
                </Text>
              </div>
              <div
                className="flex items-center"
                style={{ padding: "5px 0px" }}
                onClick={() =>
                  this.props.store.getPermission(18, 18, () => {
                    this.updateAutosave();
                  })
                }
              >
                <Text size="xl" color="white">
                  {this.getAutoSave()}
                </Text>
              </div>
            </>
          ) : (
            <>
              <div
                className="flex items-center"
                style={{ padding: "5px 0px" }}
                onClick={() =>
                  this.props.store.getPermission(19, 19, () =>
                    this.handleShowModalPlaylist()
                  )
                }
              >
                <Text size="xl" color="white">
                  {this.props.store.language.label147}
                </Text>
              </div>
              <div
                className="flex items-center"
                style={{ padding: "5px 0px" }}
                onClick={() =>
                  this.props.store.getPermission(19, 19, () =>
                    this.handleShowModalPlaylist(true)
                  )
                }
              >
                <Text size="xl" color="white">
                  {this.props.store.language.label149}
                </Text>
              </div>
            </>
          )}
        </div>
      </ModalSheet>
    );
  };

  renderHeader(owner, category) {
    const { showModalBlock, showModalPlaylist } = this.state;

    if (this.props.screen) {
      return this.renderScreen(owner, category);
    }

    return (
      <React.Fragment>
        <div>
          {this.props.store.viewMobile ? (
            <div className="flex items-center mb-4" style={{ gap: "50px" }}>
              <div
                className="flex items-center"
                style={{ justifyContent: "center" }}
              >
                <Img
                  height="auto"
                  width="250"
                  src={
                    owner == 1
                      ? this.props.store.replaceBucketUrl(this.props.store.ui.currentCategoryPlaylist?.icon_path)
                      : this.props.store.ui.currentCategoryPlaylist?.static ===
                        1
                      ? this.props.store.ui._iconMyplaylists2
                      : this.props.store.ui._iconMyplaylists
                  }
                  style={{
                    borderRadius: "6px",
                    boxShadow: "0 4px 60px rgba(0,0,0,.5)",
                  }}
                />
              </div>

              <div>
                <Text size="sm" lead weight="medium">
                  {"Playlist"}
                </Text>
                <Text className="py-1" size="xl" lead weight="black" lineBreak>
                  {owner == 1
                    ? this.props.store.ui.currentCategoryPlaylist?.alias
                    : this.props.store.ui.currentCategoryPlaylist?.name}
                </Text>
                <Text size="md" weight="medium" multiline>
                  {this.props.store.ui.currentCategoryPlaylist?.description}
                </Text>
                <Text size="md" weight="medium" color="secondary" multiline>
                  {`${this.props.store.ui.lists.songs.length} ${this.props.store.language.label38}`}
                </Text>
                <Dropdown direction="bottom-left">
                  <DropdownToggle hideToggler>
                    <Button
                      iconKasimu="three-points"
                      kind="link"
                      color="basic"
                      size="xl"
                      space
                    />
                  </DropdownToggle>

                  {this.props.store.ui.currentCategoryPlaylist?.static === 1 ? (
                    <DropdownPanel
                      style={{
                        width: 400,
                      }}
                    >
                      <DropdownPanelItem
                        onClick={() =>
                          this.props.store.getPermission(18, 18, () =>
                            this.handleSave()
                          )
                        }
                      >
                        {this.props.store.language.label122}
                      </DropdownPanelItem>
                      <DropdownPanelItem
                        onClick={() =>
                          this.props.store.getPermission(18, 18, () =>
                            this.updateAutosave()
                          )
                        }
                      >
                        {this.getAutoSave()}
                      </DropdownPanelItem>
                    </DropdownPanel>
                  ) : (
                    <DropdownPanel
                      style={{
                        width: 400,
                      }}
                    >
                      <DropdownPanelItem
                        onClick={() =>
                          this.props.store.getPermission(19, 19, () =>
                            this.handleShowModalPlaylist()
                          )
                        }
                      >
                        {this.props.store.language.label147}
                      </DropdownPanelItem>
                      <DropdownPanelItem
                        onClick={() =>
                          this.props.store.getPermission(19, 19, () =>
                            this.handleShowModalPlaylist(true)
                          )
                        }
                      >
                        {this.props.store.language.label149}
                      </DropdownPanelItem>
                    </DropdownPanel>
                  )}
                </Dropdown>
              </div>
            </div>
          ) : (
            <div>
              <div
                style={{
                  position: "fixed",
                  top: 0,
                  left: 0,
                  right: 0,
                  width: "100%",
                  padding: "0 12px",
                  height: 8 * 8,
                  background: "transparent",
                  zIndex: 1,
                }}
                id="show-header"
              >
                <div className="flex items-center" style={{ height: "100%" }}>
                  <Button
                    size="xl"
                    iconKasimu="arrow-left"
                    onClick={this.handleBackwards}
                    color="basic"
                    className="mr-2"
                    id="go-back"
                    kind="link"
                    space
                  />
                  <div id="show-category" style={{ display: "none" }}>
                    <Text size="xl" weight="bold" lineBreak>
                      {owner == 1
                        ? this.props.store.ui.currentCategoryPlaylist?.alias
                        : this.props.store.ui.currentCategoryPlaylist?.name}
                    </Text>
                  </div>
                </div>
              </div>
              <div className="pb-2 pt-2" style={{ position: "relative" }}>
                <div
                  className="flex"
                  style={{
                    justifyContent: "flex-end",
                    zIndex: 1,
                    position: "absolute",
                    right: 0,
                  }}
                >
                  <Button
                    kind="link"
                    color="basic"
                    size="xl"
                    iconKasimu="three-dots"
                    onClick={() => this.setState({ open: true })}
                    space
                  />
                  {this.state.open && this.renderOptions(owner)}
                </div>
                <div
                  className="flex items-center pt-3"
                  style={{ justifyContent: "center" }}
                >
                  <Img
                    height="auto"
                    width="200"
                    src={
                      owner == 1
                        ? this.props.store.replaceBucketUrl(this.props.store.ui.currentCategoryPlaylist?.icon_path)
                        : this.props.store.ui.currentCategoryPlaylist
                            ?.static === 1
                        ? this.props.store.ui._iconMyplaylists2
                        : this.props.store.ui._iconMyplaylists
                    }
                    style={{
                      borderRadius: "6px",
                      boxShadow: "0 4px 60px rgba(0,0,0,.5)",
                    }}
                  />
                </div>

                <div className="py-2">
                  <Text size="md" lead weight="black" lineBreak>
                    {owner == 1
                      ? this.props.store.ui.currentCategoryPlaylist?.alias
                      : this.props.store.ui.currentCategoryPlaylist?.name}
                  </Text>
                  <Text color="special" size="md" weight="medium" multiline>
                    {this.props.store.ui.currentCategoryPlaylist?.description}
                  </Text>
                </div>
              </div>
            </div>
          )}
          <Columns>
            <Column
              isSize={{ mobile: 12, desktop: 12, tablet: 12 }}
              style={{
                backgroundColor: "#000000",
                padding: this.props.store.viewMobile ? "20px" : "4px",
                borderRadius: "15px",
              }}
            >
              {this.renderDragDrop(this.props.container())}
            </Column>
          </Columns>

          <Tooltip id="favorite">
            <span>{this.props.store.language.label77}</span>
          </Tooltip>
          <Tooltip id="ban">
            <span>{this.props.store.language.label78}</span>
          </Tooltip>
          {showModalBlock && (
            <BlockModal
              onClose={this.handleCloseModalBlock}
              onAccept={this.handleBlock}
              song={this.state.selected.song}
            />
          )}
          {showModalPlaylist && (
            <PlaylistModal
              onClose={this.handleCloseModalPlaylist}
              onAccept={this.handleCloseModalPlaylist}
              create={this.state.createPlaylist}
              handleSavePlaylist={this.handleSavePlaylist}
              handleCreatePlaylist={this.handleCreatePlaylist}
              loading={this.state.loading}
            />
          )}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const { category } = this.props;

    return this.props.store.ui.banderClick
      ? this.renderHeader(this.props.store.ui.ownerClick, category)
      : this.renderHeader(this.props.store.ui.currentOwner, category);
  }
}

ReproducingView.defaultProps = {
  onGoback: null,
  mobile: false,
};

ReproducingView.propTypes = {
  onBackwards: PropTypes.func,
  mobile: PropTypes.bool,
};

export default withToastManager(withStore(withRouter(ReproducingView)));
