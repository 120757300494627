import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Text,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
} from "ks_storybook";
import { Select } from "../Select";

@observer
class PlaylistModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      playlists: this.props.create ? null : this.getManualPlaylists(),
      playlist_id: null,
      playlist_name: "",
    };
  }

  getManualPlaylists = () => {
    return this.props.store.userPlaylist.getMyManualPlatlists();
  };

  getOptionsPlaylists = () => {
    const { playlists } = this.state;
    return playlists
      .toArray()
      .map((o) => ({ value: o.playlist_id, label: o.name }));
  };

  handleChangeSelect = (value) => {
    this.setState({ playlist_id: value.value });
  };

  handleAddSongsToPlaylist = () => {
    const { handleSavePlaylist } = this.props;
    const { playlist_id } = this.state;
    handleSavePlaylist(playlist_id);
  };

  handleChangeInput = (sender, value) => {
    this.setState({ playlist_name: value });
  };

  handleAddSongsToNewPlaylist = () => {
    const { handleCreatePlaylist } = this.props;
    const { playlist_name } = this.state;
    handleCreatePlaylist(playlist_name);
  };

  handleAccept = () => {
    this.props.onAccept && this.props.onAccept();
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  renderHeader() {
    const { create, loading } = this.props;

    if (create) {
      return (
        <div
          className="flex flex-col items-center py-2"
          style={{
            height: "100%",
          }}
        >
          <Text
            lineBreak
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="strong"
            className="text-center"
          >
            {this.props.store.language.label150}
          </Text>

          <TextInput
            backgroundColor="blackDark"
            borderColor="black"
            autocomplete="nope"
            className="is-fullwidth mt-2"
            size={this.props.store.viewMobile ? "xl" : "lg"}
            paddingInput={"20px"}
            placeholder={this.props.store.language.label141}
            placeholderColor="gray"
            onChange={this.handleChangeInput}
          />

          <br />
          <div>
            <Button
              className="px-3"
              onClick={this.handleAddSongsToNewPlaylist}
              size={this.props.store.viewMobile ? "lg" : "md"}
              weight="bold"
              disabled={loading}
            >
              <strong>
                {loading
                  ? this.props.store.language.label154
                  : this.props.store.language.label143}
              </strong>
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div
        className="flex flex-col py-2"
        style={{
          height: "100%",
        }}
      >
        <Text
          lineBreak
          size={this.props.store.viewMobile ? "md" : "sm"}
          lead
          weight="strong"
          className="text-center"
        >
          {this.props.store.language.label148}
        </Text>
        <br />

        <Text size={this.props.store.viewMobile ? "xl" : "lg"} lineBreak>
          {this.props.store.language.label140}:
        </Text>

        <Select
          placeholder={this.props.store.language.label139}
          size={this.props.store.viewMobile ? "lg" : "md"}
          backgroundColor="#000000"
          className="is-fullwidth pt-1"
          options={this.getOptionsPlaylists()}
          isSearchable={false}
          onChange={this.handleChangeSelect}
        />

        <br />

        <div className="flex justify-center">
          <Button
            className="px-3"
            onClick={this.handleAddSongsToPlaylist}
            size={this.props.store.viewMobile ? "lg" : "md"}
            weight="bold"
            disabled={loading}
          >
            <strong>
              {loading
                ? this.props.store.language.label153
                : this.props.store.language.label142}
            </strong>
          </Button>
        </div>
      </div>
    );
  }

  render() {
    return (
      <Modal
        show
        width={this.props.store.viewMobile ? "620px" : "90%"}
        height={this.props.store.viewMobile ? "250px" : "auto"}
        onClose={this.handleClose}
      >
        <ModalHeader></ModalHeader>
        <ModalContent>{this.renderHeader()}</ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

PlaylistModal.propTypes = {};

PlaylistModal.defaultProps = {};

export default withStore(PlaylistModal);
