import { Store } from "ks_storybook";
import { AdvertisingGuidelines } from "../models";

class AdvertisingGuidelinesStore extends Store {
  model = AdvertisingGuidelines;

  getGuidelinesUser(value) {
    return this.search(
      { per_page: 1000 },
      "advertising_guidelines",
      true,
      `/advertising_guidelines/${value}/get_guidelines_user`
    );
  }

  getGuidelinesAndAdBatches(date, time) {
    return this.search(
      { per_page: 10000 },
      "get_guidelines_and_ad_batches",
      true,
      `/advertising_guidelines/get_guidelines_and_ad_batches/?date=${date}&time=${time}`
    );
  }
}

export default AdvertisingGuidelinesStore;
