import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  RectSkeleton,
  Button,
  Text,
  Dropdown,
  DropdownToggle,
  DropdownPanel,
} from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";

@observer
class AdPlanRow extends Component {
  renderSkelenton = () => {
    return <RectSkeleton width="150px" height="20px" />;
  };

  renderSwitch = (plan) => {
    switch (plan.priority) {
      case 1:
        return (
          <small className="has-text-grey-lighter">
            <small className="has-text-grey-lighter">
              {this.props.store.language.label186}: {plan.exact_day}
            </small>
            <br />
            {this.props.store.language.label187}: {plan.exact_hour}
          </small>
        );
      case 2:
        return (
          <small className="has-text-grey-lighter">
            {this.props.store.language.label187}: {plan.exact_hour}
          </small>
        );
      case 3:
        return (
          <small className="has-text-grey-lighter">
            {this.props.store.language.label188}: {plan.from_hour} -{" "}
            {plan.to_hour}
            <br />
            {this.props.store.language.label190}:{" "}
            {this.props.store.language.label189} {plan.frequency_songs}{" "}
            {this.props.store.language.label191}
          </small>
        );
      case 4:
        return (
          <small className="has-text-grey-lighter">
            {this.props.store.language.label188}: {plan.from_hour} -{" "}
            {plan.to_hour}
            <br />
            {this.props.store.language.label190}:{" "}
            {this.props.store.language.label189} {plan.frequency_minutes}{" "}
            {" min"}
          </small>
        );
      case 5:
        return (
          <small className="has-text-grey-lighter">
            {this.props.store.language.label188}:{plan.from_hour} -{" "}
            {plan.to_hour}
            <br />
            {this.props.store.language.label190}:{" "}
            {this.props.store.language.label189} {plan.frequency_songs}{" "}
            {this.props.store.language.label191}
          </small>
        );
      case 6:
        return (
          <small className="has-text-grey-lighter">
            {this.props.store.language.label188}: {plan.from_hour} -{" "}
            {plan.to_hour}
            <br />
            {this.props.store.language.label190}:{" "}
            {this.props.store.language.label189} {plan.frequency_minutes}{" "}
            {" min"}
          </small>
        );
    }
  };

  render() {
    const { plan } = this.props;
    if (plan.isEmpty()) {
      return this.renderSkelenton();
    }
    return (
      <div className="ks--plan">
        <article
          className="ks--media"
          style={{
            alignItems: "center",
            justifyContent: "space-beetwen",
            padding: !this.props.store.viewMobile && "10px 15px",
          }}
        >
          <div className="media-content">
            <div className="content" style={{ lineBreak: "anywhere" }}>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="special"
                lineBreak
                weight="strong"
              >
                {plan.name}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                color="gray"
                lineBreak
                weight="regular"
              >
                <small className="has-text-grey-lighter">
                  {this.props.store.language.label192}: {plan.launcher_limiter}
                </small>
                <br />
                {plan.start_date == "NULL" &&
                plan.finish_date == "NULL" ? null : (
                  <div>
                    <small className="has-text-grey-lighter">
                      {this.props.store.language.label193} {plan.start_date}{" "}
                      {"- " + this.props.store.language.label194}{" "}
                      {plan.finish_date}
                    </small>
                  </div>
                )}
                {this.renderSwitch(plan)}
              </Text>
            </div>
          </div>
        </article>
      </div>
    );
  }
}

AdPlanRow.propTypes = {
  plan: PropTypes.object,
  onDeleteClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onEyeClick: PropTypes.func,
};

AdPlanRow.defaultProps = {
  plan: null,
  onDeleteClick: null,
  onEditClick: null,
  onEyeClick: null,
};

export default withStore(withToastManager(AdPlanRow));
