import { Store } from "ks_storybook";
import { PlaylistPlanUser } from "../models";

class PlaylistsPlansUserStore extends Store {
  model = PlaylistPlanUser;

  getPlaylistPlansUser(value, lang) {
    return this.search(
      { per_page: 1000, lang: lang },
      "user/actions_playlist_plans",
      true,
      `/actions_playlist_plans/${value}/get_playlist_planner_user`
    );
  }
}

export default PlaylistsPlansUserStore;
