import React, { Component } from "react";
import { observer, PropTypes } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Text,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
} from "ks_storybook";
import { withToastManager } from "react-toast-notifications";
import { Select } from "../Select";
import es_ES from "../../lang/es_ES.js";
import en_EN from "../../lang/en_EN.js";
import fr_FR from "../../lang/fr_FR.js";
import pt_PT from "../../lang/pt_PT.js";
import et_EE from "../../lang/et_EE.js";
import de_DE from "../../lang/de_DE.js";
import it_IT from "../../lang/it_IT.js";

@observer
class LanguageChange extends Component {
  constructor(props) {
    super(props);

    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  handleSelectChange(value, sender) {
    const val = !value ? 0 : value.value;
    this.props.store.lang = val;

    switch (val) {
      case 2:
        localStorage.setItem("currentLang", val);
        this.props.store.language = en_EN;
        break;
      case 1:
        localStorage.setItem("currentLang", val);
        this.props.store.language = es_ES;
        break;
      case 3:
        localStorage.setItem("currentLang", val);
        this.props.store.language = fr_FR;
        break;
      case 4:
        localStorage.setItem("currentLang", val);
        this.props.store.language = pt_PT;
        break;
      case 5:
        localStorage.setItem("currentLang", val);
        this.props.store.language = et_EE;
        break;
      case 6:
        localStorage.setItem("currentLang", val);
        this.props.store.language = de_DE;
        break;
      case 7:
        localStorage.setItem("currentLang", val);
        this.props.store.language = it_IT;
        break;
    }
  }

  renderSelectLang(value) {
    const options = [
      { value: 2, label: this.props.store.language.languages[2] },
      { value: 1, label: this.props.store.language.languages[1] },
      { value: 3, label: this.props.store.language.languages[3] },
      { value: 4, label: this.props.store.language.languages[4] },
      { value: 5, label: this.props.store.language.languages[5] },
      { value: 6, label: this.props.store.language.languages[6] },
      { value: 7, label: this.props.store.language.languages[7] },
    ];

    return (
      <Field>
        <Select
          onChange={this.handleSelectChange}
          name="lang"
          required
          autocomplete="nope"
          options={options}
          value={options.filter((e) => e.value === value)}
        />
      </Field>
    );
  }

  render() {
    return (
      <Modal
        show
        onClose={this.handleClose}
        width={this.props.store.viewMobile ? "540px" : "90%"}
        height={this.props.store.viewMobile ? "200px" : "auto"}
      >
        <ModalHeader>
          <Text
            lineBreak
            size={this.props.store.viewMobile ? "md" : "sm"}
            lead
            weight="black"
            className="mb-1"
          >
            {this.props.store.language.label2}
          </Text>
        </ModalHeader>
        <ModalContent>
          {this.renderSelectLang(this.props.store.lang || 1)}
        </ModalContent>
        <ModalFooter></ModalFooter>
      </Modal>
    );
  }
}

LanguageChange.propTypes = {
  onClose: PropTypes.func,
};

LanguageChange.defaultProps = {
  onClose: null,
};

export default withToastManager(withStore(LanguageChange));
