import { Store } from "ks_storybook";
import { User } from "../models";

class UsersStore extends Store {
  model = User;

  fetchSearch(value) {
    return this.search({ search: value }, "accounts", true, "/users");
  }

  fetchAccounts() {
    return this.search({}, "accounts", true, "/users/accounts");
  }

  saveAsAccount(model) {
    var str = model.name;
    var pos = str.indexOf("@");
    if (pos === -1) {
      model.name = `@${model.name}`;
    }

    return this.save(model, "/users/create_account");
  }
}

export default UsersStore;
