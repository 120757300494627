import audioplayerLogo from "../assets/images/audioplayer.png";
import audioplayerLogoMobile from "../assets/images/audioplayer_m.png";
import audioplayerLogoSquare from "../assets/images/audioplayer_square.png";
const AWS_BUCKET = 'https://sources.megaplayer.me';

const AUDIOPLAYER = {
    reseller_companies_id: 6,
    logo: audioplayerLogo,
    logoMobile: audioplayerLogoMobile,
    logoSquare: audioplayerLogoSquare,
    name: 'AudioPlayer',
    favicon: AWS_BUCKET+'/favicon/audioplayer.ico',
    web: 'www.audioplayer.me',
    supportContact: 'soporte@audioplayer.me',
    url_panel: 'https://panel.audioplayer.me/'
}

export default AUDIOPLAYER;