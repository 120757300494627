import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { Text, Button, Img, Avatar } from "ks_storybook";
import { SignOutModal, LanguageChange } from "../../../Components/User";

@observer
class SettingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalSignOut: false,
      showLanguageChange: false,
    };
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  componentDidMount() {
    this.props.store.ui.pushHistory("/player/setting");
    this.props.store.menuTitle = this.props.store.language.label93;
  }

  componentDidUpdate() {
    if (this.props.store.menuTitle !== this.props.store.language.label93) {
      this.props.store.menuTitle = this.props.store.language.label93;
    }
  }

  componentWillUnmount() {
    this.props.store.menuTitle = null;
  }

  handleShowModalSignOut = () => {
    this.setState({ showModalSignOut: true });
  };

  handleCloseModalSignOut = () => {
    this.setState({ showModalSignOut: false });
  };

  handleLanguageChangeModalOpen = () => {
    this.setState({ showLanguageChange: true });
  };

  handleLanguageChangeModalClose = () => {
    this.setState({ showLanguageChange: false });
  };

  handleSignOut() {
    this.handleShowModalSignOut();
    this.props.store.signOut();
  }

  goToPage = (to) => {
    window.open("http://" + to, "_blank");
  };

  getDeveloped = () => {
    if (this.props.store.typeView === 0) {
      return `${this.props.store.language.label57} ${
        this.props.store.currentCompany.name
      }`;
    } else {
      return `${this.props.store.language.label58} ${
        this.props.store.currentCompany.name
      }`;
    }
  };

  goTo = (route) => {
    this.props.history.push("/app/" + route);
  };

  renderHeader() {
    const info = this.props.store.loggedInUserPersonalization;
    const user = this.props.store.loggedInUser;

    const { showModalSignOut, showLanguageChange } = this.state;

    return (
      <div
        style={{
          display: "flex",
          flex: "auto",
          flexDirection: "column",
        }}
      >
        <div className="flex items-center justify-center py-3">
          <Button
            color="primary"
            size="md"
            className="px-2"
            onClick={() =>
              this.props.store.getPermission(8, 8, () => {
                this.props.store.goToPanel();
              })}
          >
            {this.props.store.language.label51}
          </Button>
        </div>
        <div className="flex items-center mb-2">
          <Avatar
            image={info ? this.props.store.replaceBucketUrl(info.avatar_path) : null}
            size="lg"
            placeholderIcon={
              this.props.store.viewMobile ? "user-circle" : "user-circle-white"
            }
          />
          <Text className="ml-2" weight="bold" size="sm" lead>
            {user.username}
          </Text>
        </div>

        <div className="mb-2">
          <Text className="mb-1" weight="bold" size="xl" lineBreak>
            {this.props.store.language.label94}
          </Text>
          <Text weight="bold" size="lg" lineBreak>
            {"Plan"} {this.props.store.getServiceName()}
          </Text>
          <Text color="special" size="lg" lineBreak>
            {this.props.store.language.label95}
          </Text>
        </div>
        <div className="mb-2">
          <Text className="mb-1" weight="bold" size="xl" lineBreak>
            {this.props.store.language.label96}
          </Text>
          <Text weight="bold" size="lg" lineBreak>
            {this.props.store.language.label97}
          </Text>
          <Text color="special" size="lg" lineBreak>
            {Math.floor(this.props.store.ui.volume * 100) + "%"}
            <br />
            {this.props.store.language.label98}
          </Text>
          <div className="my-1"></div>
          <div onClick={this.handleLanguageChangeModalOpen}>
            <Text weight="bold" size="lg" lineBreak>
              {this.props.store.language.label2}
            </Text>
            <Text color="special" size="lg" lineBreak>
              {this.props.store.language.label3}
            </Text>
          </div>
        </div>
        <div className="mb-2">
          <Text className="mb-1" weight="bold" size="xl" lineBreak>
            {this.props.store.language.label99}
          </Text>
          <div
            onClick={() => {
              this.props.store.getPermission(9, 9, () => {
                this.goTo("player/playlists_plans");
              });
            }}
          >
            <Text weight="bold" size="lg" lineBreak>
              {this.props.store.language.label47}
            </Text>
            <Text color="special" size="lg" lineBreak>
              {this.props.store.language.label100}
            </Text>
          </div>
          <div className="my-1"></div>
          <div
            onClick={() => {
              this.props.store.getPermission(9, 9, () => {
                this.goTo("player/ads_plans");
              });
            }}
          >
            <Text weight="bold" size="lg" lineBreak>
              {this.props.store.language.label49}
            </Text>
            <Text color="special" size="lg" lineBreak>
              {this.props.store.language.label101}
            </Text>
          </div>
          <div className="my-1"></div>
          <div
            onClick={() => {
              this.props.store.getPermission(9, 9, () => {
                this.goTo("player/ads/list");
              });
            }}
          >
            <Text weight="bold" size="lg" lineBreak>
              {this.props.store.language.label50}
            </Text>
            <Text color="special" size="lg" lineBreak>
              {this.props.store.language.label102}
            </Text>
          </div>
        </div>
        <div className="mb-2">
          <Text className="mb-1" weight="bold" size="xl" lineBreak>
            {this.props.store.language.label103}
          </Text>
          <Text weight="bold" size="lg" lineBreak>
            {this.props.store.language.label104}
          </Text>
          <Text color="special" size="lg" lineBreak>
            {this.props.store.version}
          </Text>
          <div className="my-1"></div>
          <Text weight="bold" size="lg" lineBreak>
            {this.props.store.language.label105}
          </Text>
          <Text color="special" size="lg" lineBreak>
            {this.getDeveloped()}
          </Text>
          <div className="my-1"></div>
          <div
            onClick={() =>
              this.goToPage(this.props.store.currentCompany.web)
            }
          >
            <Text weight="bold" size="lg" lineBreak>
              {this.props.store.language.label106}
            </Text>
            <Text color="special" size="lg" lineBreak>
              {this.props.store.currentCompany.web}
            </Text>
          </div>
          {this.props.store.showContent("kasimu") && (
            <>
              <div className="my-1"></div>
              <div
                onClick={() =>
                  this.goToPage("kasimu.com/politica-de-privacidad-de-kasimu")
                }
              >
                <Text weight="bold" size="lg" lineBreak>
                  {this.props.store.language.label107}
                </Text>
                <Text color="special" size="lg" lineBreak>
                  {this.props.store.language.label108}
                </Text>
              </div>
              <div className="my-1"></div>
              <div
                onClick={() => this.goToPage("kasimu.com/soporte-individual")}
              >
                <Text weight="bold" size="lg" lineBreak>
                  {this.props.store.language.label109}
                </Text>
                <Text color="special" size="lg" lineBreak>
                  {this.props.store.language.label110}
                </Text>
              </div>
            </>
          )}
        </div>
        <div className="mb-2">
          <Text className="mb-1" weight="bold" size="xl" lineBreak>
            {this.props.store.language.label111}
          </Text>
          <div onClick={this.handleShowModalSignOut}>
            <Text weight="bold" size="lg" lineBreak>
              {this.props.store.language.label112}
            </Text>
            <Text color="special" size="lg" lineBreak>
              {this.props.store.language.label113} {user.username}
            </Text>
          </div>
        </div>
        {showModalSignOut && (
          <SignOutModal
            onClose={this.handleCloseModalSignOut}
            onAccept={this.handleSignOut}
          />
        )}
        {showLanguageChange && (
          <LanguageChange onClose={this.handleLanguageChangeModalClose} />
        )}

        {/* <Text className="mb-1" size="sm" weight="strong">
          {this.props.store.language.root[user.getRolId()]}
        </Text> */}
      </div>
    );
  }

  render() {
    return this.renderHeader();
  }
}

export default withStore(SettingPage);
