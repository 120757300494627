import React, { Component } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router";
import withStore from "../../../hocs/withStore";
import {
  RectSkeleton,
  Text,
  TextInput,
  CardAlbum,
  Columns,
  Column,
} from "ks_storybook";
import { PlayerView, ReproducingView } from "../../../Components/AudioPlayer";
import { Tooltip } from "react-tooltip";

@observer
class AllPlaylistsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categoryPlaylists: null,
      selectedCategoryPlaylist: null,
      name: "",
      selectedPlaylist: false,
    };

    this.handleBackwards = this.handleBackwards.bind(this);
  }

  componentDidMount() {
    const category = JSON.parse(localStorage.getItem("allPlaylists"));
    this.props.store.handleHeader(<div></div>, ["renderHeaderStart"]);

    if (category.id != 5000) {
      this.setState({ categoryPlaylists: this.getCategoryPlayLists(category) });
    } else {
      this.setState({ categoryPlaylists: this.getMyPlayLists() });
    }

    if (!this.props.store.ui.isReproducing) {
      this.props.store.ui.pushHistory("/player/playlists/all");
    }
  }

  componentWillUnmount() {
    this.props.store.handleHeader(null, [
      "renderHeaderStart",
      "renderHeaderContent",
    ]);
  }

  handleChange = (sender, value, name, validation) => {
    this.setState({ name: value });
  };

  handlePlaylistClick = (categoryPlaylist) => {
    this.props.store.ui.loadBalancer();
    this.setState({
      selectedCategoryPlaylist: categoryPlaylist,
      selectedPlaylist: true,
    });
    this.props.store.ui.selectedCategoryPlaylist = categoryPlaylist;
    this.props.store.ui.isPlayer = true;
    this.props.store.handleHeader(null, [
      "renderHeaderStart",
      "renderHeaderContent",
    ]);
  };

  getCategoryPlayLists(category) {
    if (!this.props.store.failsafeMode) {
      return this.props.store.categoriesPlaylists.fetchSearchId(
        category.id,
        this.props.store.lang
      );
    }
  }

  getMyPlayLists() {
    return this.props.store.userPlaylist.fetchAll();
  }

  renderSkeleton(playlists) {
    return (
      <div>
        <Columns style={{ gridGap: "2rem" }} isVCentered>
          <Column isSize={{ mobile: 12, desktop: 4, tablet: 4 }}>
            <RectSkeleton width="100%" height="250px" />
          </Column>
          <Column isSize={{ mobile: 12, desktop: 8, tablet: 8 }}>
            <div>
              <RectSkeleton width="170px" height="15px" />
              <div className="py-1">
                <RectSkeleton width="370px" height="50px" />
              </div>
              <RectSkeleton width="370px" height="15px" />
              <RectSkeleton width="170px" height="15px" />
            </div>
          </Column>
        </Columns>
        <div className="my-4" style={{ maxWidth: "580px" }}>
          <RectSkeleton width="100%" height="30px" />
        </div>
        <Columns fill="170">
          {playlists.map((p) => (
            <React.Fragment>
              <Column>
                <RectSkeleton width="100%" height="150px" />
                <RectSkeleton width="100%" height="15px" />
                <RectSkeleton width="100%" height="15px" />
              </Column>
            </React.Fragment>
          ))}
        </Columns>
      </div>
    );
  }

  getIdentifier = (userId) => {
    if (this.props.store.loggedInUser.id == userId) return "(Usuario) - ";
    return "(Administrador) - ";
  };

  replaceBucketUrl(originalUrl) {
    // URL base a reemplazar
    let oldBaseUrl = process.env.REACT_APP_KASIMU_API_URL == "https://api.megaplayer.me"
    ? "https://bucket-kasimu.s3.amazonaws.com/"
    : "https://bucket-kasimu-test.s3.amazonaws.com/";

    // Nueva URL base
    let newBaseUrl = this.props.store.getBucketUrl();

    // Reemplazar la URL base en la URL original
    let newUrl = originalUrl.replace(oldBaseUrl, newBaseUrl);
    
    // Devolver la nueva URL
    return newUrl;
  }

  renderPlaylistRow(playlist, idx) {
    const category = JSON.parse(localStorage.getItem("allPlaylists"));
    if (!playlist.id)
      return (
        <Column>
          <div style={{ aspectRatio: "1 / 1" }}>
            <RectSkeleton height="100%" width="100%" />
          </div>
        </Column>
      );

    if (category.id != 5000) {
      return (
        <Column>
          <div
            onClick={() => this.handlePlaylistClick(playlist)}
            style={{ position: "relative", height: "100%" }}
          >
            <CardAlbum
              id={idx}
              title={playlist.alias}
              description={playlist.description}
              src={this.replaceBucketUrl(playlist.icon_path)}
              mobile={!this.props.store.viewMobile}
              style={{
                borderRadius: "6px",
              }}
            />
            {this.props.store.viewMobile && (
              <Tooltip
                id={"playlist-" + idx}
                place="bottom"
                style={{ zIndex: 3 }}
              >
                <span>{playlist.alias}</span>
              </Tooltip>
            )}
          </div>
        </Column>
      );
    } else {
      return (
        <Column>
          <div
            onClick={() => this.handlePlaylistClick(playlist)}
            style={{ position: "relative", height: "100%" }}
          >
            <CardAlbum
              id={idx}
              title={
                this.props.store.getSingleUser()
                  ? playlist.name
                  : this.getIdentifier(playlist.user_id) + playlist.name
              }
              description={playlist.description}
              src={this.props.store.ui._iconMyplaylists}
              style={{
                borderRadius: "6px",
              }}
            />
            {this.props.store.viewMobile && (
              <Tooltip
                id={"playlist-" + idx}
                place="bottom"
                style={{ zIndex: 3 }}
              >
                <span>
                  {this.props.store.getSingleUser()
                    ? playlist.name
                    : this.getIdentifier(playlist.user_id) + playlist.name}
                </span>
              </Tooltip>
            )}
          </div>
        </Column>
      );
    }
  }

  renderMessage() {
    return (
      <Columns
        isGapless
        isMarginless
        className="py-3"
        style={{
          padding: `0 ${this.props.store.viewMobile ? "50px" : "20px"}`,
        }}
      >
        <Column className="has-text-centered">
          <Text
            color="black"
            weigth="strong"
            lead
            size={this.props.store.viewMobile ? "md" : "sm"}
            multiline
          >
            {this.props.store.language.label21}
          </Text>
        </Column>
      </Columns>
    );
  }

  search = (data) => {
    const { name } = this.state;
    const category = JSON.parse(localStorage.getItem("allPlaylists"));

    if (!data[0].id) {
      return data;
    }

    if (category.id != 5000) {
      return data.filter(
        (item) =>
          item.alias?.toLowerCase()?.includes(name) ||
          item.alias?.includes(name)
      );
    } else {
      return data.filter(
        (item) =>
          item.name?.toLowerCase()?.includes(name) || item.name?.includes(name)
      );
    }
  };

  handleBackwards = () => {
    const path = "/app";

    const history = this.props.store.ui.onBackwards();

    if (history) {
      if (history == "/player/categories") {
        this.props.store.ui.isCategory = true;
        this.props.store.ui.isReproducing = false;
        this.props.history.push(path + "/player/home");
      } else if (history == "/player/playlist") {
        this.props.store.ui.isReproducing = true;
        this.props.store.ui.isCategory = false;
        this.props.history.push(path + "/player/home");
      } else {
        this.props.store.ui.isCategory = false;
        this.props.store.ui.isReproducing = false;
        this.props.history.push(path + history);
      }
    }
  };

  render() {
    const category = JSON.parse(localStorage.getItem("allPlaylists"));

    const { categoryPlaylists, selectedPlaylist, selectedCategoryPlaylist } =
      this.state;

    const { isReproducing, isPlayer } = this.props.store.ui;

    if (!categoryPlaylists) return null;

    const playlists = !categoryPlaylists.isOk()
      ? categoryPlaylists._store.getDummy(10)
      : categoryPlaylists.toArray();

    if (categoryPlaylists.isEmpty()) {
      return this.renderSkeleton(playlists);
    }

    if (isReproducing && !isPlayer) {
      return (
        <React.Fragment>
          <Columns isCentered isVCentered>
            <Column
              style={{
                maxWidth: "1450px",
              }}
              isSize={12}
            >
              <ReproducingView
                mobile={!this.props.store.viewMobile}
                categories={[]}
                category={category}
                container={this.props.container}
              />
            </Column>
          </Columns>
        </React.Fragment>
      );
    }

    if (selectedCategoryPlaylist && isPlayer) {
      return (
        <React.Fragment>
          {!this.props.store.ui.hideMiniPlayer &&
            this.props.store.viewMobile && (
              <div className="is-hidden-desktop">
                <div className="mt-6"></div>
              </div>
            )}
          <PlayerView
            categoryPlaylist={selectedCategoryPlaylist}
            category={category}
            onBackwards={this.handleBackwards}
            mobile={this.props.mobile}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div>
          {this.props.store.viewMobile ? (
            <div>
              <div className="my-4" style={{ maxWidth: "580px" }}>
                <TextInput
                  invert
                  backgroundColor="grayLight"
                  className="is-fullwidth"
                  size={this.props.store.viewMobile ? "xl" : "lg"}
                  name="search"
                  autocomplete="nope"
                  placeholder={this.props.store.language.label73}
                  iconKasimu="search"
                  colorIcon="dark"
                  onChange={this.handleChange}
                />
              </div>
            </div>
          ) : (
            <>
              {this.props.store.handleHeader(
                <div
                  style={{
                    width: "100vw",
                    padding: "0px 12px",
                  }}
                >
                  <TextInput
                    invert
                    backgroundColor="grayLight"
                    className="is-fullwidth"
                    size={this.props.store.viewMobile ? "xl" : "lg"}
                    name="search"
                    autocomplete="nope"
                    placeholder={this.props.store.language.label73}
                    iconKasimu="search"
                    colorIcon="dark"
                    onChange={this.handleChange}
                  />
                </div>,
                ["renderHeaderContent"]
              )}
            </>
          )}
          <Columns
            fill={this.props.store.viewMobile ? "170" : "130"}
            style={{ gap: this.props.store.viewMobile && "24px" }}
          >
            {playlists.length > 0
              ? this.search(playlists).map((p, idx) =>
                  this.renderPlaylistRow(p, idx)
                )
              : this.renderMessage()}
          </Columns>
        </div>
      </React.Fragment>
    );
  }
}

export default withStore(withRouter(AllPlaylistsPage));
