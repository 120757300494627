import mysoundLogo from "../assets/images/mysound.png";
import mysoundLogoMobile from "../assets/images/mysound_m.png";
import mysoundLogoSquare from "../assets/images/mysound_square.png";
const AWS_BUCKET = 'https://sources.megaplayer.me';

const MYSOUND = {
    reseller_companies_id: 4,
    logo: mysoundLogo,
    logoMobile: mysoundLogoMobile,
    logoSquare: mysoundLogoSquare,
    name: 'MySound',
    favicon: AWS_BUCKET+'/favicon/mysound.ico',
    web: 'www.mysound.ee',
    supportContact: 'info@mysound.ee',
    url_panel: 'https://panel.mysound.ee/'
}

export default MYSOUND;