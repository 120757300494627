import React, { Component } from "react";
import { observer } from "mobx-react";
import { Text } from "ks_storybook";
import withStore from "../../../hocs/withStore";

@observer
class PlaylistsListPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Text>{"Crear Playlist"}</Text>
      </React.Fragment>
    );
  }
}

export default withStore(PlaylistsListPage);
