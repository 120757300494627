import _logo from "../assets/images/bitproximity.png";
import _logoSquare from "../assets/images/bitproximity_square.png";
const AWS_BUCKET = 'https://sources.megaplayer.me';

const BITPROXIMITY = {
    reseller_companies_id: 8,
    logo: _logo,
    logoMobile: _logo,
    logoSquare: _logoSquare,
    name: 'Bitproximity',
    favicon: AWS_BUCKET+'/favicon/bitproximity.ico',
    web: 'www.bitproximity.com',
    supportContact: 'mario@bitproximity.com',
    url_panel: 'https://panel.bitproximity.com/'
}

export default BITPROXIMITY;