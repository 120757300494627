import React, { Component } from "react";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import {
  Text,
  Field,
  TextInput,
  Modal,
  Button,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Panel,
} from "ks_storybook";
import { LanguageChange } from ".";

@observer
class ConfigModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLanguageChange: false,
    };
  }

  handleLanguageChangeModalOpen = () => {
    this.setState({ showLanguageChange: true });
  };

  handleLanguageChangeModalClose = () => {
    this.setState({ showLanguageChange: false });
  };

  handleAccept = () => {
    this.props.onAccept && this.props.onAccept();
  };

  handleClose = () => {
    this.props.onClose && this.props.onClose();
  };

  render() {
    const { showLanguageChange } = this.state;

    return (
      <>
        <Modal
          show
          width={this.props.store.viewMobile ? "540px" : "90%"}
          height={this.props.store.viewMobile ? "200px" : "auto"}
          onClose={this.handleClose}
        >
          <ModalHeader>
            <Text
              size={this.props.store.viewMobile ? "lg" : "md"}
              className="mb-2"
              weight="black"
              lineBreak
              lead
            >
              {this.props.store.language.label93}
            </Text>
          </ModalHeader>
          <ModalContent>
            <ul>
              <li>
                <Button
                  kind="link"
                  size={this.props.store.viewMobile ? "lg" : "md"}
                  className="is-fullwidth"
                  onClick={this.handleLanguageChangeModalOpen}
                >
                  {this.props.store.language.label2}
                </Button>
              </li>
            </ul>
          </ModalContent>
          <ModalFooter></ModalFooter>
        </Modal>
        {showLanguageChange && (
          <LanguageChange onClose={this.handleLanguageChangeModalClose} />
        )}
      </>
    );
  }
}

ConfigModal.propTypes = {};

ConfigModal.defaultProps = {};

export default withStore(ConfigModal);
