import { Model } from "ks_storybook";

class UserPlaylist extends Model {
  urlRoot = "/users_playlist";

  build() {
    return this._store.search(
      { playlist_id: this.playlist_id, user_id: this.user_id },
      "build_list",
      true,
      `/users_playlist/build_list`
    );
  }
}

export default UserPlaylist;
