import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import {
  Dropdown,
  DropdownToggle,
  DropdownPanel,
  DropdownPanelItem,
  Avatar,
  Text,
  Button,
} from "ks_storybook";
import withStore from "../../../hocs/withStore";
import { withRouter } from "react-router";
import { Tooltip } from "react-tooltip";
import { ConfigModal, SignOutModal } from "../../../Components/User";

@observer
class LoggedUserDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModalSignOut: false,
      showModalConfig: false,
    };
    this.handleSignOut = this.handleSignOut.bind(this);
  }

  handleShowModalSignOut = () => {
    this.setState({ showModalSignOut: true });
  };

  handleCloseModalSignOut = () => {
    this.setState({ showModalSignOut: false });
  };

  handleShowModalConfig = () => {
    this.setState({ showModalConfig: true });
  };

  handleCloseModalConfig = () => {
    this.setState({ showModalConfig: false });
  };

  handleSignOut() {
    this.handleShowModalSignOut();
    this.props.store.signOut();
  }

  goToSetting = () => {
    const path = this.props.match.path;
    this.props.history.push(`/app/player/setting`);
  };

  renderHeader(info) {
    const { showModalSignOut, showModalConfig } = this.state;
    const user = this.props.store.loggedInUser;
    const path = this.props.match.path;

    return (
      <div className="flex items-center">
        {!this.props.store.viewMobile ? (
          <Button
            className="ml-1"
            size="lg"
            iconSize="28px"
            icon="cog"
            color="basic"
            kind="link"
            onClick={this.goToSetting}
          />
        ) : (
          <Dropdown direction="bottom-right">
            <DropdownToggle>
              <Button
                color="black"
                iconKasimu="user-circle-white"
                size="lg"
                iconSize="28px"
                padding="4px"
                img={info ? this.props.store.replaceBucketUrl(info.avatar_path) : null}
              >
                {!this.props.mobile ? user.username : null}
              </Button>
            </DropdownToggle>
            <DropdownPanel
              style={{
                width: 240,
              }}
            >
              <DropdownPanelItem
                onClick={() =>
                  this.props.store.getPermission(9, 9, () => {
                    this.handleClick(`${path}/player/playlists_plans`);
                  })
                }
              >
                {this.props.store.language.label47}
              </DropdownPanelItem>
              <DropdownPanelItem
                onClick={() =>
                  this.props.store.getPermission(10, 10, () => {
                    this.handleClick(`${path}/player/ads_plans`);
                  })
                }
              >
                {this.props.store.language.label49}
              </DropdownPanelItem>
              <DropdownPanelItem
                onClick={() =>
                  this.props.store.getPermission(11, 11, () => {
                    this.handleClick(`${path}/player/ads/list`);
                  })
                }
              >
                {this.props.store.language.label50}
              </DropdownPanelItem>
              <DropdownPanelItem onClick={() =>
                  this.props.store.getPermission(8, 8, () => {
                    this.props.store.goToPanel();
                  })
              }>
                {this.props.store.language.label51}
              </DropdownPanelItem>
              <DropdownPanelItem onClick={this.handleShowModalConfig}>
                {this.props.store.language.label93}
              </DropdownPanelItem>
              <DropdownPanelItem
                onClick={() => this.handleClick(`${path}/player/about`)}
              >
                {this.props.store.language.label52}
              </DropdownPanelItem>
            </DropdownPanel>
          </Dropdown>
        )}
        <div data-tip data-for="logout">
          <Button
            className="ml-1"
            size="lg"
            iconSize="28px"
            iconKasimu="turn-off"
            color={this.props.store.viewMobile ? "black" : "basic"}
            kind={this.props.store.viewMobile ? "solid" : "link"}
            onClick={() => {
              this.props.store.getPermission(7, 7, () => {
                this.handleShowModalSignOut();
              });
            }}
            id="logout"
          />
        </div>
        <Tooltip id="logout" place="bottom">
          <span>{this.props.store.language.label28}</span>
        </Tooltip>
        {showModalSignOut && (
          <SignOutModal
            onClose={this.handleCloseModalSignOut}
            onAccept={this.handleSignOut}
          />
        )}
        {showModalConfig && (
          <ConfigModal onClose={this.handleCloseModalConfig} />
        )}
      </div>
    );
  }

  handleClick = (to) => {
    this.props.history.push(to);
  };

  render() {
    const info = this.props.store.loggedInUserPersonalization;

    return this.renderHeader(info);
  }
}

LoggedUserDropdown.propTypes = {
  mobile: PropTypes.bool,
};

LoggedUserDropdown.defaultProps = {
  mobile: false,
};

export default withRouter(withStore(LoggedUserDropdown));
