import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  RectSkeleton,
  Text,
  Avatar,
  Button,
  Toggle,
  Panel,
  Img,
} from "ks_storybook";
import { observer } from "mobx-react";
import withStore from "../../hocs/withStore";
import { withToastManager } from "react-toast-notifications";
import icon3 from "../../assets/images/hibernation.png";

@observer
class PlaylistPlanRow extends Component {
  renderSkelenton = () => {
    return <RectSkeleton width="150px" height="20px" />;
  };

  getPlaylistType = () => {
    switch (this.props.plan.playlist_type) {
      case 0:
        return false;
      case 1:
        return true;
      case 2:
        return false;
      case 3:
        return true;
      case 4:
        return false;
      case 5:
        return true;
      case 6:
        return false;
      case 7:
        return true;
      default:
        break;
    }
  };

  getPlaylistTypeInvest = () => {
    switch (this.props.plan.playlist_type) {
      case 0:
        return 1;
      case 1:
        return 0;
      case 2:
        return 3;
      case 3:
        return 2;
      case 4:
        return 5;
      case 5:
        return 4;
      case 6:
        return 7;
      case 7:
        return 6;
    }
  };

  getIconPlaylistUser = (value) => {
    switch (value) {
      case 4:
        return this.props.store.getBucketUrl()+"images/cp-a.png";
      case 5:
        return this.props.store.getBucketUrl()+"images/cp-a.png";
      case 6:
        return this.props.store.getBucketUrl()+"images/cp-m.png";
      case 7:
        return this.props.store.getBucketUrl()+"images/cp-m.png";
    }
  };

  renderDesktop = (plan) => {
    return (
      <Panel color="gray" className="mt-1" padding="15px 28px">
        <article
          className="ks--media"
          style={{ alignItems: "center", padding: "0px" }}
        >
          <figure className="media-left">
            {plan.type == 1 ? (
              <div className="flex items-center justify-center">
                <Avatar
                  size="lg"
                  image={
                    plan.playlist_type >= 4
                      ? this.getIconPlaylistUser(plan.playlist_type)
                      : this.props.store.replaceBucketUrl(plan.categories_playlists[0].icon_path)
                  }
                  style={{ borderRadius: 4 }}
                />
              </div>
            ) : (
              <Avatar size="lg" image={icon3} />
            )}
          </figure>
          <div className="media-content">
            <div className="content" style={{ lineBreak: "anywhere" }}>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="medium"
                color="white"
              >
                {plan.type == 1 ? (
                  <strong className="has-text-light">
                    {plan.owner == 3
                      ? this.props.store.language.label197
                      : plan.owner == 2
                      ? this.props.store.language.label198
                      : ""}
                    {plan.playlist_type >= 4
                      ? plan.categories_playlists[0].name
                      : plan.categories_playlists[0].alias}
                  </strong>
                ) : (
                  <strong className="has-text-light">
                    {this.props.store.language.label199}
                  </strong>
                )}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="regular"
                color="special"
              >
                {plan.type == 1 ? (
                  <small>
                    {plan.playlist_type >= 4
                      ? plan.categories_playlists[0].description
                        ? plan.categories_playlists[0].description
                        : this.props.store.language.label4
                      : plan.categories_playlists[0].description}
                  </small>
                ) : null}
                <br />
                <small>
                  {plan.start_hour} - {plan.finish_hour}
                </small>
              </Text>
            </div>
          </div>
          <div className="media-right"></div>
        </article>
      </Panel>
    );
  };

  renderMobile = (plan) => {
    return (
      <Panel color="gray" className="mt-1" padding="10px 15px">
        <article
          className="ks--media"
          style={{ alignItems: "center", padding: "0px", flexWrap: "wrap" }}
        >
          <figure className="media-left">
            {plan.type == 1 ? (
              <Avatar
                size="md"
                image={
                  plan.playlist_type >= 4
                    ? this.getIconPlaylistUser(plan.playlist_type)
                    : this.props.store.replaceBucketUrl(plan.categories_playlists[0].icon_path)
                }
                style={{ borderRadius: 10 }}
              />
            ) : (
              <Avatar size="md" image={icon3} />
            )}
          </figure>
          <div className="media-content">
            <div className="content" style={{ lineBreak: "anywhere" }}>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="medium"
                color="white"
              >
                {plan.type == 1 ? (
                  <strong className="has-text-light">
                    {plan.owner == 3
                      ? this.props.store.language.label197
                      : plan.owner == 2
                      ? this.props.store.language.label198
                      : ""}
                    {plan.playlist_type >= 4
                      ? plan.categories_playlists[0].name
                      : plan.categories_playlists[0].alias}
                  </strong>
                ) : (
                  <strong className="has-text-light">
                    {this.props.store.language.label199}
                  </strong>
                )}
              </Text>
              <Text
                size={this.props.store.viewMobile ? "xl" : "lg"}
                weight="regular"
                color="special"
                lineBreak
              >
                {plan.type == 1 ? (
                  <small>
                    {plan.playlist_type >= 4
                      ? plan.categories_playlists[0].description
                        ? plan.categories_playlists[0].description
                        : this.props.store.language.label4
                      : plan.categories_playlists[0].description}
                  </small>
                ) : null}
                <br />
                <small>
                  {plan.start_hour} - {plan.finish_hour}
                </small>
              </Text>
            </div>
          </div>

          <div
            className="media-right"
            style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}
          ></div>
        </article>
      </Panel>
    );
  };

  render() {
    const { plan } = this.props;
    if (plan.length === 0) {
      return this.renderSkelenton();
    }
    return this.props.store.viewMobile
      ? this.renderDesktop(plan)
      : this.renderMobile(plan);
  }
}

PlaylistPlanRow.propTypes = {
  plan: PropTypes.object,
};

PlaylistPlanRow.defaultProps = {
  plan: null,
};

export default withStore(withToastManager(PlaylistPlanRow));
