import funboxmusicLogo from "../assets/images/funboxmusic.png";
import funboxmusicLogoSquare from "../assets/images/funboxmusic_square.png";
const AWS_BUCKET = 'https://sources.megaplayer.me';

const FUNBOXMUSIC = {
    reseller_companies_id: 2,
    logo: funboxmusicLogo,
    logoMobile: funboxmusicLogo,
    logoSquare: funboxmusicLogoSquare,
    name: 'FUNBOXMUSIC',
    favicon: AWS_BUCKET+'/favicon/funbox.ico',
    web: 'www.funboxmusic.com',
    supportContact: 'info@funboxmusic.com',
    url_panel: 'https://musicpanel.funboxmusic.com/'
}

export default FUNBOXMUSIC;