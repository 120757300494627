import { Store } from "ks_storybook";
import { Preference } from "../models";

class PreferencesStore extends Store {
  model = Preference;

  getPreferences() {
    return this.search({}, "users_preference", true, `/users_preferences_user`);
  }
}

export default PreferencesStore;
