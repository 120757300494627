import _logo from "../assets/images/mozartplay.png";
import _logoSquare from "../assets/images/mozartplay_square.png";
const AWS_BUCKET = 'https://sources.megaplayer.me';

const MOZARTPLAY = {
    reseller_companies_id: 9,
    logo: _logo,
    logoMobile: _logo,
    logoSquare: _logoSquare,
    name: 'MozartPlay',
    favicon: AWS_BUCKET+'/favicon/mozartplay.ico',
    web: 'www.funboxmusic.com',
    supportContact: 'info@funboxmusic.com',
    url_panel: 'https://mozartplaypanel.funboxmusic.com/'
}

export default MOZARTPLAY;