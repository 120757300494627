import { Store } from "ks_storybook";
import { Category } from "../models";

class CategoriesStore extends Store {
  model = Category;

  fetchAllLang(lang) {
    return this.search(
      { per_page: 1000, lang: lang },
      "categories_all_lang",
      true,
      "/categories/get_category_all_lang"
    );
  }
}

export default CategoriesStore;
