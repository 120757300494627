const es_ES = {
  languages: {
    1: "Español",
    2: "Inglés",
    3: "Francés",
    4: "Portugués",
    5: "Estonio",
    6: "Alemán",
    7: "Italiano"
  },
  label1: "Idiomas",
  label2: "Cambiar idioma",
  label3: "Puedes elegir y cambiar el idioma que quieras.",
  label4: "Sin descripción",
  label5: "Cargando Aplicación...",
  label6: `Su suscripción ha expirado. Ir a `,
  label7: "Esta cuenta está conectada desde otra ubicación.",
  label8: "Error, Usuario o contraseña incorrecta.",
  label9: "¡Las cuentas administradoras no tiene acceso al reproductor web!",
  label10: "¡Usuario suspendido!, ponte en contacto con ",
  label11: "Por favor proporcione nombre de usuario y contraseña.",
  label12: "Usuario suspendido por el administrador",
  label13: "Error al conectarse al servidor, comprueba tu conexión.",
  label14: `Usuario`,
  label15: `(No usar email)`,
  label16: `Contraseña`,
  label17: `Iniciar sesión`,
  label18: `Categoría`,
  label19: `Mis playlists`,
  label20: `Volver`,
  label21: `Conectate con el panel de control para crear playlists.`,
  label22: `Sin datos por el momento`,
  label23: `Error, el PIN debe ser de 4 dígitos.`,
  label24: `Error, PIN incorrecto.`,
  label25: `Reproductor bloqueado`,
  label26: `Por favor, ingresa tu PIN para desbloquearlo.`,
  label27: `Obligatorio`,
  label28: `Cerrar sesión`,
  label29: `Elegir Por Tipo de Local`,
  label30: `Elegir Por Estilo de Música`,
  label31: `Elegir Por Otros Criterios`,
  label32: `Reproductor Hibernando`,
  label33: `Por favor, ingresa tu PIN para salir de la hibernación.`,
  label34: `Desbloquear`,
  label35: `Cancelar`,
  label36: `Inicio`,
  label37: `Reproduciendo`,
  label38: `Canciones`,
  label39: `Inicio`,
  label40: `Buscar Playlists`,
  label41: `Biblioteca musical`,
  label42: `Artista`,
  label43: `Buscar el nombre de la canción`,
  label44: `Buscar el artista`,
  label45: `Resultados`,
  label46: `Añadir como siguiente en reproducirse`,
  label47: `Planificador de playlists`,
  label48: `Personalización`,
  label49: `Planificador de anuncios`,
  label50: `Anuncios`,
  label51: `Panel de control`,
  label52: `Acerca de`,
  label53: `Activado`,
  label54: `Desactivado`,
  label55: `Versión de actualización de base de datos`,
  label56: `Aplicación de musica`,
  label57: `Esta aplicación fue desarrollada por`,
  label58: `Esta aplicación fue desarrollada para`,
  label59: "Enero,Febrero,Marzo,Abril,Mayo,Junio,Julio,Agosto,Septiembre,Octubre,Noviembre,Diciembre",
  label60: "Ene,Feb,Mar,Abr,May,Jun,Jul,Ago,Sep,Oct,Nov,Dec",
  label61: "Domingo,Lunes,Martes,Miércoles,Jueves,Viernes,Sabado",
  label62: "Dom,Lun,Mar,Mie,Jue,Vie,Sab",
  label63: "Do,Lu,Ma,Mi,Ju,Vi,Sa",
  label64: "Conectado",
  label65: "Desconectado",
  label66: `¿Desea cerrar la sesión?`,
  label67: "Si",
  label68: "No",
  label69: `Mover como siguiente a reproducir`,
  label70: `Marcar como siguiente a reproducir`,
  label71: `Eliminar canción de la playlist en reproducción`,
  label72: `Buscando...`,
  label73: `Buscar...`,
  label74: `Abrir`,
  label75: `Cerrar`,
  label76: `Más opciones`,
  label77: `Favorito`,
  label78: `Bloquear`,
  label79: `Anterior`,
  label80: `Play`,
  label81: `Pausa`,
  label82: `Siguiente`,
  label83: `Playlist en reproducción`,
  label84: `Ir a panel de control web`,
  label85: `Avanzar`,
  label86: `Ampliar biblioteca`,
  label87: `Reducir biblioteca`,
  label88: `Expandir biblioteca`,
  label89: `Muteado`,
  label90: `¿Deseas marcar como no deseada esta canción?`,
  label91: `no se volverá a escuchar.`,
  label92: `Cargando Datos...`,
  label93: "Configuración",
  label94: "Cuenta",
  label95: "Servicio contratado",
  label96: "Preferencias",
  label97: "Volumen",
  label98: "Puedes cambiar o silenciar el volumen desde el panel de control web.",
  label99: "Planificadores y anuncios",
  label100: "Programa las playlists que más te gustan en el horario que deseas, esto lo puedes hacer desde el panel de control web.",
  label101: "Crea y asigna pautas publicitarias en el horario que deseas, esto lo puedes hacer desde el panel de control web.",
  label102: "Sube tus anuncios publicitarios, esto lo puedes hacer desde el pnale de control web.",
  label103: "Información",
  label104: "Versión",
  label105: "Desarrollador",
  label106: "Sitio web",
  label107: "Políticas de privicidad",
  label108: "Importante para ti y para nosotros.",
  label109: "Soporte",
  label110: "Cuenta con nuestra ayuda.",
  label111: "Other",
  label112: "Cerrar sesión",
  label113: "Has iniciado sesión como",
  label114: "REPRODUCIENDO DESDE",
  label115: "Me gusta",
  label116: "Oculta esta canción",
  label117: "Mover como siguiente en reproducir",
  label118: "Marcar como siguiente en reproducir",
  label119: "Eliminar de la lista actual",
  label120: "Añadir como siguiente en reproducirse",
  label121: `Anuncio`,
  label122: "Guardar cambios",
  label123: "Autoguardado (Activado)",
  label124: "Autoguardado (Desactivado)",
  label125: "Autoguardado activado",
  label126: "Autoguardado desactivado",
  label127: "Se ha agregado exitosamente la canción a la playlist",
  label128: "No se ha podido agregar la canción a la playlist",
  label129: "La playlist se creó exitosamente",
  label130: "El nombre de playlist ya existe, indique otro nombre",
  label131: "Debe indicar un nombre para la nueva playlist",
  label132: "Se ha agregado exitosamente las canciones a la playlist",
  label133: "No se ha podido agregar las canciones a la playlist",
  label134: "Debes seleccionar una playlist",
  label135: "El usuario no posee una configuración base de límites, contactar al soporte",
  label136: "Se marcó la canción como favorita, se programara siempre",
  label137: "Se desmarcó como favorita la canción, ya no se programara siempre",
  label138: "Se ha añadido la canción a la playlist en reproducción",
  label139: "Seleccione una playlist",
  label140: "Playlists manuales",
  label141: "Nombre de la nueva playlist",
  label142: "Añadir",
  label143: "Guardar",
  label144: "Añadir a una playlist existente",
  label145: "Añadir a una playlist nueva",
  label146: "Se necesita conexión a internet para realizar esta acción, verifica tu conexión",
  label147: "Añadir las canciones a una playlist existente",
  label148: "Se añadirán todas las canciones en reproducción a una playlist existente",
  label149: "Añadir las canciones a una playlist nueva",
  label150: "Se añadirán todas las canciones en reproducción a una playlist nueva",
  label151: "No tienes ninguna playlist manual creada por el usuario",
  lavel152: "Se ha guardado exitosamente",
  label153: "Añadiendo...",
  label154: "Guardando...",
  label155: "No se ha podido guardar",
  label156: "No tienes permiso de acceder a mis playlists",
  label157: "No tienes permiso de acceder a mis playlists favoritas",
  label158: "No tienes permiso de acceder a las categorías y playlists",
  label159: "No tienes permiso de buscar en todo el contenido musical solo en favoritas",
  label160: "No tienes permiso de acceder a la biblioteca musical",
  label161: "No tienes permiso de acceder a la configuración",
  label162: "No tienes permiso de acceder a cerrar sesión",
  label163: "No tienes permiso de acceder al panel de control",
  label164: "No tienes permiso de acceder al planificador de playlists",
  label165: "No tienes permiso de acceder al planificador de anuncios",
  label166: "No tienes permiso de acceder a los anuncios",
  label167: "No tienes permiso de salir de la aplicación",
  label168: "No tienes permiso de Play/Pause de la reproducción",
  label169: "No tienes permiso de cambiar la canción en reproducción",
  label170: "No tienes permiso de cambiar la barra de progreso de la canción en reproducción",
  label171: "No tienes permiso de acceder a la vista del mini reproductor",
  label172: "No tienes permiso a mover canciones en la playlist en reproducción",
  label173: "No tienes permiso a guardar cambios de una playlist manual en reproducción",
  label174: "No tienes permiso a añadir canciones a una playlist manual nueva o existente",
  label175: "No tienes permiso de marcar o desmarcar canciones como favoritas",
  label176: "No tienes permiso de ocultar canciones para que no se puedan reproducir",
  label177: "No tienes permiso de eliminar canciones en la playlist en reproducción",
  label178: "Playlists favoritas",
  label179: "Todas tus playlists favoritas y de tu administrador",
  label180: "Acceso restringido por pin",
  label181: "Favoritas",
  label182: "Esta playlist es del administrador no se puede modificar",
  label183: "Todos los días",
  label184: "Lunes a viernes",
  label185: "Sábado y Domingo",
  label186: "Día exacto",
  label187: "Hora exacta",
  label188: "Intervalo",
  label189: "Cada",
  label190: "Frecuencia",
  label191: "canciones",
  label192: "Límite de anuncios por tanda",
  label193: "Inicio de la pauta",
  label194: "Fin de la pauta",
  label195: "De Adminstrador",
  label196: "De Usuario",
  label197: `(Administrador) - `,
  label198: `(Usuario) - `,
  label199: `Hibernación`,
  label200: "Suscripción no válida, contacte al proveedor del servicio"
};

export default es_ES;