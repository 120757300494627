import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { observer } from "mobx-react";
import withStore from "../../../hocs/withStore";
import { Button, Text } from "ks_storybook";

@observer
class MobileHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      container: null,
      navbar: false,
    };
    this.container = React.createRef();
  }

  handleBackwards = () => {
    const path = this.props.match.path;
    const history = this.props.store.ui.onBackwards();

    if (history) {
      if (history == "/player/categories") {
        this.props.store.ui.isCategory = true;
        this.props.store.ui.isReproducing = false;
        this.props.history.push(path + "/player/home");
      } else if (history == "/player/playlist") {
        this.props.store.ui.isReproducing = true;
        this.props.store.ui.isCategory = false;
        this.props.history.push(path + "/player/home");
      } else {
        this.props.store.ui.isCategory = false;
        this.props.store.ui.isReproducing = false;
        this.props.history.push(path + history);
      }
    }
  };

  handleForward = () => {
    const path = this.props.match.path;
    const history = this.props.store.ui.onForward();

    if (history) {
      if (history == "/player/categories") {
        this.props.store.ui.isCategory = true;
        this.props.store.ui.isReproducing = false;
        this.props.history.push(path + "/player/home");
      } else if (history == "/player/playlist") {
        this.props.store.ui.isReproducing = true;
        this.props.store.ui.isCategory = false;
        this.props.history.push(path + "/player/home");
      } else {
        this.props.store.ui.isCategory = false;
        this.props.store.ui.isReproducing = false;
        this.props.history.push(path + history);
      }
    }
  };

  getExistsBackwards = () => {
    const histories = this.props.store.ui.histories;
    const historyPosition = this.props.store.ui.historyPosition;

    return !histories[historyPosition - 1];
  };

  getExistsForward = () => {
    const histories = this.props.store.ui.histories;
    const historyPosition = this.props.store.ui.historyPosition;

    return !histories[historyPosition + 1];
  };

  getExistsBackwards = () => {
    const histories = this.props.store.ui.histories;
    const historyPosition = this.props.store.ui.historyPosition;

    return !histories[historyPosition - 1];
  };

  showHeader = () => {
    const { pathname } = this.props.history.location;
    if (pathname === "/app/player/home") {
      return false;
    }

    return true;
  };

  renderHeaderStart = () => {
    const selectedCategory = this.props.store.ui.selectedCategory;
    return (
      <div className="flex items-center">
        <Button
          size="lg"
          onClick={() => this.handleBackwards()}
          iconKasimu="arrow-left"
          color="black"
          className="mr-1"
          id="go-back"
          disabled={this.getExistsBackwards()}
        />
        <div>
          <Button
            size="lg"
            onClick={() => this.handleForward()}
            iconKasimu="arrow-right"
            color="black"
            disabled={this.getExistsForward()}
            id="go-forward"
          />
        </div>
        <div id="show-category" style={{ display: "none" }}>
          <Text size="xl" className="ml-2">
            {this.props.store.ui.isCategory
              ? selectedCategory?.name
              : this.props.store.ui.currentCategoryPlaylist?.alias ||
                this.props.store.ui.currentCategoryPlaylist?.name}
          </Text>
        </div>
      </div>
    );
  };

  renderHeaderContent = () => {
    return (
      this.props.store.renderHeaderContent || (
        <Text size="xxl" weight="bold">
          {this.props.store.menuTitle}
        </Text>
      )
    );
  };

  renderHeaderEnd = () => {
    return this.props.store.renderHeaderEnd;
  };

  render() {
    const { type } = this.props;

    if (type === "LayoutHeaderStart") return this.renderHeaderStart();
    if (type === "LayoutHeaderContent") return this.renderHeaderContent();
    if (type === "LayoutHeaderEnd") return this.renderHeaderEnd();
  }
}

export default withStore(withRouter(MobileHeader));
