const en_EN = {
  languages: {
    1: "Spanish",
    2: "English",
    3: "French",
    4: "Portuguese",
    5: "Estonian",
    6: "German",
    7: "Italian"
  },
  label1: "Languages",
  label2: "Change language",
  label3: "You can choose and change the language you want.",
  label4: "Without description",
  label5: "Loading application...",
  label6: `Your subscription has expired. Go to `,
  label7: "This account is connected from another location.",
  label8: "Error, Incorrect username or password.",
  label9: "Admin accounts do not have access to the web player!",
  label10: "Suspended user! Get in touch with ",
  label11: "Please provide username and password.",
  label12: "User suspended by administrator",
  label13: "Error connecting to server, check your connection.",
  label14: `User`,
  label15: `(Do not use email)`,
  label16: `Password`,
  label17: `Log in`,
  label18: `Category`,
  label19: `My playlists`,
  label20: `Go back`,
  label21: `Connect with the control panel to create playlists.`,
  label22: `No data at the moment`,
  label23: `Error, PIN must be 4 digits.`,
  label24: `Error, wrong PIN.`,
  label25: `Player locked`,
  label26: `Please enter your PIN to unlock it.`,
  label27: `Required`,
  label28: `Log out`,
  label29: `Choose By Business Type`,
  label30: `Choose By Music Style`,
  label31: `Choose By Other Criteria`,
  label32: `Hibernating Player`,
  label33: `Please enter your PIN to exit hibernation.`,
  label34: `To unlock`,
  label35: `Cancel`,
  label36: `Start`,
  label37: `Playing`,
  label38: `Songs`,
  label39: `Home`,
  label40: `Search Playlists`,
  label41: `Music library`,
  label42: `Artist`,
  label43: `Search for the name of the song`,
  label44: `Search for the artist`,
  label45: `Results`,
  label46: `Add as next to play`,
  label47: `Playlist planner`,
  label48: `Personalization`,
  label49: `Ad planner`,
  label50: `Advertisements`,
  label51: `Control Panel`,
  label52: `About`,
  label53: `Activated`,
  label54: `Desactivated`,
  label55: `Database update version`,
  label56: `Music application`,
  label57: `This application was developed by`,
  label58: `This application was developed for`,
  label59: "January,February,March,April,May,June,July,August,September,October,November,December",
  label60: "Jan,Feb,Mar,Abr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec",
  label61: "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday",
  label62: "Sun,Mon,Tue,Wed,Wed,Thu,Fri,Sat",
  label63: "Su,Mo,Tu,We,Wd,Th,Sa",
  label64: "Connected",
  label65: "Disconnected",
  label66: `Do you want to log out?`,
  label67: "Yes",
  label68: "No",
  label69: `Move as next to play`,
  label70: `Mark as next to play`,
  label71: `Remove song from the playlist in playback`,
  label72: `Searching...`,
  label73: `Search...`,
  label74: `Open`,
  label75: `Close`,
  label76: `More options`,
  label77: `Favorite`,
  label78: `Block`,
  label79: `Prev`,
  label80: `Play`,
  label81: `Pause`,
  label82: `Next`,
  label83: `Playlist in playback`,
  label84: `Go to web control panel`,
  label85: `Go forward`,
  label86: `Enlarge library`,
  label87: `Reduce library`,
  label88: `Expand library`,
  label89: `Muted`,
  label90: `You want to mark this song as spam?`,
  label91: `it will be heard again as normal.`,
  label92: `Loading Data...`,
  label93: "Setting",
  label94: "Account",
  label95: "Contracted service",
  label96: "Preferences",
  label97: "Volume",
  label98: "You can change or mute the volume from the web control panel.",
  label99: "Planner and Announcements",
  label100: "Program the playlist that you like the most at the time you want, you can do this from the web control panel.",
  label101: "Create and assign advertising guidelines at the time you want, you can do this from the web control panel.",
  label102: "Upload your advertisements, you can do this from the web control panel.",
  label103: "Information",
  label104: "Version",
  label105: "Developer",
  label106: "Website",
  label107: "Privacy policies",
  label108: "Important for you and for us.",
  label109: "Support",
  label110: "Count on our help.",
  label111: "Other",
  label112: "Sign off",
  label113: "You are logged in as",
  label114: "PLAYING FROM",
  label115: "I like",
  label116: "Hide this song",
  label117: "Move as next to play",
  label118: "Mark as next yo play",
  label119: "Remove from current list",
  label120: "Add as next to play",
  label121: "Advertisement",
  label122: "Save Changes",
  label123: "Autosave (Enabled)",
  label124: "Autosave (Disabled)",
  label125: "Autosave enabled",
  label126: "Autosave disabled",
  label127: "The song has been successfully added to the playlist",
  label128: "Could not add song to playlist",
  label129: "The playlist was created successfully",
  label130: "The playlist name already exists, please enter another name",
  label131: "You must enter a name for the new playlist",
  label132: "The songs have been successfully added to the playlist",
  label133: "Could not add songs to playlist",
  label134: "You must select a playlist",
  label135: "The user does not have a base configuration of limits, contact support",
  label136: "The song has been marked as a favorite, it will always be programmed",
  label137: "The song was unmarked as a favorite, it is no longer always programmed",
  label138: "The song has been added to the currently playing playlist",
  label139: "Select a playlist",
  label140: "Manual Playlists",
  label141: "Name of the new playlist",
  label142: "Add",
  label143: "Save",
  label144: "Add to an existing playlist",
  label145: "Add to new playlist",
  label146: "Internet connection is required to perform this action, check your connection",
  label147: "Add songs to an existing playlist",
  label148: "All currently playing songs will be added to an existing playlist",
  label149: "Add songs to a new playlist",
  label150: "All currently playing songs will be added to a new playlist",
  label151: "You do not have any manual user-created playlists",
  lavel152: "Saved successfully",
  label153: "Adding...",
  label154: "Saving...",
  label155: "Could not save",
  label156: "You do not have permission to access my playlists",
  label157: "You do not have permission to access my favorite playlists",
  label158: "You do not have permission to access categories and playlists",
  label159: "You do not have permission to search all musical content only in favorites",
  label160: "You do not have permission to access the music library",
  label161: "You do not have permission to access settings",
  label162: "You do not have permission to log out",
  label163: "You do not have permission to access the control panel",
  label164: "You do not have permission to access the playlist planner",
  label165: "You do not have permission to access the ad scheduler",
  label166: "You do not have permission to access ads",
  label167: "You do not have permission to exit the application",
  label168: "You do not have permission to play/pause playback",
  label169: "You do not have permission to change the song currently playing",
  label170: "You do not have permission to change the progress bar of the currently playing song",
  label171: "You do not have permission to access the mini player view",
  label172: "You do not have permission to move songs in the currently playing playlist",
  label173: "You do not have permission to save changes to a manually playing playlist",
  label174: "You do not have permission to add songs to a new or existing manual playlist",
  label175: "You do not have permission to mark or unmark songs as favorites",
  label176: "You do not have permission to hide songs so that they cannot be played",
  label177: "You do not have permission to delete songs in the currently playing playlist",
  label178: "Favorite playlists",
  label179: "All your favorite playlists and your administrator's",
  label180: "Pin restricted access",
  label181: "Favorites",
  label182: "This playlist belongs to the administrator and cannot be modified.",
  label183: "Everyday",
  label184: "Monday to friday",
  label185: "Saturday and Sunday",
  label186: "Exact day",
  label187: "Exact time",
  label188: "Interval",
  label189: "Each",
  label190: "Frequency",
  label191: "songs",
  label192: "Ad limit per batch",
  label193: "Schedule beginning",
  label194: "Schedule end",
  label195: "From Administrator",
  label196: "From User",
  label197: `(Administrator) - `,
  label198: `(User) -`,
  label199: `Hibernation`,
  label200: "Invalid subscription, contact service provider"
};

export default en_EN;